import { authApi } from "@api";
import { BackButton, FormVerifyPIN } from "@components";
import { Paths } from "@constants/paths";
import { Button, PageTitle, useHandleApiResponse } from "kz-ui-sdk";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ForgotPinPageProps {}

const UpdatePINPage = ({}: ForgotPinPageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const [updatePIN, { isLoading: isUpdatingPIN }] = authApi.useUpdatePasswordMutation();
  const { handleApiResponse } = useHandleApiResponse({ toast });

  const handleBackToHomepage = useCallback(() => {
    navigate(Paths.PRIVATE.PROFILE, {
      replace: true,
    });
  }, [navigate]);

  const handleSubmitForm = useCallback(
    async (values: { pin?: string }) => {
      if (!values.pin || isUpdatingPIN) return;
      const response = await updatePIN({
        secret: values.pin,
      });
      handleApiResponse(response, {
        successMessage: t("Updated PIN successfully!"),
        toastSuccess: false,
        onSuccess: () => {
          setIsUpdated(true);
        },
        onError: (error) => {
          setIsUpdated(false);
        },
      });
    },
    [handleApiResponse, isUpdatingPIN, t, updatePIN],
  );

  return (
    <>
      {isUpdated ? (
        <div className="flex h-full animate-fade-in flex-col gap-4">
          <img
            className="mt-5 h-60 w-full object-contain"
            src={"/images/success_update.png"}
            alt="success"
          />

          <div className="text-gradient-2 mt-[-30px] flex flex-col flex-wrap items-center">
            <p className="content-base text-lg">{t("You have successfully")}</p>
            <p className="content-secondary text-lg">{t("changed your PIN!")}</p>
          </div>

          <Button
            id="btn-login"
            onClick={handleBackToHomepage}
            variant="primary"
            className="mt-4"
          >
            {t("Back to home")}
          </Button>
        </div>
      ) : (
        <>
          <PageTitle
            label={t("Change PIN")}
            backButton={<BackButton onClick={handleBackToHomepage} />}
          />
          <div className="mx-auto w-fit">
            <FormVerifyPIN
              loading={isUpdatingPIN}
              onSubmit={handleSubmitForm}
              submitLabel={t("Update")}
              className="-mt-8 !px-0"
              classes={{
                subTitle: "content-base",
              }}
              messages={{
                enterPIN: "Enter new PIN",
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default UpdatePINPage;
