import { IconMSIArrowForward, IconMSIHistory } from "@assets";
import { MaintenanceOverlay } from "@components";
import { MaintenanceOverlaySize } from "@components/utils/MaintenanceOverlay";
import { ButtonIcon, GameModel, PropsWithClassName, cn } from "kz-ui-sdk";
import { AspectRatio } from "react-aspect-ratio";

interface GameEntryProps extends PropsWithClassName {
  variant: "thumbnail" | "row";
  game: GameModel;
  providerName?: string;
  sourceRef?: string;
  onOpen: (game: GameModel) => void;
  maintenanceSize?: MaintenanceOverlaySize;
  maintenance?: boolean;
  isRecent?: boolean;
}

const GameEntry = ({
  game,
  onOpen,
  variant,
  providerName,
  sourceRef,
  maintenanceSize,
  maintenance,
  isRecent,
}: GameEntryProps) => {
  const handleOnClick = (e: React.MouseEvent) => {
    if (maintenance) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onOpen?.(game);
    }
  };

  if (variant === "row") {
    return (
      <span
        className="flex items-center gap-x-4 overflow-hidden rounded-[10px]"
        onClick={handleOnClick}
        role={"button"}
      >
        <div className="relative h-[62px] w-[62px] flex-shrink-0 overflow-hidden rounded-md border-2 border-[#262442] bg-[#3F3E70]">
          <MaintenanceOverlay underMaintenance={maintenance}>
            <img
              src={game.primaryImage?.url}
              alt={game.name}
              className={cn("absolute left-0 top-0 h-full w-full object-fill")}
            />
          </MaintenanceOverlay>
        </div>

        <div className="flex-grow items-start overflow-x-hidden">
          <h3 className="content-base overflow-x-hidden text-ellipsis whitespace-nowrap text-left text-base font-bold">
            {game.name}
          </h3>
          <p className="content-base-secondary h-4 overflow-x-hidden overflow-y-hidden text-ellipsis whitespace-nowrap text-left text-xs">
            {providerName}
          </p>
        </div>
        <ButtonIcon
          className="ml-auto !h-8 !w-8 flex-shrink-0"
          disabled={maintenance}
          variant="ghost"
          icon={<IconMSIArrowForward className="scale-[0.7]" />}
        />
      </span>
    );
  }

  // default variant === "thumbnail"
  return (
    <AspectRatio ratio={1}>
      <button
        className="relative aspect-square h-full w-full overflow-hidden rounded-md border-2 border-[#262442] bg-[#3F3E70]"
        onClick={handleOnClick}
      >
        <MaintenanceOverlay
          underMaintenance={maintenance}
          size={maintenanceSize}
        >
          <img
            src={game.primaryImage?.url || ""}
            alt={game.name}
            className={cn("absolute left-0 top-0 h-full w-full object-fill")}
          />
          {isRecent && (
            <span className="absolute left-[-2px] top-[-2px] rounded-br-lg bg-[#262442] p-[3px] shadow-md">
              <IconMSIHistory className="text-[#958ED8]" />
            </span>
          )}
        </MaintenanceOverlay>
      </button>
    </AspectRatio>
  );
};

export default GameEntry;
