import { OnboardMode, Paths, SEARCH_PARAMS } from "@constants";
import { useAuthSession } from "@hooks";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

interface ReferralCallbackPageProps {}

/**
 * @name ReferralCallbackPage
 * @description Handle referral callback
 * - Get refCode from path /r/:refCode and add it to query params
 * - Redirect to register page
 */
const ReferralCallbackPage = ({}: ReferralCallbackPageProps) => {
  const { status } = useAuthSession();
  const { refCode } = useParams() as {
    refCode?: string;
  };
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to register page
    navigate(
      generatePath(Paths.PUBLIC.ONBOARD, { mode: OnboardMode.REGISTER }) + `?${SEARCH_PARAMS.REF_CODE}=${refCode}`,
      {
        replace: true,
      },
    );
  }, [navigate, refCode, status]);

  return <></>;
};

export default ReferralCallbackPage;
