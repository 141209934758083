import { authApi } from "@api";
import authSlice from "@stores/auth/slices";
import { bnOrZero, delay } from "kz-ui-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import useAppWS from "./useAppWS";
import useTypedSelector from "./useTypedSelector";

export interface UseProfileBalanceProps {
  delayUpdate?: number;
}

export default function useProfileBalance({ delayUpdate }: UseProfileBalanceProps = {}) {
  const { self } = useTypedSelector((state) => state.auth);
  const [getProfile, { isLoading }] = authApi.useLazyGetProfileQuery();

  const { lastJsonMessage } = useAppWS();

  const balance = useMemo(() => {
    return bnOrZero(self?.member?.primaryWallet?.balance);
  }, [self?.member?.primaryWallet?.balance]);

  const dispatch = useDispatch();

  const [prevBalance, setPrevBalance] = useState(() => balance);
  const [nextBalance, setNextBalance] = useState(() => balance);

  useEffect(() => {
    setPrevBalance(nextBalance);
    setNextBalance(balance);
    // To make number run
    // eslint-disable-next-line
  }, [balance]);

  const refetch = useCallback(async () => {
    const profileResult = await getProfile();
    if (profileResult?.data) {
      dispatch(authSlice?.actions?.updateProfile(profileResult.data));
    }
  }, [dispatch, getProfile]);

  useEffect(() => {
    if (lastJsonMessage) {
      try {
        if (lastJsonMessage.type === "event" && lastJsonMessage.payload.event === "balance-changed") {
          // if player has primary wallet, update balance
          setTimeout(() => {
            if (self?.member?.primaryWallet && lastJsonMessage.payload.event === "balance-changed") {
              dispatch(authSlice.actions.updateProfileBalance(lastJsonMessage.payload.balance));
            } else {
              // if player doesn't have primary wallet, refetch profile to get new wallet
              delay().then(refetch);
            }
          }, delayUpdate ?? 0);
        }
      } catch (error) {
        console.error(error);
      }
    }
    // to make sure refetch is not called multiple times
    // eslint-disable-next-line
  }, [lastJsonMessage, dispatch, refetch]);

  return { balance, refetch, prevBalance, nextBalance, isLoading };
}
