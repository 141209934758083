import { closeIframeDialog } from "@components";
import { closeDeposit } from "@components/utils/DepositDrawer";
import { fAmount, WSEventType } from "kz-ui-sdk";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import useAppWS from "./useAppWS";

export default function useDepositNotification() {
  const { t } = useTranslation();

  const { lastJsonMessage } = useAppWS();

  useEffect(() => {
    if (lastJsonMessage) {
      try {
        if (lastJsonMessage.type === "event") {
          switch (lastJsonMessage.payload.event) {
            case WSEventType.DEPOSIT_SUCCESS:
              toast.success(
                t("Deposit amount completed", { amount: fAmount(lastJsonMessage.payload.fundingTx?.netAmount) }),
              );
              closeIframeDialog({
                id: `deposit-${lastJsonMessage.payload.fundingTx?.orderRef}`,
              });
              closeDeposit();
              break;
            default:
              break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [lastJsonMessage, t]);
}
