import { siteApi } from "@api";
import { useServerInfo } from "@hooks";
import { EmptyState } from "@views/ReferralPage/components/TabPanelRecords/components";
import {
  Button,
  GetReferralFriendHistoryRequest,
  MemberModel,
  Skeleton,
  useHandleApiResponse,
  useInfiniteQuery,
} from "kz-ui-sdk";
import moment from "moment/moment";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface FriendListProps {
  backToOverview?: () => void;
}

const itemsPerPage = 10;
const initialQuery: GetReferralFriendHistoryRequest["query"] = {
  count: 0,
  limit: itemsPerPage,
  offset: 0,
  sort: "referredAt:desc",
};

const FriendList = ({ backToOverview }: FriendListProps) => {
  const { t } = useTranslation();
  const [getFriendHistory] = siteApi.useLazyGetReferralFriendHistoryQuery();
  const serverInfo = useServerInfo();
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });

  // Fetch history
  const {
    data: friendHistory,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingInitialPage,
  } = useInfiniteQuery<GetReferralFriendHistoryRequest, MemberModel>(
    getFriendHistory,
    {
      query: initialQuery,
    },
    {
      onError: handleApiResponse,
    },
  );

  const isLoading = useMemo(() => {
    return isFetchingNextPage || isFetchingInitialPage;
  }, [isFetchingInitialPage, isFetchingNextPage]);

  const renderUsername = useCallback(
    (apiIdentifier: string) => {
      return `${serverInfo?.prefix}${apiIdentifier}******`;
    },
    [serverInfo?.prefix],
  );

  return (
    <div className="pt-4">
      {friendHistory.length === 0 && !isLoading && (
        <EmptyState
          title={t("no referrals yet")}
          subTitle={t("share your referral link to start earning")}
          btnText={t("get share link")}
          onGetShareLink={backToOverview}
        />
      )}

      {!!friendHistory.length && (
        <div className="flex flex-col">
          {friendHistory.map((friend) => (
            <div
              key={friend.id}
              className="mb-2 flex items-center justify-between border-b border-[#231F55] pb-2"
            >
              <span className="content-base block text-sm font-bold leading-5">
                {renderUsername(friend.apiIdentifier)}
              </span>
              <span className="content-base-secondary block text-xs leading-4">
                {moment(friend.referredAt).format("DD-MM-YYYY HH:mm")}
              </span>
            </div>
          ))}
        </div>
      )}
      {isLoading && (
        <div className="flex flex-col gap-y-4">
          <Skeleton
            className="!h-[30px] !rounded-md"
            count={8}
          />
        </div>
      )}

      {hasNextPage && (
        <Button
          loading={isFetchingNextPage}
          onClick={fetchNextPage}
          size="sm"
          variant="secondary"
          className="mx-auto !w-fit px-4"
          classes={{
            "label&": "text-xs",
          }}
        >
          {t("Show More")}
        </Button>
      )}
    </div>
  );
};

export default FriendList;
