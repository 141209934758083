import { siteApi } from "@api";
import {
  Button,
  GetReferralTxHistoryRequest,
  ReferralTxModel,
  Skeleton,
  fAmount,
  useHandleApiResponse,
  useInfiniteQuery,
} from "kz-ui-sdk";
import moment from "moment";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { EmptyState } from "..";

interface IncomeListProps {
  backToOverview?: () => void;
}

const itemsPerPage = 10;
const initialQuery: GetReferralTxHistoryRequest["query"] = {
  count: 0,
  limit: itemsPerPage,
  offset: 0,
  claimedAt: "-null",
  sort: "claimedAt:desc",
};

const IncomeList = ({ backToOverview }: IncomeListProps) => {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useState<GetReferralTxHistoryRequest["query"]>(initialQuery);
  const [getTxHistory] = siteApi.useLazyGetReferralTxHistoryQuery();
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });

  // Fetch history
  const {
    data: incomeHistory,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingInitialPage,
  } = useInfiniteQuery<GetReferralTxHistoryRequest, ReferralTxModel>(
    getTxHistory,
    {
      query: queryParams,
    },
    {
      onError: handleApiResponse,
    },
  );

  const isLoading = useMemo(() => {
    return isFetchingNextPage || isFetchingInitialPage;
  }, [isFetchingInitialPage, isFetchingNextPage]);

  return (
    <div className="pt-4">
      {incomeHistory.length === 0 && !isLoading && (
        <EmptyState
          title={t("no income yet")}
          subTitle={t("share your referral link to start earning")}
          btnText={t("get share link")}
          onGetShareLink={backToOverview}
        />
      )}

      {!!incomeHistory.length && (
        <div className="flex flex-col">
          {incomeHistory.map((tx, index) => (
            <div
              key={tx.id}
              className="mb-3 flex justify-between border-b border-[#231F55] pb-3"
            >
              <div className="flex flex-col gap-y-0.5">
                <span className="content-base block text-sm font-bold leading-5">
                  {t(tx.categoryGroup?.name ?? "Other Games")}
                </span>
                {tx.claimedAt && (
                  <span className="content-base-secondary block text-xs leading-4">
                    {moment(tx.claimedAt).format("DD-MM-YYYY HH:mm")}
                  </span>
                )}
              </div>
              <div>
                <span className="text-sm font-bold leading-5 text-[#21C97B]">{`+${fAmount(tx.amount)}`}</span>
                <span className="pl-1 text-sm leading-5 text-[#14814F]">{tx.currency}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {isLoading && (
        <div className="flex flex-col gap-y-4">
          <Skeleton
            className="!h-[50px] !rounded-md"
            count={8}
          />
        </div>
      )}

      {hasNextPage && (
        <Button
          loading={isFetchingNextPage}
          onClick={fetchNextPage}
          size="sm"
          variant="secondary"
          className="mx-auto !w-fit px-4"
          classes={{
            "label&": "text-xs",
          }}
        >
          {t("Show More")}
        </Button>
      )}
    </div>
  );
};

export default IncomeList;
