import { SVGProps } from "react";

interface IconNavContactProps extends SVGProps<SVGSVGElement> {
  active?: boolean;
}

const IconNavContact = (props: IconNavContactProps) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="transition-colors"
      {...props}
    >
      <g
        clipPath="url(#clip0_124_3073)"
        filter="url(#filter0_i_124_3073)"
      >
        <path
          d="M32.6252 13.1334C32.6252 7.00126 26.6762 2.01245 19.3642 2.01245C12.052 2.01245 6.10303 7.00131 6.10303 13.1334C6.10303 19.2656 12.052 24.2544 19.3642 24.2544C19.979 24.2544 20.5959 24.2188 21.2025 24.1486C24.0189 25.9377 26.098 26.917 27.3866 27.0602C27.4314 27.0651 27.4766 27.0676 27.5208 27.0676C27.9347 27.0676 28.3178 26.8613 28.5459 26.5154C28.8009 26.1285 28.8175 25.6376 28.5904 25.2368C28.5798 25.2177 27.5854 23.4034 27.8723 21.6702C30.8977 19.553 32.6252 16.4589 32.6252 13.1334ZM15.7627 14.813C14.6777 14.813 13.7984 13.9335 13.7984 12.8486C13.7984 11.7637 14.6777 10.8842 15.7627 10.8842C16.8476 10.8842 17.7272 11.7638 17.7272 12.8486C17.7273 13.9335 16.8476 14.813 15.7627 14.813ZM22.9659 14.813C21.8808 14.813 21.0012 13.9335 21.0012 12.8486C21.0012 11.7637 21.8808 10.8842 22.9659 10.8842C24.0507 10.8842 24.9303 11.7638 24.9303 12.8486C24.9303 13.9335 24.0507 14.813 22.9659 14.813Z"
          fill={props.active ? "#B8C3FF" : "#816ED9"}
        />
        <path
          d="M8.90437 22.0311C7.58856 20.9415 6.55133 19.6662 5.82192 18.2406C5.05282 16.7376 4.66291 15.1388 4.66291 13.4886C4.66291 11.8383 5.05287 10.2395 5.82192 8.73653C5.88079 8.62149 5.94244 8.50787 6.00524 8.39478C2.75729 10.2556 0.625 13.3613 0.625 16.872C0.625 20.0261 2.32408 22.9506 5.2924 24.9178C5.84063 27.073 4.56842 29.3532 4.55499 29.3765C4.47917 29.5102 4.48476 29.6752 4.56937 29.8036C4.6456 29.9192 4.77433 29.9877 4.91096 29.9877C4.92607 29.9877 4.94113 29.9868 4.9564 29.9851C6.44147 29.8202 9.05663 28.3108 11.0378 27.0381C11.7051 27.1287 12.3871 27.1745 13.0677 27.1745C15.6703 27.1745 18.0884 26.5089 20.0881 25.3732C19.6413 25.409 19.1913 25.4281 18.7427 25.4281C15.0441 25.4281 11.5502 24.2217 8.90437 22.0311Z"
          fill={props.active ? "#B8C3FF" : "#816ED9"}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_124_3073"
          x="0.625"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="1"
            dy="1"
          />
          <feComposite
            in2="hardAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.627451 0 0 0 0 0.811765 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_124_3073"
          />
        </filter>
        <clipPath id="clip0_124_3073">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconNavContact;
