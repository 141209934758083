import { QsMeta } from "kz-ui-sdk";

export const QUERY_ALL: QsMeta = {
  offset: 0,
  limit: 999,
};

export const SEARCH_PARAMS = {
  REF_CODE: "r",
};
