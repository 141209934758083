import { IconMSIApprovalDelegation } from "@assets";
import { openWithdraw } from "@components/utils/WithdrawDrawer";
import { delay } from "kz-ui-sdk";
import { useTranslation } from "react-i18next";

export default function WithdrawButton() {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => delay(100).then(openWithdraw)}
      className="relative h-[100px] w-full overflow-hidden rounded-md bg-[radial-gradient(57.15%_100%_at_49.73%_0%,rgba(249,43,129,0.8)_0%,rgba(27,24,114,0.8)_100%)] transition-all active:shadow-[0px_0px_16px_8px_rgba(0,_0,_0,_0.30)_inset,_0px_0px_4px_0px_rgba(0,_0,_0,_0.25)]"
    >
      <img
        className="absolute bottom-0 left-[-12px] z-0 h-[48px] w-[69px] object-contain"
        src={"/images/withdraw-left-corner.png"}
        alt={"withdraw-left-corner"}
      />
      <img
        className="absolute bottom-0 right-[-12px] z-0 h-[48px] w-[69px] object-contain"
        src={"/images/withdraw-right-corner.png"}
        alt={"withdraw-right-corner"}
      />

      <div
        className="z-[1] flex h-full w-full flex-col items-center justify-center bg-transparent bg-cover px-6 py-0"
        style={{
          backgroundImage: "url(/images/withdraw-bg.png)",
        }}
      >
        <IconMSIApprovalDelegation />
        <span className="content-base text-lg font-bold">{t("Withdraw")}</span>
      </div>
    </button>
  );
}
