import { siteApi } from "@api";
import { ONE_DAY_IN_SECONDS } from "@constants/time";
import { RewardStatus } from "@types";
import { getDiff } from "@utils";
import BigNumber from "bignumber.js";
import { WalletStatus, WalletTxMethod, bnOrZero } from "kz-ui-sdk";
import moment from "moment";
import { useMemo } from "react";
import useServerInfo from "./useServerInfo";

/**
 * rule: [current <  now  < next]
 * did claim ?
 *  - no  -> claimable
 *  - yes -> claimedAt in range (current, next) ?
 *        - no  -> claimable
 *        - yes -> show count down
 */
const QUERY_CACHE_IN_SECONDS = 2;

export interface RewardInfoData {
  remainingSeconds: number;
  periodInSeconds: number;
  claimPoints?: number;
  minClaimPoints?: number;
  initialStatus?: RewardStatus;
  type: WalletTxMethod;
}

const RewardInfoBuildType: Record<WalletTxMethod, string> = {
  [WalletTxMethod.InstantCashBack]: "cashback",
  [WalletTxMethod.DailyCashBack]: "cashback",
  [WalletTxMethod.WeeklyCashBack]: "cashback",
  [WalletTxMethod.MonthlyCashBack]: "cashback",
  [WalletTxMethod.DailyBonus]: "bonus",
} as const;

export default function useGetRewardsInfo(type?: WalletTxMethod) {
  const result = siteApi.useGetRewardsStatusQuery(undefined, {
    // If there is an existing query, but the amount of time specified since the last query has not elapsed, it will serve the existing cached data.
    refetchOnMountOrArgChange: QUERY_CACHE_IN_SECONDS,
  });

  const serverInfo = useServerInfo();
  const [manualRefetch, manualRefetchResult] = siteApi.useLazyGetRewardsStatusQuery();

  const data: Record<WalletTxMethod, RewardInfoData | null> | null = useMemo(() => {
    if (!result?.data) return null;
    const utcOffset = serverInfo?.utcOffset ?? 0;

    const dailyBonus = result.data[WalletTxMethod.DailyBonus];
    const instantCashback = result.data[WalletTxMethod.InstantCashBack];
    const dailyCashback = result.data[WalletTxMethod.DailyCashBack];
    const weeklyCashback = result.data[WalletTxMethod.WeeklyCashBack];
    const monthlyCashback = result.data[WalletTxMethod.MonthlyCashBack];

    // Validate and build reward info data
    const validatedData: Record<WalletTxMethod, RewardInfoData | null> = {
      [WalletTxMethod.DailyBonus]: buildRewardInfo(dailyBonus, WalletTxMethod.DailyBonus, utcOffset),
      [WalletTxMethod.InstantCashBack]: buildRewardInfo(instantCashback, WalletTxMethod.InstantCashBack, utcOffset),
      [WalletTxMethod.DailyCashBack]: buildRewardInfo(dailyCashback, WalletTxMethod.DailyCashBack, utcOffset),
      [WalletTxMethod.WeeklyCashBack]: buildRewardInfo(weeklyCashback, WalletTxMethod.WeeklyCashBack, utcOffset),
      [WalletTxMethod.MonthlyCashBack]: buildRewardInfo(monthlyCashback, WalletTxMethod.MonthlyCashBack, utcOffset),
    };

    return validatedData;
  }, [serverInfo?.utcOffset, result.data]);

  const refetch = async (type?: WalletTxMethod): Promise<null | RewardInfoData> => {
    const rewardInfoRes = await manualRefetch().refetch();
    if (!rewardInfoRes.data) return null;
    return type ? buildRewardInfo(rewardInfoRes.data[type], type, serverInfo?.utcOffset ?? 0) : null;
  };

  const initialRewardStatus = useMemo(() => {
    if (!!type && !!data && !!data[type]?.remainingSeconds) {
      const remaining = data[type]?.remainingSeconds;
      if (remaining !== 0) {
        return RewardStatus.LOCKED;
      } else {
        return RewardStatus.CLAIMABLE;
      }
    }
  }, [data, type]);

  const initialStatuses = useMemo(() => {
    if (!data) return null;
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (value?.remainingSeconds === 0) {
          return [key, RewardStatus.CLAIMABLE];
        }
        return [key, RewardStatus.LOCKED];
      }),
    );
  }, [data]);

  return {
    data,
    isLoading: result.isLoading,
    refetch,
    initialRewardStatus,
    initialStatuses,
  };
}

const buildRewardInfo = (
  walletStatus: WalletStatus | null | undefined,
  walletType: WalletTxMethod,
  utcOffset: number,
): RewardInfoData | null => {
  if (!walletStatus) return null;

  const remainingSeconds = getDiff(walletStatus, utcOffset);
  const type = RewardInfoBuildType[walletType];

  if (type === "cashback") {
    return {
      remainingSeconds: remainingSeconds,
      claimPoints: bnOrZero(walletStatus?.balance ?? 0)
        .dp(2, BigNumber.ROUND_DOWN)
        .toNumber(),
      periodInSeconds:
        moment(walletStatus?.nextPeriodResetDate).diff(walletStatus?.currentPeriodResetDate, "seconds") ?? 0,
      initialStatus: remainingSeconds > 0 ? RewardStatus.LOCKED : RewardStatus.CLAIMABLE,
      minClaimPoints: bnOrZero(walletStatus?.minimumClaim ?? 0)
        .dp(2, BigNumber.ROUND_DOWN)
        .toNumber(),
      type: walletType,
    };
  }

  if (type === "bonus") {
    return {
      remainingSeconds: getDiff(walletStatus, utcOffset),
      claimPoints: bnOrZero(walletStatus?.balance ?? 0)
        .dp(2, BigNumber.ROUND_DOWN)
        .toNumber(),
      periodInSeconds: ONE_DAY_IN_SECONDS,
      type: walletType,
    };
  }
  return null;
};
