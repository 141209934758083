import { siteApi } from "@api";
import { StatsTable } from "@views/ReferralPage/components";
import BigNumber from "bignumber.js";
import { cn, fAmount, fNumber, PropsWithClassName, Skeleton } from "kz-ui-sdk";
import { useTranslation } from "react-i18next";

interface StatsSectionProps extends PropsWithClassName {}

const StatsSection = ({ className }: StatsSectionProps) => {
  const { t } = useTranslation();
  const { data: statsResponse, isLoading } = siteApi.useGetReferralOverallQuery({});

  const { income, directReferral, teamReferral } = statsResponse ?? {};

  // SKELETON
  if (isLoading) {
    return (
      <div className={cn(className)}>
        <div className="mb-5 flex flex-col items-center">
          <span>
            <Skeleton className="!h-5 !w-20" />
          </span>
          <div className="mt-1.5 grid w-full grid-cols-3 gap-x-4">
            <Skeleton
              className="!h-[65px]"
              count={3}
            />
          </div>
        </div>
        <div className="mb-5 flex flex-col items-center">
          <span>
            <Skeleton className="!h-5 !w-20" />
          </span>
          <div className="mt-3 grid w-full grid-cols-3 gap-x-4">
            <Skeleton
              className="!h-[65px]"
              count={3}
            />
          </div>
          <div className="mt-3.5 grid w-full grid-cols-3 gap-x-4">
            <Skeleton
              className="!h-[65px]"
              count={3}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(className)}>
      {/*YOUR INCOME*/}
      <div className="mb-5">
        <h4 className="mb-1.5 text-center text-sm font-bold">{t("your income")}</h4>
        <StatsTable
          cols={[
            {
              title: t("referral today"),
              value: fRoundedAmount(income?.todayIncome ?? new BigNumber(0)),
              subValue: income?.currency,
              classes: {
                value: "text-[#21C97B]",
              },
            },
            {
              title: t("referral yesterday"),
              value: fRoundedAmount(income?.ytdIncome ?? new BigNumber(0)),
              subValue: income?.currency,
            },
            {
              title: t("referral lifetime"),
              value: fRoundedAmount(income?.lifeTime ?? new BigNumber(0)),
              subValue: income?.currency,
            },
          ]}
        />
      </div>

      {/*DIRECT REFERRALS*/}
      <div className="mb-5">
        <h4 className="mb-0.5 text-center text-sm font-bold">{t("direct referrals")}</h4>
        <StatsTable
          className="mb-3.5"
          title={t("registrations")}
          cols={[
            {
              title: t("referral today"),
              value: fNumber(directReferral?.todayRegistration),
              classes: {
                value: "text-[#21C97B]",
              },
            },
            {
              title: t("referral yesterday"),
              value: fNumber(directReferral?.ytdRegistration),
            },
            {
              title: t("referral lifetime"),
              value: fNumber(directReferral?.lifeTimeRegistration),
            },
          ]}
        />
        <StatsTable
          title={t("first deposits")}
          cols={[
            {
              title: t("referral today"),
              value: fNumber(directReferral?.todayFTD),
              classes: {
                value: "text-[#21C97B]",
              },
            },
            {
              title: t("referral yesterday"),
              value: fNumber(directReferral?.ytdFTD),
            },
            {
              title: t("referral lifetime"),
              value: fNumber(directReferral?.lifeTimeFTD),
            },
          ]}
        />
      </div>

      {/*TEAM DATA*/}
      <div>
        <h4 className="mb-0.5 text-center text-sm font-bold">{t("team data")}</h4>
        <StatsTable
          className="mb-3.5"
          title={t("registrations")}
          cols={[
            {
              title: t("referral today"),
              value: fNumber(teamReferral?.todayRegistration),
              classes: {
                value: "text-[#21C97B]",
              },
            },
            {
              title: t("referral yesterday"),
              value: fNumber(teamReferral?.ytdRegistration),
            },
            {
              title: t("referral lifetime"),
              value: fNumber(teamReferral?.lifeTimeRegistration),
            },
          ]}
        />
        <StatsTable
          title={t("first deposits")}
          cols={[
            {
              title: t("referral today"),
              value: fNumber(teamReferral?.todayFTD),
              classes: {
                value: "text-[#21C97B]",
              },
            },
            {
              title: t("referral yesterday"),
              value: fNumber(teamReferral?.ytdFTD),
            },
            {
              title: t("referral lifetime"),
              value: fNumber(teamReferral?.lifeTimeFTD),
            },
          ]}
        />
      </div>
    </div>
  );
};

const fRoundedAmount = (amount: BigNumber) => {
  // if amount >= 100,000 then round and remove decimal
  if (amount.gte(100_000)) {
    return fNumber(amount.dp(0, BigNumber.ROUND_DOWN));
  }
  return fAmount(amount);
};

export default StatsSection;
