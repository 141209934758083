import { useAuthSession, useTypedSelector } from "@hooks";
import { cn } from "kz-ui-sdk";
import { useTranslation } from "react-i18next";

interface BankAccountTopBarProps {
  open: boolean;
}

const BankAccountTopBar = ({ open }: BankAccountTopBarProps) => {
  const isDisplayAppInstaller = useTypedSelector((state) => state.preferences.displayAppInstaller);
  const { t } = useTranslation();
  const { bankAccount } = useAuthSession();

  return (
    <div
      className={cn(
        "absolute left-0 top-0 z-50 h-[34px] w-full overflow-y-hidden !bg-[#151240] py-1 pt-[6px] opacity-0 transition-[opacity]",
        {
          "top-10": isDisplayAppInstaller,
          "opacity-100": open,
          "pointer-events-none": !open,
        },
      )}
    >
      <div className="flex items-center justify-center gap-2 px-4">
        {bankAccount?.bankIcon && (
          <img
            className="h-[20px] w-[20px] flex-shrink-0"
            src={bankAccount.bankIcon}
            alt="icon"
          />
        )}
        <p className="content-base-secondary flex-shrink-0 text-lg capitalize leading-4">{t("My account")}:</p>
        <p className="line-clamp-1 break-words text-lg font-semibold leading-5">{bankAccount?.bankAccNo}</p>
      </div>
    </div>
  );
};

export default BankAccountTopBar;
