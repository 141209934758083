import { IconHeadsetMic } from "@assets";
import { useServerInfo } from "@hooks";
import { ButtonIcon, PropsWithClassName, cn } from "kz-ui-sdk";
import { useCallback } from "react";

interface ChatButtonProps extends PropsWithClassName {}

const ChatButton = ({ className }: ChatButtonProps) => {
  const serverInfo = useServerInfo();

  const handleOpenChat = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (!serverInfo?.contactLink) return;
      window.open(serverInfo?.contactLink, "_blank");
    },
    [serverInfo?.contactLink],
  );

  return (
    <ButtonIcon
      className={cn(className)}
      size="lg"
      onClick={handleOpenChat}
      icon={<IconHeadsetMic />}
    />
  );
};

export default ChatButton;
