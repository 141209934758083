import { PropsWithClassName, cn, delay } from "kz-ui-sdk";
import React, { HTMLAttributes, PropsWithChildren } from "react";

interface MenuButtonProps extends PropsWithChildren, PropsWithClassName, HTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  rightSection?: React.ReactNode;
}

const MenuButton = ({ className, onClick, icon, children, rightSection, ...btnProps }: MenuButtonProps) => {
  return (
    <button
      className={cn(
        "flex h-12 w-full items-center gap-x-2 rounded-md border-t border-white/10 bg-[radial-gradient(2429.88%_99.93%_at_-0.94%_44.68%,#231F55_0%,#312D6F_100%)] pr-4 transition-opacity duration-200 active:opacity-80",
        className,
      )}
      onClick={(e) => {
        delay(200).then(() => {
          onClick?.(e);
        });
      }}
      {...btnProps}
    >
      <span className="h-12 w-[60px]">{icon}</span>
      <span className="content-base mr-auto text-base font-bold leading-7">{children}</span>
      {rightSection}
    </button>
  );
};

export default MenuButton;
