import { siteApi } from "@api";
import { IconGame, IconMSICalendarToday, IconMSIKeyboardArrowDown } from "@assets";
import { BackButton } from "@components";
import { deployEnvConfig } from "@configs/deploy-env";
import { enUS, th } from "date-fns/locale";
import {
  Button,
  DateRangePicker,
  Drawer,
  DrawerTitle,
  EmptyState,
  GameTransaction,
  GameTxModel,
  GetBettingRequest,
  List,
  PageTitle,
  SelectOption,
  SelectOptions,
  Skeleton,
  copyToClipboard,
  delay,
  useHandleApiResponse,
  useInfiniteQuery,
} from "kz-ui-sdk";
import "kz-ui-sdk/dist/index.css";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const locales = {
  en: enUS,
  th: th,
};

const itemsPerPage = 10;
const initialQuery: GetBettingRequest["query"] = {
  count: 0,
  limit: itemsPerPage,
  offset: 0,
  timestamp: undefined,
  sort: "timestamp:desc",
};

const BetHistoryPage = () => {
  const [queryParams, setQueryParams] = useState<GetBettingRequest["query"]>(initialQuery);
  const [getBettingHistory] = siteApi.useLazyGetBettingHistoryQuery();
  const { data: providerResponse } = siteApi.useGetGameProviderListQuery({
    query: {
      offset: 0,
      limit: 999,
    },
  });
  const { t, i18n } = useTranslation();

  const [isShowProviderSelector, setIsShowProviderSelector] = useState(false);
  const [isShowDateRangePicker, setIsShowDateRangePicker] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<SelectOption>();
  const [dateRange, setDateRange] = useState<DateRange>();
  const [tempDateRange, setTempDateRange] = useState<DateRange>();

  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });

  // Fetch betting history
  const {
    data: bettingData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingInitialPage,
  } = useInfiniteQuery<GetBettingRequest, GameTxModel>(
    getBettingHistory,
    {
      query: queryParams,
    },
    {
      onError: handleApiResponse,
    },
  );

  // Fetch provider list
  const providerOptions: SelectOption[] = useMemo(() => {
    const options: SelectOption[] = [
      {
        id: "all",
        name: t("All Providers"),
      },
    ];

    providerResponse?.entries.forEach((provider) => {
      options.push({
        id: provider.key,
        name: !provider?.primaryImage?.url ? provider.name : "",
        imgUrl: provider?.primaryImage?.url,
      });
    });

    return options;
  }, [providerResponse?.entries, t]);

  // Update selected provider
  useEffect(() => {
    setSelectedProvider(providerOptions[0]);
  }, [providerOptions]);

  // Update query params when selected provider changes
  useEffect(() => {
    let providerKey = selectedProvider?.id;
    if (providerKey === "all") {
      providerKey = undefined;
    }

    setQueryParams((params) => ({
      ...params,
      providerKey: providerKey,
    }));
  }, [selectedProvider?.id]);

  // Update query params when date range changes
  useEffect(() => {
    let timestamp = undefined;
    const { from, to } = dateRange ?? {};
    const start = from ? moment(from).startOf("day").valueOf() / 1000 : "";
    // for end date, we need to query 00.00.00 of the next day
    const end = to ? Math.floor(moment(to).add(1, "day").startOf("day").valueOf() / 1000) : "";

    if (start || end) {
      timestamp = `${start},${end}`;
    }

    setQueryParams((params) => ({
      ...params,
      timestamp,
    }));
  }, [dateRange]);

  const handleOnSelectProvider = (option: SelectOption) => {
    setSelectedProvider(option);
    setIsShowProviderSelector(false);
  };

  const handleOnSelectDateRange = () => {
    setDateRange(tempDateRange);
    toggleDateRangePicker();
  };

  const toggleDateRangePicker = () => {
    // Reset date range if date range picker is closed
    if (isShowDateRangePicker) {
      delay().then(() => {
        // Reset temp date range
        setTempDateRange(undefined);
      });
    } else {
      // Set temp date range to current date range
      setTempDateRange(dateRange);
    }
    setIsShowDateRangePicker((prev) => !prev);
  };

  const toggleProviderSelector = () => {
    setIsShowProviderSelector((prev) => !prev);
  };

  const onTxIndexCopyClick = async (txIndex: string) => {
    try {
      await copyToClipboard(txIndex);
      toast.success(t("ID Copied!"));
    } catch (error) {
      toast.error(t("Failed to copy ID!"));
    }
  };

  const isLoading = useMemo(() => {
    return isFetchingNextPage || isFetchingInitialPage;
  }, [isFetchingInitialPage, isFetchingNextPage]);

  return (
    <div className="flex flex-col">
      <PageTitle
        label={t("Bet History")}
        backButton={<BackButton />}
      />
      <div className="align-items mt-4 flex justify-between gap-2">
        <Button
          onClick={toggleProviderSelector}
          variant="contained"
          icon={<IconMSIKeyboardArrowDown />}
          iconPosition="right"
          size="lg"
          classes={{
            "label&": "text-base font-normal content-base w-full justify-between px-3",
          }}
        >
          {selectedProvider ? (
            <>
              {selectedProvider?.imgUrl ? (
                <img
                  src={selectedProvider?.imgUrl}
                  alt={selectedProvider?.name}
                  className="h-5 w-auto"
                />
              ) : (
                <span className="whitespace-nowrap">{selectedProvider?.name}</span>
              )}
            </>
          ) : (
            <span className="whitespace-nowrap">{t("All Providers")}</span>
          )}
        </Button>
        <Button
          variant="contained"
          icon={<IconMSICalendarToday className="text-white" />}
          iconPosition="right"
          size="lg"
          classes={{
            "label&": "text-base font-normal content-base w-full justify-between px-3",
          }}
          onClick={toggleDateRangePicker}
        >
          {t("Date")}
        </Button>
      </div>
      {!!bettingData.length && (
        <>
          <List className="mt-4">
            {bettingData.map((tx) => {
              return (
                <GameTransaction
                  key={tx.id}
                  transaction={{
                    amount: tx.earnAmt.minus(tx.playAmt),
                    currency: deployEnvConfig.country.currency,
                    timestamp: tx.timestamp,
                    title: tx.description,
                    txIndex: tx.id,
                  }}
                  onTxIdCopyClick={() => onTxIndexCopyClick(tx.id)}
                  timeFormat="DD-MM-YY HH:mm"
                  classes={{
                    "idLabel&": "uppercase",
                  }}
                />
              );
            })}
          </List>

          {hasNextPage && (
            <Button
              loading={isFetchingNextPage}
              onClick={fetchNextPage}
              size="sm"
              variant="secondary"
              className="mx-auto mt-6 !w-fit px-4"
              classes={{
                "label&": "text-xs",
              }}
            >
              {t("Show More")}
            </Button>
          )}
        </>
      )}
      {bettingData.length === 0 && !isLoading && (
        <EmptyState
          className="mt-4 animate-fade-in"
          icon={<IconGame className="text-light3" />}
          title={t("No betting history found.")}
        />
      )}
      {isLoading && (
        <div className="mt-4 flex flex-col gap-y-4">
          <Skeleton
            className="!h-20 !rounded-md"
            count={8}
          />
        </div>
      )}
      <Drawer
        open={isShowProviderSelector}
        onClose={toggleProviderSelector}
        placement="bottom"
      >
        <DrawerTitle title={t("Providers")} />
        <SelectOptions
          className="mx-8 mb-6"
          options={providerOptions}
          selectedOption={selectedProvider}
          handleOnSelect={handleOnSelectProvider}
          classes={{
            "image&": "max-h-8",
          }}
        />
      </Drawer>
      <Drawer
        open={isShowDateRangePicker}
        onClose={toggleDateRangePicker}
        placement="bottom"
        classes={{
          "container&": "px-5",
        }}
      >
        <DrawerTitle title={t("Dates")} />
        <DateRangePicker
          setRange={setTempDateRange}
          range={tempDateRange}
          toDate={new Date()}
          locale={locales[(i18n.language as keyof typeof locales) ?? "en"]}
        />
        <Button
          className="mb-14 mt-6"
          size="lg"
          onClick={handleOnSelectDateRange}
        >
          {t("Submit")}
        </Button>
      </Drawer>
    </div>
  );
};

export default BetHistoryPage;
