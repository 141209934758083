import { IconMSIArrowBack } from "@assets";
import { useTypedSelector } from "@hooks";
import { ButtonIcon, Drawer, DrawerMode, DrawerTitle, Prompt, cn, delay, useCustomEventsListener } from "kz-ui-sdk";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DepositDrawer.css";
import { BankAccountTopBar, StepAmountSelection, StepAmountSelectionRef, StepMethodSelection } from "./components";
import {
  EVENT_CLOSE_DEPOSIT,
  EVENT_GO_TO_SUB_SCREEN,
  EVENT_NATIVE_DEPOSIT_BACK,
  EVENT_NATIVE_DEPOSIT_CANCEL,
  EVENT_NATIVE_DEPOSIT_CLOSE,
  EVENT_NATIVE_DEPOSIT_TIMEOUT,
  EVENT_OPEN_DEPOSIT,
} from "./constants";
import { DepositDrawerSubScreen, DepositMethod } from "./types";
import { closeDeposit } from "./utils";

const DepositDrawer = () => {
  const refAmountSelectionStep = useRef<StepAmountSelectionRef>(null);
  const { t } = useTranslation();
  const [subScreen, setSubScreen] = useState(DepositDrawerSubScreen.METHOD_SELECTION);

  const [selectedMethod, setSelectedMethod] = useState<DepositMethod>();
  const [drawerDisplayMode, setDrawerDisplayMode] = useState<DrawerMode>("compact");
  const [nativeDepositCloseMode, setNativeDepositCloseMode] = useState<"close" | "back">("close");

  const [isOpenDeposit, setIsOpenDeposit] = useState(false);

  // Display timeout dialog when native deposit is timed out
  const [isDisplayTimedOutDialog, setIsDisplayTimedOutDialog] = useState(false);

  const [isDisplayCancelConfirmDialog, setIsDisplayCancelConfirmDialog] = useState(false);
  const [isDisplayCloseConfirmDialog, setIsDisplayCloseConfirmDialog] = useState(false);

  const isDisplayAppInstaller = useTypedSelector((state) => state.preferences.displayAppInstaller);
  const isDisplayBankTopBar = useMemo(() => {
    // Only display bank top bar on method & amount selection screen
    return (
      isOpenDeposit &&
      [DepositDrawerSubScreen.AMOUNT_SELECTION, DepositDrawerSubScreen.METHOD_SELECTION].includes(subScreen)
    );
  }, [subScreen, isOpenDeposit]);

  useEffect(() => {
    if (!isOpenDeposit) {
      delay(1000).then(() => {
        resetDrawerState();
      });
    }
    return () => {
      delay(1000).then(() => {
        resetDrawerState();
      });
    };
  }, [isOpenDeposit]);

  useCustomEventsListener({
    [EVENT_CLOSE_DEPOSIT]: () => setIsOpenDeposit(false),
    [EVENT_OPEN_DEPOSIT]: () => setIsOpenDeposit(true),
    [EVENT_GO_TO_SUB_SCREEN]: (event: CustomEvent) => {
      const subScreen = event.detail as DepositDrawerSubScreen;
      setSubScreen(subScreen);
    },
    [EVENT_NATIVE_DEPOSIT_CANCEL]: () => {
      setIsDisplayCancelConfirmDialog(true);
      setNativeDepositCloseMode("close");
    },
    [EVENT_NATIVE_DEPOSIT_CLOSE]: () => {
      setIsDisplayCloseConfirmDialog(true);
      setNativeDepositCloseMode("close");
    },
    [EVENT_NATIVE_DEPOSIT_TIMEOUT]: () => {
      setIsDisplayCancelConfirmDialog(false);
      setIsDisplayTimedOutDialog(true);
      closeDeposit();
    },
    [EVENT_NATIVE_DEPOSIT_BACK]: () => {
      setIsDisplayCancelConfirmDialog(true);
      setNativeDepositCloseMode("back");
    },
  });

  useEffect(() => {
    switch (subScreen) {
      case DepositDrawerSubScreen.METHOD_SELECTION:
        // Compact mode for method selection screen & reset selected method
        setDrawerDisplayMode("compact");
        delay().then(() => setSelectedMethod(undefined));
        break;
      case DepositDrawerSubScreen.AMOUNT_SELECTION:
        // Compact mode for amount selection screen
        setDrawerDisplayMode("compact");
        break;
      case DepositDrawerSubScreen.NATIVE_DEPOSIT:
        // Full screen mode for native deposit screen
        setDrawerDisplayMode("full-screen");
        break;
    }
  }, [subScreen]);

  const resetDrawerState = () => {
    // Reset to method selection screen
    setSubScreen(DepositDrawerSubScreen.METHOD_SELECTION);
  };

  const handleOnBack = useCallback(() => {
    // Back to method selection screen
    setSubScreen(DepositDrawerSubScreen.METHOD_SELECTION);
  }, []);

  // Select deposit method & move to amount selection screen
  const handleSelectMethod = (newMethod: DepositMethod) => {
    setSelectedMethod(newMethod);
    setSubScreen(DepositDrawerSubScreen.AMOUNT_SELECTION);
  };

  // Start native deposit & move to native deposit screen
  const handleOnStartNativeDeposit = () => {
    setSubScreen(DepositDrawerSubScreen.NATIVE_DEPOSIT);
  };

  const handleCancelDeposit = () => {
    setIsDisplayCancelConfirmDialog(false);
    setIsDisplayCloseConfirmDialog(false);
    // Close deposit drawer
    if (nativeDepositCloseMode === "close") {
      closeDeposit();
    }

    // Back to amount selection screen
    if (nativeDepositCloseMode === "back") {
      if (selectedMethod?.skipAmountInput) {
        // Skip amount selection screen
        setSubScreen(DepositDrawerSubScreen.METHOD_SELECTION);
      } else {
        refAmountSelectionStep.current?.backToAmountSelection();
        setSubScreen(DepositDrawerSubScreen.AMOUNT_SELECTION);
      }
    }
  };

  const isDisplayBackBtn = useMemo(() => {
    return subScreen === DepositDrawerSubScreen.AMOUNT_SELECTION;
  }, [subScreen]);

  return (
    <>
      <BankAccountTopBar open={isDisplayBankTopBar} />

      <Drawer
        open={isOpenDeposit}
        onClose={() => setIsOpenDeposit(false)}
        safeZone
        classes={{
          "drawerBody&": "z-10 overflow-y-hidden h-full flex flex-col",
          "gap&": "!duration-500 transition-[opacity,max-height] max-h-[calc(100dvh-420px)] overflow-y-hidden",
          "container&": cn("!px-0 !py-0", {
            "h-[420px] max-h-[420px]": [
              DepositDrawerSubScreen.METHOD_SELECTION,
              DepositDrawerSubScreen.AMOUNT_SELECTION,
            ].includes(subScreen),
            "!duration-500": [DepositDrawerSubScreen.AMOUNT_SELECTION, DepositDrawerSubScreen.NATIVE_DEPOSIT].includes(
              subScreen,
            ),
            "!h-dvh max-h-[100dvh]": subScreen === DepositDrawerSubScreen.NATIVE_DEPOSIT && !isDisplayAppInstaller,
            "!h-[calc(100dvh-40px)] !mt-10":
              subScreen === DepositDrawerSubScreen.NATIVE_DEPOSIT && isDisplayAppInstaller,
          }),
        }}
        variant={drawerDisplayMode}
      >
        {/*Hide drawer title on native deposit screen*/}
        {
          <DrawerTitle
            title={t("Deposit")}
            size="lg"
            backButton={
              isDisplayBackBtn && (
                <ButtonIcon
                  className="animate-fade-in"
                  onClick={handleOnBack}
                  icon={<IconMSIArrowBack />}
                  variant="ghost"
                />
              )
            }
            classes={{
              "root&": cn("mx-5 -mb-1 mt-4 max-h-[34px] transition-[max-height,opacity] overflow-hidden", {
                "opacity-0  max-h-[0px] !m-0 pointer-events-none": subScreen === DepositDrawerSubScreen.NATIVE_DEPOSIT,
              }),
            }}
          />
        }
        <div className="relative h-full overflow-hidden">
          {/*SELECT METHOD SCREEN*/}
          <div
            className={cn("transition-transform", {
              "slide-out-method": subScreen !== DepositDrawerSubScreen.METHOD_SELECTION,
              "slide-in-method": subScreen === DepositDrawerSubScreen.METHOD_SELECTION,
            })}
          >
            <StepMethodSelection onChange={handleSelectMethod} />
          </div>
          {/*SELECT AMOUNT & NATIVE DEPOSIT SCREEN*/}
          <div
            className={cn("absolute inset-0 flex h-full flex-col transition-transform", {
              "slide-in-amount": subScreen === DepositDrawerSubScreen.AMOUNT_SELECTION,
              "slide-out-amount": subScreen === DepositDrawerSubScreen.METHOD_SELECTION,
            })}
          >
            {selectedMethod && (
              <StepAmountSelection
                method={selectedMethod}
                ref={refAmountSelectionStep}
                nativeFlowProps={{
                  onStart: handleOnStartNativeDeposit,
                }}
              />
            )}
          </div>
        </div>
      </Drawer>

      {/*TIMEOUT DIALOG*/}

      <Prompt
        open={isDisplayTimedOutDialog}
        onClose={() => setIsDisplayTimedOutDialog(false)}
        onConfirm={() => setIsDisplayTimedOutDialog(false)}
        title={t("deposit timed out")}
        messages={{
          confirm: t("ok"),
        }}
        withCancelButton={false}
        dialogClasses={{
          classes: {
            "backDrop&": "bg-[radial-gradient(50%_100%_at_50%_0%,#1F0E67_0%,#141240_100%)] px-6",
          },
        }}
      />

      {/*CONFIRM CANCEL DIALOG*/}
      <Prompt
        open={isDisplayCancelConfirmDialog}
        onClose={() => setIsDisplayCancelConfirmDialog(false)}
        onConfirm={handleCancelDeposit}
        title={t("confirm")}
        content={t("Are you sure you want to cancel?")}
        zIndex={40}
        messages={{
          confirm: t("confirm"),
          cancel: t("close"),
        }}
      />

      {/*CONFIRM CLOSE DIALOG*/}
      <Prompt
        open={isDisplayCloseConfirmDialog}
        onClose={() => setIsDisplayCloseConfirmDialog(false)}
        onConfirm={handleCancelDeposit}
        title={t("confirm")}
        content={t("are you sure you want to close")}
        zIndex={40}
        messages={{
          confirm: t("confirm"),
          cancel: t("return"),
        }}
      />
    </>
  );
};

export default DepositDrawer;
