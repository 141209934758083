import { FormPIN, OnboardPageLayout } from "@components";
import { OnboardMode, Paths, STORAGE_KEY } from "@constants";
import { useMemo } from "react";
import { generatePath, useLocation } from "react-router-dom";

const LoginPage = () => {
  const { state } = useLocation();
  const onboardingNumber = sessionStorage.getItem(STORAGE_KEY.ONBOARDING_PHONE);

  const phone = useMemo(() => {
    return state?.phone || onboardingNumber || "";
  }, [onboardingNumber, state?.phone]);

  return (
    <OnboardPageLayout
      backTo={generatePath(Paths.PUBLIC.ONBOARD, {
        mode: OnboardMode.LOGIN,
      })}
      navigateOptions={{
        replace: true,
      }}
    >
      <FormPIN phone={phone} />
    </OnboardPageLayout>
  );
};

export default LoginPage;
