import { SVGProps } from "react";

interface IconNavDepositProps extends SVGProps<SVGSVGElement> {
  active?: boolean;
}

const IconNavDepositGlow = (props: IconNavDepositProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="46"
      viewBox="0 0 51 46"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_dd_8188_2157)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.943 16H20.143C18.163 16 16.543 17.4 16.543 19.1111C16.543 20.4333 17.443 21.5222 18.793 21.9889V19.1111C18.793 18.8 18.973 18.4889 19.153 18.2556C19.333 18.0222 19.783 17.9444 20.143 17.9444H30.943C31.303 17.9444 31.663 18.1 31.933 18.2556C32.203 18.4111 32.293 18.8 32.293 19.1111V21.9889C33.643 21.5222 34.543 20.4333 34.543 19.1111C34.543 17.4 32.923 16 30.943 16ZM30.943 28.4444V19.1111H20.143V28.4444C20.143 29.3 20.953 30 21.943 30H29.143C30.133 30 30.943 29.3 30.943 28.4444ZM24.823 23.3111L24.193 23.8556C23.923 24.0889 23.473 24.0889 23.203 23.8556C22.933 23.6222 22.933 23.2333 23.203 23L24.373 21.9889C25.003 21.4444 25.993 21.4444 26.623 21.9889L27.793 23C28.063 23.2333 28.063 23.6222 27.793 23.8556C27.523 24.0889 27.073 24.0889 26.803 23.8556L26.173 23.3111V26.5778C26.173 26.8889 25.903 27.2 25.453 27.2C25.003 27.2 24.733 26.9667 24.733 26.5778V23.3111H24.823Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_8188_2157"
          x="-0.457031"
          y="-3"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          {/*<feGaussianBlur stdDeviation="8" />*/}
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.796078 0 0 0 0 0.894118 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8188_2157"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.631373 0 0 0 0 0.807843 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8188_2157"
            result="effect2_dropShadow_8188_2157"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8188_2157"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default IconNavDepositGlow;
