import { MaintenanceOverlay } from "@components";
import { DepositMethod } from "@components/utils/DepositDrawer/types";
import { cn, MaskedImg, PropsWithClassName } from "kz-ui-sdk";
import { AspectRatio } from "react-aspect-ratio";

interface DepositMethodOptionProps extends PropsWithClassName {
  method: DepositMethod;
  onClick?: (method: DepositMethod) => void;
  labelClassName?: string;
  btnClassName?: string;
  maskedImgClassName?: string;
}

const DepositMethodOption = ({
  method,
  onClick,
  className,
  labelClassName,
  btnClassName,
  maskedImgClassName,
}: DepositMethodOptionProps) => {
  return (
    <AspectRatio
      key={method.key}
      ratio="200/76"
      className={cn(className)}
    >
      <button
        className={cn(
          `relative flex h-full w-full flex-shrink-0 items-center justify-center shadow-[0px_8px_16px_0px_rgba(0,0,0,0.25)]`,
          {
            "bg-white": !method.content?.bannerUrl,
          },
          btnClassName,
        )}
        onClick={() => onClick?.(method)}
      >
        <MaintenanceOverlay
          underMaintenance={!method.content.enabled}
          text={"Temporary Unavailable"}
          className="rounded-lg"
        >
          {method.content?.bannerUrl && (
            <MaskedImg
              src={method.content?.bannerUrl}
              alt={method.key}
              className={cn("absolute inset-0 h-full w-full rounded-xl !bg-[white] object-cover", maskedImgClassName)}
              imgClassName="object-cover h-full w-full"
            />
          )}
          {!method.content?.bannerUrl && (
            <span
              className={cn(
                "line-clamp-2 px-2 py-1.5 text-2xl font-semibold leading-[2rem] text-gray-700",
                labelClassName,
              )}
            >
              {method.content?.name}
            </span>
          )}
        </MaintenanceOverlay>
      </button>
    </AspectRatio>
  );
};

export default DepositMethodOption;
