export enum MarketingDataParam {
  PIXEL_ID = "pixelid",
  FBCL_ID = "fbclid",
  CODE = "code",
}

export interface MarketingData {
  [MarketingDataParam.PIXEL_ID]: string;
  [MarketingDataParam.FBCL_ID]: string;
  [MarketingDataParam.CODE]: string;
}
