import { IconNavContact, IconNavHome, IconNavProfile } from "@assets";
import IconNavGames from "@assets/icons/IconNavGames";
import { CoinHeartbeat, DepositPulseIcon, openDeposit } from "@components";
import { Paths } from "@constants";
import { usePromotionStatus, useServerInfo, useTypedSelector } from "@hooks";
import {
  BottomNavigationAction,
  BottomNavigation as BottomNavigationUIKit,
  PropsWithClassName,
  WarningWrapper,
  cn,
} from "kz-ui-sdk";
import { PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface BottomNavigationProps extends PropsWithChildren, PropsWithClassName {}

const BottomNavigation = ({ className }: BottomNavigationProps) => {
  const { t } = useTranslation();
  const { contactLink: preLoginContactLink } = useServerInfo() ?? {};
  const { contactLink: postLoginContactLink } = useTypedSelector((state) => state.auth);

  const handleOpenContact = useCallback(() => {
    window.open(postLoginContactLink ?? preLoginContactLink, "_blank");
  }, [preLoginContactLink, postLoginContactLink]);

  const { isAnyClaimable: isPromotionsClaimable } = usePromotionStatus();

  return (
    <>
      <BottomNavigationUIKit className={cn(className)}>
        <NavLink
          to={Paths.PRIVATE.HOME}
          className="h-[56px] w-[56px] flex-shrink-0"
        >
          {({ isActive }) => (
            <BottomNavigationAction
              icon={
                <WarningWrapper
                  isShown={isPromotionsClaimable ?? false}
                  classes={{
                    "span&": "right-[-4px] top-[-2px] !h-[16px] !w-[16px] !text-[10px] border-2 border-[#291c5a]",
                  }}
                  icon={
                    <CoinHeartbeat
                      className="absolute -right-1 -top-1"
                      imgClassName="h-[13px] w-[13px]"
                    />
                  }
                >
                  <IconNavHome
                    active={isActive}
                    width={20}
                    height={20}
                  />
                </WarningWrapper>
              }
              label={t("Home")}
              active={isActive}
              className="w-full"
            />
          )}
        </NavLink>
        <BottomNavigationAction
          icon={<DepositPulseIcon />}
          label={t("Deposit")}
          onClick={openDeposit}
          className="h-[56px] w-[56px]"
        />
        <NavLink
          to={Paths.PRIVATE.GAMES}
          className="group inline-block h-[60px] w-[60px] translate-y-[-10px]"
        >
          {({ isActive }) => (
            <div
              className={cn(
                "relative h-full w-full rounded-full bg-[linear-gradient(180deg,#9C40FF_0%,#3C39A8_100%)] shadow-[0px_4px_6px_0px_rgba(0,_0,_0,_0.24)] transition-transform will-change-transform",
                {
                  "translate-y-0.5": isActive,
                },
              )}
            >
              {/*COLOR MIST BG*/}
              <div
                className="absolute left-0 top-0 h-full w-full animate-spin rounded-full will-change-transform [animation-duration:16s]"
                style={{
                  backgroundImage: "url(/images/bg-nav-game.png)",
                }}
              />

              <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
                <IconNavGames
                  width={18}
                  height={18}
                  className="content-base"
                />
                <span className="content-base text-xs font-bold">{t("Games")}</span>
              </div>

              {/*RIM LIGHT*/}
              <div
                className={`absolute left-0 top-0 h-full w-full rounded-full border-0 border-dashed border-[rgba(0,0,0,0.20)] bg-[radial-gradient(46.67%_23.33%_at_50%_100%,_rgba(114,_187,_255,_0.80)_0%,_rgba(75,_69,_234,_0.00)_100%)] shadow-[inset_0px_2px_1px_0px_rgba(255,255,255,0.24),_inset_0px_4px_4px_0px_rgba(255,255,255,0.25)]`}
              />
              {/*GRAY OVERLAY*/}
              <div className={`absolute left-0 top-0 h-full w-full rounded-full bg-[rgba(217,217,217,0.01)]`} />
            </div>
          )}
        </NavLink>
        <NavLink
          to={Paths.PRIVATE.PROFILE}
          className="h-[56px] w-[56px] flex-shrink-0"
        >
          {({ isActive }) => (
            <BottomNavigationAction
              icon={
                <IconNavProfile
                  active={isActive}
                  width={22}
                  height={22}
                />
              }
              label={t("Profile")}
              active={isActive}
              className="w-full"
            />
          )}
        </NavLink>
        <BottomNavigationAction
          icon={
            <IconNavContact
              width={20}
              height={20}
            />
          }
          label={t("Contact")}
          onClick={handleOpenContact}
          className="h-[56px] w-[56px] flex-shrink-0"
        />
      </BottomNavigationUIKit>
    </>
  );
};

export default BottomNavigation;
