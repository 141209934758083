import { publicApi } from "@api";
import { AppInstaller, AppVersion } from "@components";
import { FONT_FAMILY_CLASSES, Paths, SEARCH_PARAMS } from "@constants";
import { Language } from "@types";
import { storeReferralCode } from "@utils";
import { PageTransitionOverlay, cn } from "kz-ui-sdk";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

interface RootRouteLayoutProps extends PropsWithChildren {}

const PRIVATE_PATHS = Object.values(Paths.PRIVATE);

const RootRouteLayout = ({ children }: RootRouteLayoutProps) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data: serverConfig } = publicApi.useGetServerConfigQuery(
    {
      env: import.meta.env.VITE_DEPLOY_ENV,
    },
    {
      skip: import.meta.env.VITE_DEPLOY_ENV === "local",
    },
  );

  const overlayClassName = useMemo(() => {
    if (PRIVATE_PATHS.includes(location.pathname)) {
      return "z-[5]";
    }
    return "z-[1030]";
  }, [location.pathname]);

  const underMaintenance = useMemo(
    () => serverConfig?.underMaintenance ?? import.meta.env.VITE_MAINTENANCE === "true",
    [serverConfig],
  );

  //Redirect to maintenance if server config is maintenance
  useEffect(() => {
    if (underMaintenance) {
      navigate(Paths.PUBLIC.MAINTENANCE, {
        replace: true,
      });
    }
  }, [underMaintenance, navigate, serverConfig]);

  // Capture referral code from any URL
  useEffect(() => {
    const refCode = searchParams.get(SEARCH_PARAMS.REF_CODE);
    storeReferralCode(refCode);
  }, [searchParams]);

  const fontFamily = useMemo(() => {
    return FONT_FAMILY_CLASSES[i18n.language as Language] ?? FONT_FAMILY_CLASSES[Language.EN];
  }, [i18n.language]);

  // Update font family to body
  useEffect(() => {
    // Remove all font family classes
    document.body.classList.remove(...Object.values(FONT_FAMILY_CLASSES));
    // Add current font family class
    document.body.classList.add(fontFamily);
  }, [i18n.language, fontFamily]);

  // if ((isFetching || underMaintenance) && !matchPath(Paths.PUBLIC.MAINTENANCE, location.pathname)) return null;

  return (
    <div className={cn("w-screen")}>
      <AppInstaller />
      <AppVersion />
      <PageTransitionOverlay
        className={cn(overlayClassName)}
        key={location.pathname}
      />
      {children}
    </div>
  );
};

export default RootRouteLayout;
