import { IconMSIContentCopy } from "@assets";
import { useServerInfo } from "@hooks";
import useAuthSession from "@hooks/useAuthSession";
import { CopyWrapper, PropsWithClassName, cn } from "kz-ui-sdk";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface UsernameBadgeProps extends PropsWithClassName {}

const UsernameBadge = ({ className }: UsernameBadgeProps) => {
  const { t } = useTranslation();
  const serverInfo = useServerInfo();
  const { self } = useAuthSession();

  const username = `${serverInfo?.prefix}${self?.member?.apiIdentifier}`;

  return (
    <div className={cn(className)}>
      <CopyWrapper
        text={username}
        onSuccess={() => {
          toast.success(t("ID Copied!"));
        }}
        onError={() => {
          toast.error(t("Failed to copy ID!"));
        }}
        className="group bg-transparent bg-none"
      >
        <div className="flex items-center gap-2 p-1 px-0 transition-opacity active:opacity-70">
          <span className="content-base text-lg">{username}</span>{" "}
          {
            <IconMSIContentCopy className="content-base-secondary scale-125 transition-transform group-active:!scale-110" />
          }
        </div>
      </CopyWrapper>
    </div>
  );
};

export default UsernameBadge;
