import { authApi } from "@api";
import { Paths } from "@constants";
import { Button, cn, copyToClipboard, PropsWithClassName, Skeleton } from "kz-ui-sdk";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { MdContentCopy } from "react-icons/md";
import { generatePath } from "react-router-dom";

interface ShareLinkSectionProps extends PropsWithClassName {}

const ShareLinkSection = ({ className }: ShareLinkSectionProps) => {
  const { t } = useTranslation();
  const { data: profileResponse, isLoading } = authApi.useGetProfileQuery();

  const [referralLink, setReferralLInk] = useState("");

  // Generate referral link
  useEffect(() => {
    if (profileResponse) {
      const siteUrl = window.location.protocol + "//" + window.location.host;
      const refLink =
        siteUrl +
        generatePath(Paths.PUBLIC.REFERRAL_CALLBACK, { refCode: profileResponse?.self?.member?.referralCode });
      setReferralLInk(refLink);
    }
  }, [profileResponse]);

  const handleOnCopy = async () => {
    try {
      await copyToClipboard(referralLink);
      toast.success(t("referral link copied"));
    } catch (error) {
      toast.error(t("failed to copy your share link"));
    }
  };

  // SKELETON
  if (isLoading) {
    return <Skeleton className={cn("!h-[120px]", className)} />;
  }

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-y-1 rounded-md border border-[#231F55] bg-[radial-gradient(51.67%_51.19%_at_51.67%_0%,_#312D6F_0%,_#151240_100%)] p-3",
        className,
      )}
    >
      <h3 className="text-xs font-bold leading-4">{t("your share link")}</h3>
      <input
        className="content-base h-[38px] w-full rounded-md border border-[#45408D] bg-[#0C0629] px-2 text-center text-xs outline-0 focus:border-[#817bd7]"
        value={referralLink}
        onClick={(e) => e.currentTarget.select()}
        readOnly
      />
      <div className="mt-1.5">
        <Button
          icon={<MdContentCopy className="h-4 w-4" />}
          size="sm"
          className="w-fit px-3"
          classes={{
            "label&": "gap-x-1 text-xs",
          }}
          onClick={handleOnCopy}
        >
          {t("copy link")}
        </Button>
      </div>
    </div>
  );
};

export default ShareLinkSection;
