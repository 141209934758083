export const STORAGE_KEY = {
  ACCESS_TOKEN: "token",
  //remember phone for next time log in
  LOGIN_PHONE: "login-phone",
  //only remember phone while onboard
  //if switch tab the onboarding phone is still be passed to login/register component
  ONBOARDING_PHONE: "onboarding-phone",
  LANGUAGE: "lng",
  MODE: "mode",
  OTP_TIMEOUT_PREFIX: "pk-",
  MARKETING_DATA: "mktg-data",
  REF_CODE: "ref-code",
} as const;

export const LANGUAGES = [
  {
    code: "en",
    name: "English",
    flagURL: "/images/flags/flag-uk.svg",
  },
  {
    code: "th",
    name: "ภาษาไทย",
    flagURL: "/images/flags/flag-th.svg",
  },
];

export enum OnboardMode {
  LOGIN = "login",
  REGISTER = "register",
}

export const ACCESS_TOKEN_ENDPOINT = "/oauth/access_token";
