import createSagaMiddleware from "@redux-saga/core";
import authSlices from "./auth/slices";

import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";

import { apiMiddlewares, apiReducers } from "@api";
import preferencesSlices from "@stores/preference/slices.ts";
import mainSaga from "@stores/saga.ts";

const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers({
  auth: authSlices.reducer,
  preferences: preferencesSlices.reducer,
  ...apiReducers,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "userProfile/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }).concat([sagaMiddleware, ...apiMiddlewares]),
});

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
