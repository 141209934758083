import { SVGProps } from "react";

interface IconNavHomeProps extends SVGProps<SVGSVGElement> {
  active?: boolean;
}

const IconNavHome = ({ active, ...props }: IconNavHomeProps) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="transition-colors"
      {...props}
    >
      <g filter="url(#filter0_i_128_8848)">
        <path
          d="M27.095 9.09333L19.415 3.72C17.3217 2.25333 14.1083 2.33333 12.095 3.89333L5.41499 9.10666C4.08165 10.1467 3.02832 12.28 3.02832 13.96V23.16C3.02832 26.56 5.78832 29.3333 9.18832 29.3333H23.5617C26.9617 29.3333 29.7217 26.5733 29.7217 23.1733V14.1333C29.7217 12.3333 28.5617 10.12 27.095 9.09333ZM17.375 24C17.375 24.5467 16.9217 25 16.375 25C15.8283 25 15.375 24.5467 15.375 24V20C15.375 19.4533 15.8283 19 16.375 19C16.9217 19 17.375 19.4533 17.375 20V24Z"
          fill={active ? "#FFFFFF" : "#816ED9"}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_128_8848"
          x="0.375"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="-1"
            dy="1"
          />
          <feComposite
            in2="hardAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.626464 0 0 0 0 0.813232 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_128_8848"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default IconNavHome;
