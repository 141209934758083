import { SVGProps } from "react";

interface IconNavDepositProps extends SVGProps<SVGSVGElement> {
  active?: boolean;
}

const IconNavDepositRays = (props: IconNavDepositProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      {...props}
    >
      <circle
        opacity="0.6"
        cx="16.6393"
        cy="16.0967"
        r="9"
        stroke="#958ED8"
        strokeWidth="6"
        strokeLinejoin="round"
        strokeDasharray="1 6.1"
      />
    </svg>
  );
};

export default IconNavDepositRays;
