import { SVGProps } from "react";

interface IconNavGamesProps extends SVGProps<SVGSVGElement> {
  active?: boolean;
}

const IconNavGames = (props: IconNavGamesProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
    >
      <g clipPath="url(#clip0_3554_1591)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1805 3.27757C11.4783 2.98919 11.6623 2.59226 11.6623 2.15408C11.6623 1.27278 10.918 0.55835 10 0.55835C9.08201 0.55835 8.33781 1.27278 8.33781 2.15408C8.33781 2.59234 8.52184 2.98934 8.81978 3.27773L6.32016 7.4788C6.28816 7.52915 6.23725 7.5658 6.17794 7.5812C6.15141 7.59275 6.12262 7.59872 6.09349 7.59872C6.06437 7.59872 6.03558 7.59275 6.00905 7.5812L3.17583 5.93078C3.27695 5.7247 3.33348 5.49448 3.33348 5.25158C3.33348 4.37028 2.58928 3.65585 1.67126 3.65585C0.753236 3.65585 0.0090332 4.37028 0.0090332 5.25158C0.0090332 6.07628 0.660712 6.75486 1.49668 6.83862L2.97794 13.9641C3.04956 14.303 3.24078 14.6077 3.51932 14.8269C3.79786 15.0462 4.14666 15.1664 4.50683 15.1673H15.4935C15.8537 15.1664 16.2025 15.0462 16.481 14.8269C16.7595 14.6077 16.9508 14.303 17.0224 13.9641L18.5084 6.83903C19.3463 6.75708 20.0001 6.07763 20.0001 5.25158C20.0001 4.37028 19.2559 3.65585 18.3379 3.65585C17.4199 3.65585 16.6757 4.37028 16.6757 5.25158C16.6757 5.49898 16.7343 5.73323 16.839 5.94222L13.9913 7.5812C13.9647 7.59275 13.936 7.59872 13.9068 7.59872C13.8777 7.59872 13.8489 7.59275 13.8224 7.5812C13.7631 7.5658 13.7122 7.52915 13.6802 7.4788L11.1805 3.27757Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3554_1591">
          <rect
            width="20"
            height="16"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconNavGames;
