import { siteApi } from "@api";
import { IconGame, IconMSISearch } from "@assets";
import { useDebounce } from "@uidotdev/usehooks";
import { GameEntry, GameEntrySkeleton } from "@views/GamesPage/components";
import { useGameGateway } from "@views/GamesPage/hooks";

import { BackButton } from "@components";
import { APP_INSTALLER_HEIGHT } from "@constants";
import { useTypedSelector } from "@hooks";
import { CATEGORY_POPULAR_SLUG } from "@views/GamesPage/contants";
import {
  EmptyState,
  GameCategoryModel,
  GameModel,
  GameStatus,
  GetGameListRequest,
  PageTitle,
  TextField,
  cn,
  useInfiniteQuery,
} from "kz-ui-sdk";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useTranslation } from "react-i18next";

interface SearchModalProps extends PropsWithChildren {
  defaultOpen?: boolean;
  onClose?: () => void;
  trigger?: React.ReactNode;
  preloadCategory?: GameCategoryModel;
}

const ITEMS_PER_PAGE = 10;
const initialGameQuery: GetGameListRequest["query"] = {
  limit: ITEMS_PER_PAGE,
  offset: 0,
  status: GameStatus.Active,
};

const GameSearchModal = ({ defaultOpen = false, onClose, trigger, preloadCategory }: SearchModalProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { t } = useTranslation();
  const isDisplayAppInstaller = useTypedSelector((state) => state.preferences.displayAppInstaller);

  const popularCategoryId = useMemo(() => {
    if (preloadCategory?.slug === CATEGORY_POPULAR_SLUG) {
      return preloadCategory.id;
    }
  }, [preloadCategory?.id, preloadCategory?.slug]);

  const [gameQueryParams, setQueryParams] = useState<GetGameListRequest["query"]>({
    ...initialGameQuery,
    // Default to popular category
    category: popularCategoryId,
  });
  const refSearchResultContainer = useBottomScrollListener<HTMLDivElement>(
    () => {
      if (hasNextPage && !isFetchingMoreResults) {
        fetchNextPage();
      }
    },
    {
      offset: 40,
    },
  );

  const { open: openGame, providerMap } = useGameGateway({
    onSuccess: () => {
      setIsOpen(false);
    },
  });
  const [getGameList] = siteApi.useLazyGetGameListQuery();

  const [searchTerm, setSearchTerm] = useState<string>();
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Fetch game list
  const {
    data: gameData,
    isFetchingInitialPage: isSearching,
    isFetchingNextPage: isFetchingMoreResults,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery<GetGameListRequest, GameModel>(getGameList, {
    query: gameQueryParams,
  });

  // Update game list when search term changes
  useEffect(() => {
    // Load popular category games if search term is empty
    const categoryId = !!debouncedSearchTerm ? undefined : popularCategoryId;

    setQueryParams({
      ...initialGameQuery,
      search: debouncedSearchTerm,
      category: categoryId,
    });
  }, [debouncedSearchTerm, popularCategoryId]);

  const handleOnBack = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value ?? undefined);
  };

  return (
    <>
      <span>
        {trigger || (
          <button
            onClick={() => setIsOpen(true)}
            className="h-[56px] w-[64px] rounded-md bg-[linear-gradient(180deg,#312D6F_0%,#231F55_100%)]"
          >
            <div className={"flex flex-col items-center justify-between gap-0.5"}>
              <IconMSISearch />
              <span className="content-base text-xs font-semibold">{t("Search")}</span>
            </div>
          </button>
        )}
      </span>

      {isOpen && (
        <div
          className={cn(
            "fixed left-1/2 top-0 z-50 flex h-dvh w-screen max-w-md -translate-x-1/2 animate-fade-in flex-col bg-black/70 px-5 pb-0 pt-3 backdrop-blur-2xl",
            `duration-300 ease-in-out data-[closed]:pointer-events-none data-[closed]:opacity-0 data-[enter]:data-[closed]:opacity-0 data-[leave]:data-[closed]:opacity-0`,
          )}
          style={{
            height: `calc(100vh - ${isDisplayAppInstaller ? APP_INSTALLER_HEIGHT : "0"}px)`,
            top: isDisplayAppInstaller ? APP_INSTALLER_HEIGHT : 0,
          }}
        >
          <PageTitle
            label={t("Search")}
            backButton={<BackButton onClick={handleOnBack} />}
            className="mb-3.5"
          />
          <TextField
            placeholder={t("Find a game")}
            icon={<IconMSISearch className="scale-110" />}
            classes={{
              "input&": "font-semibold w-full !pl-10 placeholder-[#9389FC]",
              "iconContainer&": "!top-3.5 !left-3",
            }}
            size={"lg"}
            onChange={handleSearchInputChange}
          />

          <div
            className="scrollbar-primary -mx-6 mt-5 grow overflow-y-auto px-6"
            ref={refSearchResultContainer}
          >
            <div className="flex flex-col gap-y-4 pb-4">
              {!isSearching &&
                gameData.map((game) => (
                  <GameEntry
                    key={[game.providerRef, game.gameProviderId].join("-")}
                    variant={"row"}
                    game={game}
                    onOpen={openGame}
                    providerName={providerMap[game.gameProviderId]?.name}
                    sourceRef={providerMap[game.gameProviderId]?.sourceRef}
                    maintenance={game.isMaintenance || providerMap[game.gameProviderId]?.isMaintenance}
                  />
                ))}
              {(isSearching || isFetchingMoreResults) && (
                <GameEntrySkeleton
                  variant="row"
                  count={ITEMS_PER_PAGE}
                />
              )}
              {!isSearching && gameData.length === 0 && (
                <EmptyState
                  title={t("No games found")}
                  subTitle={t("Try a different search term")}
                  icon={<IconGame className="text-light3" />}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GameSearchModal;
