import { BalanceBadge, BrandLogo } from "@components";
import { BALANCE_BADGE_TOP_ID, HEADER_HEIGHT, Paths } from "@constants";
import { PageHeader as KZPageHeader, PropsWithClassName, cn } from "kz-ui-sdk";
import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

interface PageHeaderProps extends PropsWithClassName {}

const deferredUpdateBalancePaths = [Paths.PRIVATE.CASHBACK, Paths.PRIVATE.REFERRAL];

export default function PageHeader({ className }: PageHeaderProps) {
  const pathname = useLocation().pathname;

  const { delayUpdate } = useMemo(() => {
    // In cashback/refer page, we need to delay the update of balance badge
    if (deferredUpdateBalancePaths.some((path) => matchPath(path, pathname))) {
      return {
        delayUpdate: 1200,
      };
    }
    return {
      delayUpdate: undefined,
    };
  }, [pathname]);

  return (
    <KZPageHeader
      fixed
      className={cn("z-10", className)}
    >
      <div
        className="mx-auto flex max-w-md flex-row items-center justify-between border-b border-white/10 bg-white/5 bg-[radial-gradient(133.74%_100.44%_at_100%_50%,_#231F55_0%,_#151240_100%)] px-6 py-3"
        style={{
          height: HEADER_HEIGHT,
        }}
      >
        <div className="h-8 w-auto">
          <BrandLogo
            size="auto"
            navigateTo={Paths.PRIVATE.HOME}
          />
        </div>

        <BalanceBadge
          id={BALANCE_BADGE_TOP_ID}
          delayUpdate={delayUpdate}
        />
      </div>
    </KZPageHeader>
  );
}
