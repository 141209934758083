import { AppConfiguration, CountryCurrency, CountryEnvKey, CountryPhoneCode, DeployCountry, Language } from "@types";
import { REG_PHONE_10_DIGITS, REG_PHONE_11_DIGITS } from "kz-ui-sdk";

export const appConfig: AppConfiguration = {
  countries: {
    [DeployCountry.TH]: {
      accountId: import.meta.env.VITE_TH_ACCOUNT_ID as string,
      language: Language.TH,
      phoneCode: CountryPhoneCode.TH,
      envKey: CountryEnvKey.TH,
      deployCountry: DeployCountry.TH,
      isoCode: DeployCountry.TH,
      currency: CountryCurrency.TH,
      postback: {
        campaignId: "",
        siteUrl: window.location.origin,
      },
      validators: {
        phone: REG_PHONE_10_DIGITS,
      },
      banks: [
        { code: "KBANK", name: "Kasikorn Bank Plc.", providerCode: "001" },
        { code: "BBL", name: "Bangkok Bank Plc.", providerCode: "003" },
        { code: "KTB", name: "Krung Thai Bank", providerCode: "004" },
        { code: "ABN", name: "ABN Amro Bank N.V.", providerCode: "005" },
        { code: "TTB", name: "TMBThanachart", providerCode: "007" },
        { code: "SCB", name: "Siam Commercial Bank", providerCode: "010" },
        { code: "UOB", name: "UOB Bank Plc.", providerCode: "016" },
        { code: "BAY", name: "Bank of Ayudhya / Krungsri", providerCode: "017" },
        { code: "CIMB", name: "CIMB Thai Bank Public Company Limited", providerCode: "018" },
        { code: "LHBANK", name: "Land and Houses Bank Public Company Limited", providerCode: "020" },
        { code: "GSB", name: "Government Savings Bank", providerCode: "022" },
        { code: "KKP", name: "Kiatnakin Phatra Bank Public Company Limited", providerCode: "023" },
        { code: "CITI", name: "Citibank N.A.", providerCode: "024" },
        { code: "GHB", name: "Government Housing Bank", providerCode: "025" },
        { code: "BAAC", name: "Bank for Agriculture and Agricultural Cooperatives", providerCode: "026" },
        { code: "MHCB", name: "Mizuho Corporate Bank Limited", providerCode: "027" },
        { code: "IBANK", name: "Islamic Bank of Thailand", providerCode: "028" },
        { code: "TISCO", name: "TISCO Bank Plc.", providerCode: "029" },
      ],
    },
    [DeployCountry.PH]: {
      accountId: import.meta.env.VITE_PH_ACCOUNT_ID as string,
      language: Language.EN,
      phoneCode: CountryPhoneCode.PH,
      envKey: CountryEnvKey.PH,
      isoCode: DeployCountry.PH,
      deployCountry: DeployCountry.PH,
      currency: CountryCurrency.PH,
      postback: {
        campaignId: "",
        siteUrl: window.location.origin,
      },
      validators: {
        phone: REG_PHONE_11_DIGITS,
      },
      banks: [],
    },
  },
  server: {
    baseURL: import.meta.env.VITE_API_BASE_URI as string,
  },
} as const;
