import BigNumber from "bignumber.js";
import { clsx } from "clsx";
import { ProgressBar, bnOrZero } from "kz-ui-sdk";
import moment from "moment";
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type DisplayMode = "seconds" | "days" | "hours";

const DAY_IN_SECONDS = 24 * 60 * 60;
const HOUR_IN_SECONDS = 60 * 60;

interface TimerBarProps extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {
  remainingSeconds: number;
  totalSeconds: number;
  onComplete?: () => void;
}

const TimerBar = ({ remainingSeconds, totalSeconds, onComplete, ...props }: TimerBarProps) => {
  const timer = useRef<NodeJS.Timeout>();
  const [currentRemainingSeconds, setCurrentRemainingSeconds] = useState(remainingSeconds);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentRemainingSeconds(remainingSeconds);
  }, [totalSeconds, remainingSeconds]);

  const onTick = useCallback(() => {
    setCurrentRemainingSeconds((prev) => {
      if (prev <= 0) {
        clearInterval(timer.current);
        onComplete?.();
        return 0;
      }
      return prev - 1;
    });
  }, [onComplete]);

  useEffect(() => {
    // sync with machine clock
    const clock = setTimeout(() => {
      timer.current = setInterval(onTick, 1000);
    }, 1000 - new Date().getMilliseconds());
    return () => {
      clearInterval(timer.current);
      clearTimeout(clock);
    };
  }, [onTick, remainingSeconds]);

  const progressPercent = useMemo(() => {
    const passedSeconds = totalSeconds - currentRemainingSeconds;
    const percent = bnOrZero(passedSeconds).div(totalSeconds).dp(2, BigNumber.ROUND_UP);
    return percent.toNumber();
  }, [currentRemainingSeconds, totalSeconds]);

  const displayMode: DisplayMode = useMemo(() => {
    if (currentRemainingSeconds >= DAY_IN_SECONDS) return "days";
    if (currentRemainingSeconds >= HOUR_IN_SECONDS) return "hours";
    return "seconds";
  }, [currentRemainingSeconds]);

  const formattedTime = useMemo(() => {
    if (displayMode === "days") {
      const days = bnOrZero(currentRemainingSeconds).div(DAY_IN_SECONDS).dp(0, BigNumber.ROUND_DOWN).toNumber();
      return `${days} ${t("days")}`;
    }
    if (displayMode === "hours") {
      return moment.utc(currentRemainingSeconds * 1000).format("HH:mm:ss");
    }
    if (displayMode === "seconds") {
      return moment.utc(currentRemainingSeconds * 1000).format("mm:ss");
    }
  }, [currentRemainingSeconds, displayMode, t]);

  return (
    <div
      {...props}
      className={clsx("", props.className)}
    >
      <div className="mb-1 flex w-full items-center">
        <span className="text-xs text-light5">{t("time left cashback")}</span>
        <span className="font-feat-tnum ml-auto text-right text-xs font-bold text-white">{formattedTime}</span>
      </div>
      <div className="">
        <ProgressBar percent={progressPercent} />
      </div>
    </div>
  );
};

export default TimerBar;
