import type { DefaultValues, Resolver, SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

const behaviors = ["field", "toast"] as const;

export default function useRHF<T extends object>(
  defaultValues: T,
  resolver: Resolver<T, unknown>,
  onSubmit: SubmitHandler<T>,
) {
  const { control, handleSubmit, reset, watch, setError, register, setValue, formState, trigger } = useForm({
    defaultValues: defaultValues as DefaultValues<T>,
    resolver,
  });

  return {
    control,
    submit: handleSubmit(onSubmit),
    reset,
    watch,
    register,
    setValue,
    formState,
    trigger,
  };
}
