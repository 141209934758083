import { fork } from "@redux-saga/core/effects";
import auth from "./auth";
import preference from "./preference";

function* mainSaga() {
  yield fork(auth.saga);
  yield fork(preference.saga);
}

export default mainSaga;
