import { STORAGE_KEY } from "@constants";
import { TFunction } from "i18next";
import { APIErrorCodes, ErrorResponse, QueryError } from "kz-ui-sdk";

const getErrorMessage = (lang: string, retryAfter: number | null, t: TFunction): string => {
  if (retryAfter !== null) {
    return t(`Please wait for ${retryAfter} seconds before trying again.`, {
      retryAfter,
    });
  }
  return t("SMS provider is not found");
};

export const resolveRegisterRequestError = (error: QueryError<ErrorResponse>, t: TFunction) => {
  const lang = localStorage.getItem(STORAGE_KEY.LANGUAGE) || "en";
  if (error?.data?.error?.message === "request rate limited.") {
    const retryAfter = +error?.data?.error?.errors?.["rateLimit"] - +error?.data?.error?.errors?.["diff"];
    return getErrorMessage(lang, retryAfter, t);
  }

  // Map register error message
  switch (error?.data?.error?.message) {
    case APIErrorCodes.Auth.AccountSuspended:
    case APIErrorCodes.Auth.AccountClosed:
    case APIErrorCodes.Auth.AccountFrozen:
      return t("Request failed, please contact customer service!");
    default:
      return t(error?.data?.error?.message ?? "An error occurred");
  }
};
