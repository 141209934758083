import { TabNavigation, TabNavigationOption } from "@components";
import { ReferralEvent, ReferralTabEnum } from "@views/ReferralPage/types";
import { cn } from "kz-ui-sdk";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FriendList, IncomeList } from "./components";

interface TabPanelRecordsProps {
  changeTab?: (tab: ReferralTabEnum) => void;
}

enum RecordTab {
  INCOME = "income-list",
  FRIENDS = "friend-list",
}

const TAB_OPTIONS: TabNavigationOption[] = [
  { label: "credited income", value: RecordTab.INCOME },
  { label: "list of friends", value: RecordTab.FRIENDS },
];

const TabPanelRecords = ({ changeTab }: TabPanelRecordsProps) => {
  const [tab, setTab] = useState<TabNavigationOption>();
  const [renderKey, setRenderKey] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Refetch history when a new referral is claimed
    const handleCustomEvent = () => {
      setRenderKey((prev) => prev + 1);
    };
    window.addEventListener(ReferralEvent.CLAIMED, handleCustomEvent);
    return () => {
      window.removeEventListener(ReferralEvent.CLAIMED, handleCustomEvent);
    };
  }, []);

  useEffect(() => {
    const tabEnum = searchParams.get("sub") as RecordTab;
    if (tabEnum) {
      setTab(TAB_OPTIONS.find((option) => option.value === tabEnum) || TAB_OPTIONS[0]);
    } else {
      setTab(TAB_OPTIONS[0]);
    }
  }, [searchParams]);

  const handleOnChangeTab = (tab: RecordTab | TabNavigationOption) => {
    let tabEnum: string;
    if (typeof tab === "string") {
      tabEnum = tab;
    } else {
      tabEnum = tab.value;
    }
    setSearchParams({ tab: ReferralTabEnum.RECORDS, sub: tabEnum });
  };

  return (
    <div>
      <div>
        {!!tab && (
          <TabNavigation
            currentTab={tab}
            onChange={handleOnChangeTab}
            className="!justify-center gap-x-6 border-b-0"
            options={TAB_OPTIONS}
            classes={{
              option: "w-[116px]",
            }}
          />
        )}
      </div>
      {!!tab && (
        <div>
          <div
            className={cn("animate-fade-in", {
              "!h-0 overflow-hidden": tab.value !== RecordTab.INCOME,
            })}
          >
            <IncomeList
              key={`income-list-${renderKey}`}
              backToOverview={() => {
                changeTab?.(ReferralTabEnum.OVERVIEW);
              }}
            />
          </div>
          <div
            className={cn("animate-fade-in", {
              "!h-0 overflow-hidden": tab.value !== RecordTab.FRIENDS,
            })}
          >
            <FriendList
              backToOverview={() => {
                changeTab?.(ReferralTabEnum.OVERVIEW);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TabPanelRecords;
