import { Brand } from "@constants/brands.ts";

export const Paths = {
  PRIVATE: {
    HOME: "/",
    DEPOSIT: "/deposit",
    GAMES: "/games",
    PROFILE: "/profile",
    CONTACT: "/contact",
    CASHBACK: "/cashback",
    CASHBACK_TNC: "/cashback/tnc",
    TRANSACTION: "/transaction",
    BANK_ACCOUNTS: "/bank-accounts",
    BET_HISTORY: "/betting-history",
    LANGUAGE: "/language",
    UPDATE_BANK_FORCE: "/onboard/bank",
    UPDATE_PIN_FORCE: "/reset-pin",
    UPDATE_BANK: "/update-bank",
    UPDATE_PIN: "/update-pin",
    TERMS_CONDITIONS: "/terms-and-conditions",
    DEPOSIT_CALLBACK: "/:provider/:orderRef/return",
    REFERRAL: "/referral",
  },
  PUBLIC: {
    ONBOARD: "/onboard/:mode",
    ONBOARD_LOGIN: "/onboard",
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT_PIN: "/forgot-pin",
    MAINTENANCE: "/maintenance",
    REFERRAL_CALLBACK: "/r/:refCode",
  },
  EXTERNAL: {
    ILV: "https://xo268vip.com/vipstatus",
    SLOTS_HACK_AI: {
      [Brand.XO268.key]: "https://slotshack.ai/th/XO268",
      [Brand["789BK"].key]: "https://slotshack.ai/th/789BK",
    },
  },
};
