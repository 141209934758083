import { siteApi } from "@api";
import { HEADER_HEIGHT, Paths, QUERY_ALL } from "@constants";
import { CATEGORY_POPULAR_SLUG, RECENT_GAME_QUERY_PARAMS } from "@views/GamesPage/contants";
import { GameCategoryModel, GameProviderModel, cn } from "kz-ui-sdk";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { GameCategoryList, GameList, GameProviderList, GameSearchModal, RecentGames } from "./components";
import { standardizeGameStats } from "./utils";

const GamesPage = () => {
  const location = useLocation();

  const [filterByProvider, setFilterByProvider] = useState<GameProviderModel>();
  const [filterByCategory, setFilterByCategory] = useState<GameCategoryModel>();

  //
  const { data } = siteApi.useGetGameRecentListQuery(
    {
      query: RECENT_GAME_QUERY_PARAMS,
    },
    {
      skip: filterByCategory?.slug !== CATEGORY_POPULAR_SLUG,
    },
  );

  const recentGames = useMemo(() => data?.entries ?? [], [data?.entries]);

  //
  const { data: providersResponse } = siteApi.useGetGameProviderListQuery({
    query: QUERY_ALL,
  });
  const { data: categoriesResponse, isFetching: isFetchingGameCategory } = siteApi.useGetGameCategoryListQuery({
    query: QUERY_ALL,
  });
  const { data: providersStatsResponse } = siteApi.useGetGameProviderStatsQuery();

  const defaultCategory = useMemo(() => {
    if (!isFetchingGameCategory) {
      // Find the popular category, if not found, use recent category
      const popularCategory = categoriesResponse?.entries.find((category) => category.slug === CATEGORY_POPULAR_SLUG);
      return popularCategory ?? categoriesResponse?.entries[0];
    }
  }, [categoriesResponse, isFetchingGameCategory]);

  // Set default category if no category is selected
  useEffect(() => {
    if (!filterByCategory && defaultCategory) {
      setFilterByCategory(defaultCategory);
    }
  }, [defaultCategory, filterByCategory]);

  // Remove global scroll bar when on games page
  useLayoutEffect(() => {
    const contentWrapper = document.getElementsByTagName("main")[0] as HTMLDivElement;
    if (location.pathname === Paths.PRIVATE.GAMES && contentWrapper) {
      // set padding bottom to 0 to remove the bottom padding
      contentWrapper.style.overflowY = "hidden";
    }

    return () => {
      if (contentWrapper) {
        contentWrapper.style.overflowY = "auto";
      }
    };
  }, [location.pathname]);

  const handleOnSelectProvider = useCallback((provider?: GameProviderModel) => {
    setFilterByProvider(provider);
  }, []);

  const handleOnSelectCategory = useCallback(
    (category?: GameCategoryModel) => {
      setFilterByCategory(category);

      if (!category) return;
      // If category is popular or recent, set provider to null
      if (CATEGORY_POPULAR_SLUG.includes(category?.slug)) {
        setFilterByProvider(undefined);
      } else {
        if (providersStatsResponse) {
          const standardizedStats = standardizeGameStats(providersStatsResponse);
          const defaultProvider = providersResponse?.entries
            .filter((provider) => !!standardizedStats?.[provider.id]?.[category.id])
            .find((provider) => !provider.isMaintenance);
          // If category is not popular or recent, set provider to default provider
          setFilterByProvider(defaultProvider);
        }
      }
    },
    [providersResponse?.entries, providersStatsResponse],
  );

  const gameProviderSelectorKey = useMemo(() => {
    if (filterByCategory?.slug && !CATEGORY_POPULAR_SLUG.includes(filterByCategory.slug)) {
      return "display-provider-list";
    }
    return "hide-provider-list";
  }, [filterByCategory?.slug]);

  const isDisplayProviders = useMemo(() => {
    return !!filterByCategory?.slug && !CATEGORY_POPULAR_SLUG.includes(filterByCategory?.slug);
  }, [filterByCategory?.slug]);

  return (
    <>
      {/*CATEGORY & SEARCH*/}
      <div className="-mx-5 flex gap-x-1.5 pr-2">
        {/*CATEGORY*/}
        <GameCategoryList onSelect={handleOnSelectCategory} />
        {/*SEARCH*/}
        {!!defaultCategory && <GameSearchModal preloadCategory={defaultCategory} />}
      </div>

      {filterByCategory?.slug === CATEGORY_POPULAR_SLUG && <RecentGames games={recentGames} />}
      {/*GAME GRID*/}
      <div
        className="-mx-5 mt-2 flex flex-grow gap-x-2"
        style={{
          // Header height + category height + padding top + margin top + height of recent games
          height: `calc(100vh - ${HEADER_HEIGHT + 56 + 16 + 8 + (filterByCategory?.slug === CATEGORY_POPULAR_SLUG && !!recentGames.length ? 106 : 0)}px)`,
        }}
      >
        <GameProviderList
          className={cn(
            "max-w-[85px] flex-shrink-0 basis-[85px] overflow-y-auto pb-28 transition-[opacity,max-width,basis]",
            {
              "max-w-[0] !basis-[0px] overflow-x-hidden opacity-0": !isDisplayProviders,
            },
          )}
          onSelect={handleOnSelectProvider}
          selected={filterByProvider}
          categoryId={filterByCategory?.id}
          key={gameProviderSelectorKey}
        />
        {/* <RecentGames /> */}
        <GameList
          filters={{
            category: filterByCategory,
            provider: filterByProvider,
          }}
          className="flex-grow pb-28 pr-2"
        />
      </div>
    </>
  );
};

export default GamesPage;
