import { siteApi } from "@api";
import { HTMLSanitizer, Skeleton } from "kz-ui-sdk";
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./html-styles.css";

interface TabPanelInstructionsProps extends PropsWithChildren {}

const TabPanelInstructions = (props: TabPanelInstructionsProps) => {
  const [instruction, setInstruction] = useState("");
  const { data: infoResponse, isFetching: isFetchingInfo } = siteApi.useGetReferralInfoQuery({});
  const { i18n } = useTranslation();

  useEffect(() => {
    if (infoResponse) {
      setInstruction(infoResponse.referralConfig?.instructions?.[i18n.language] ?? "");
    }
  }, [i18n.language, infoResponse]);

  return (
    <div className="mt-3">
      {!isFetchingInfo && !!instruction && (
        <HTMLSanitizer
          html={instruction}
          className="html-styles animate-fade-in"
        />
      )}

      {isFetchingInfo && (
        <Skeleton
          className="mb-6 !h-36"
          count={4}
        />
      )}
    </div>
  );
};

export default TabPanelInstructions;
