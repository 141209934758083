import { ClaimButton } from "@components";
import { Paths } from "@constants";
import { RewardStatus } from "@types";
import { AnimKeyframe } from "@views/CashbackPage/types";
import { PropsWithClassName, cn, delay } from "kz-ui-sdk";
import React, { PropsWithChildren, forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TimerBar from "../TimerBar";

interface CardBottomSectionProps extends PropsWithChildren, PropsWithClassName {
  onClick?: () => void;
  status?: RewardStatus;
  points?: number;
  timer?: {
    remainingSeconds: number;
    totalSeconds: number;
    onComplete?: () => void;
  };
  loading?: boolean;
  animKeyframe?: AnimKeyframe;
}

export interface CardBottomSectionRef {
  renderFlyingCoins: () => void;
}

const BoxBottomSection = forwardRef<CardBottomSectionRef, CardBottomSectionProps>(
  ({ className, onClick, status, animKeyframe, points, timer, loading }, ref) => {
    const [btnText, setBtnText] = useState("");
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isNoPoints = points === 0;
    const isPreClaimable = animKeyframe === "pre-claimable";
    const isPreLock = animKeyframe === "pre-lock";
    const isLocked = status === RewardStatus.LOCKED;
    const isClaimable = status === RewardStatus.CLAIMABLE && !!points && points > 0;

    useEffect(() => {
      if (isNoPoints) {
        setBtnText("play more cashback");
        return;
      }
      switch (status) {
        case RewardStatus.CLAIMED:
          delay(4500).then(() => {
            setBtnText("received cashback");
          });
          return;
        case RewardStatus.CLAIMABLE:
          setBtnText("claim cashback");
          return;
        case RewardStatus.LOCKED:
        default:
          return;
      }
    }, [isNoPoints, points, status]);

    const handleOnNoPointsClick = (e: React.MouseEvent) => {
      if (isNoPoints) {
        e.preventDefault();
        e.stopPropagation();
        delay().then(() => navigate(Paths.PRIVATE.GAMES));
      }
    };

    return (
      <div className={cn("bottom-0 h-[87px] bg-contain px-4", className)}>
        <img
          src="/images/cashback/bg-reward-card-bottom.svg"
          alt=""
          className="absolute left-0 top-0 h-full w-full rounded-b-md object-cover object-top"
        />
        {/*<div className="absolute bottom-0 left-0 h-10 w-full bg-[#120e41]" />*/}
        <div
          className={cn("absolute bottom-[-60px] left-4 w-[calc(100%-2rem)]", {
            // display timer when claimable
            "!bottom-[16px] transition-[bottom] !duration-[2s]": isPreLock,
            "!bottom-[16px]": isLocked && !isNoPoints,
            // hide timer when claimable
            "transition-[bottom] !duration-[2s]": isPreClaimable,
          })}
        >
          {timer && (
            <TimerBar
              remainingSeconds={timer.remainingSeconds}
              totalSeconds={timer.totalSeconds}
              onComplete={timer.onComplete}
            />
          )}
        </div>
        <div
          className={cn("absolute bottom-4 left-4 w-[calc(100%-2rem)] active:opacity-80", {
            // hide button when no points
            "bottom-[-60px] transition-[bottom] !duration-[2s]": isPreLock,
            "bottom-[-60px]": isLocked && !isNoPoints,
            // show button when claimable
            "transition-[bottom] !duration-[2s]": isPreClaimable,
          })}
          role={isNoPoints ? "button" : undefined}
          onClick={handleOnNoPointsClick}
        >
          <ClaimButton
            onClick={onClick}
            withCoin={isClaimable}
            withShine={isClaimable}
            claimable={isClaimable}
            text={t(btnText)}
            loading={loading}
            classes={{
              text: isNoPoints ? "text-sm !text-white" : "text-base ",
            }}
            ref={ref}
            withFlyingCoins
          />
        </div>
      </div>
    );
  },
);

BoxBottomSection.displayName = "CardBottomSection";

export default BoxBottomSection;
