import { siteApi } from "@api";
import { IconMSIClose, IconMSISchedule } from "@assets/icons";
import { PointLabel, PointLabelRef } from "@components";
import { ONE_DAY_IN_SECONDS } from "@constants";
import { useGetRewardsInfo, useProfileBalance, useServerInfo, useTypedSelector } from "@hooks";
import { Language, RewardStatus } from "@types";
import { RewardButton } from "@views/HomePage/components";
import { Button, ButtonIcon, Dialog, PageTitle, WalletTxMethod, cn, delay, useHandleApiResponse } from "kz-ui-sdk";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function DailyRewardDialog() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });
  const countingDownRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [remainingSeconds, setRemainingSeconds] = useState(ONE_DAY_IN_SECONDS);
  const [status, setStatus] = useState<RewardStatus>(RewardStatus.LOCKED);
  const { data, refetch } = useGetRewardsInfo();
  const [claimReward, result] = siteApi.useClaimRewardMutation();
  const serverInfo = useServerInfo();
  const { refetch: refetchBalance } = useProfileBalance();
  const stopCountdown = useCallback(() => {
    clearInterval(countingDownRef.current);
  }, []);
  const pointLabelRef = useRef<PointLabelRef>(null);
  const [claimedPoints, setClaimedPoints] = useState(0);
  const isDisplayAppInstaller = useTypedSelector((state) => state.preferences.displayAppInstaller);

  const onSecondTick = useCallback(() => {
    setRemainingSeconds((prev) => {
      if (prev === 1) {
        stopCountdown();
        setStatus(RewardStatus.CLAIMABLE);
      }
      return prev - 1;
    });
  }, [stopCountdown]);

  const startCountdown = useCallback(() => {
    const now = new Date();
    const delay = 1000 - now.getMilliseconds();
    // Sync with the machine clock
    setTimeout(() => {
      stopCountdown();
      countingDownRef.current = setInterval(onSecondTick, 1000);
    }, delay);
  }, [onSecondTick, stopCountdown]);

  useEffect(() => {
    if (data?.[WalletTxMethod.DailyBonus] && serverInfo?.utcOffset) {
      const remainingSecs = data[WalletTxMethod.DailyBonus].remainingSeconds;
      setRemainingSeconds(remainingSecs);
      if (remainingSecs > 0) {
        setStatus(RewardStatus.LOCKED);
        startCountdown();
      } else {
        setStatus(RewardStatus.CLAIMABLE);
        stopCountdown();
      }
    }
  }, [data, serverInfo?.utcOffset, startCountdown, stopCountdown]);

  useEffect(() => {
    // Cleanup function to clear the intervals
    return () => {
      clearInterval(countingDownRef.current);
    };
  }, []);

  const handleClaim = useCallback(async () => {
    const result = await claimReward({ query: { type: WalletTxMethod.DailyBonus } });

    handleApiResponse(result, {
      errorMessageMap: {
        NotConfigured: "Not configured",
      },
    });

    if (result.data) {
      setClaimedPoints(result.data.model.netAmount.toNumber());
      refetch();
      delay(500).then(pointLabelRef.current?.count);
      delay(2000).then(refetchBalance);
    }
  }, [claimReward, handleApiResponse, refetch, refetchBalance]);

  return (
    <>
      <RewardButton
        claimable={status === RewardStatus.CLAIMABLE}
        rewardIcon={
          <img
            src="/images/icon-home-daily.png"
            alt="daily reward"
            className="h-full w-auto object-contain"
          />
        }
        onClick={() => setOpen(true)}
        locked={status === RewardStatus.LOCKED}
        lockedIcon={
          <div className="flex items-center gap-x-1">
            <div className="font-feat-tnum text-[10px]">{moment.utc(remainingSeconds * 1000).format("HH:mm:ss")}</div>
            <IconMSISchedule className="h-4 w-4" />
          </div>
        }
      >
        {t("Daily Login")}
      </RewardButton>
      <Dialog
        classes={{
          "container&": cn("h-[340px] ", {
            "delay-[300ms] transition-all duration-1000 ease-out h-[280px]": result.data !== undefined && open,
          }),
          "backDrop&": cn("mt-[50px]", {
            "mt-[88px]": isDisplayAppInstaller,
          }),
        }}
        transition
        open={open}
        onClose={() => setOpen(false)}
        title={
          <div className="m-[-10px] flex flex-row">
            <div className="h-10 w-10"></div>
            <div className="mt-4 grow text-center">
              <PageTitle
                label={t("Daily Login")}
                variant="gradient"
                className="-mt-2"
                classes={{
                  "label&": "uppercase whitespace-nowrap",
                  "labelOverlay&": "uppercase whitespace-nowrap",
                  "star&": cn({
                    "translate-y-[-36%] translate-x-[-42%]": i18n.language === Language.EN,
                    "translate-y-[-28%] translate-x-[-46%]": i18n.language === Language.TH,
                  }),
                }}
              />

              <div className="content-base-secondary text-sm">{t("Log in daily for free credits!")}</div>
            </div>
            <ButtonIcon
              variant="ghost"
              size="md"
              onClick={() => setOpen(false)}
              icon={<IconMSIClose className="content-base" />}
            />
          </div>
        }
      >
        <div className="relative">
          <div
            className={cn(
              "relative m-auto mt-6 h-[180px] w-[280px] bg-[url(/images/bg-glow.png)] bg-contain bg-center bg-no-repeat transition-opacity delay-[800ms] duration-1000 ease-out",
              {
                "opacity-30": result.data !== undefined,
              },
            )}
          >
            <div
              className={cn(
                "absolute bottom-[35px] left-1/2 h-[120px] w-[247px] -translate-x-1/2 bg-[url(/images/bg-closed-chest.png)] bg-contain bg-center bg-no-repeat transition-opacity duration-300 ease-out",
                {
                  "opacity-0": result.data !== undefined,
                },
              )}
            ></div>
            <div
              className={cn(
                "absolute bottom-[35px] left-1/2 h-[140px] w-[247px] -translate-x-1/2 bg-[url(/images/bg-opened-chest.png)] bg-cover bg-center bg-no-repeat opacity-0 transition duration-[800ms] ease-out",
                {
                  "-translate-y-3 opacity-100": result.data !== undefined,
                },
              )}
            ></div>
          </div>
          <div
            className={cn(
              "absolute left-1/2 top-[40%] z-10 -translate-x-1/2 -translate-y-1/2 text-center font-semibold text-light1 opacity-0 transition-opacity duration-75",
              {
                "opacity-100": result.data !== undefined,
              },
            )}
          >
            <div className="flex flex-col items-center align-middle">
              <div className="flex items-center">
                <div className="text-gradient-light-4 min-w-max text-[40px]">+</div>
                <PointLabel
                  endVal={claimedPoints}
                  startVal={0}
                  ref={pointLabelRef}
                  type="up"
                  className="text-gradient-light-4 min-w-max text-[50px]"
                  decimal={2}
                />
              </div>
              <div className="text-gradient-light-3 min-w-max text-sm leading-3">{t("Free Credits Added!")}</div>
            </div>
          </div>
        </div>
        <div
          className={cn("duration-800 w-full px-7 transition-all delay-[300ms] ease-out", {
            "pointer-events-none z-0 opacity-0": result.data !== undefined,
          })}
        >
          <Button
            size="md"
            className="-mt-4"
            onClick={handleClaim}
            loading={result.isLoading}
          >
            {t("Claim Now")}
          </Button>
        </div>
      </Dialog>
    </>
  );
}
