import { PropsWithChildren } from "react";
import { ClaimSection, ShareLinkSection, StatsSection } from "./components";

interface TabPanelOverviewProps extends PropsWithChildren {}

const TabPanelOverview = (props: TabPanelOverviewProps) => {
  return (
    <div className="">
      {/*HERO  */}
      <div className="-mx-5 bg-[radial-gradient(73.33%_38.02%_at_73.33%_58.07%,_#371b9799_0%,_rgba(0,_0,_0,_0.00)_100%)] px-5">
        <ClaimSection />
      </div>

      <div className="mb-[-36px] translate-y-[-36px]">
        {/*SHARE LINK*/}
        <ShareLinkSection className="mb-3" />

        {/*STATS*/}
        <StatsSection className="pt-3" />
      </div>
    </div>
  );
};

export default TabPanelOverview;
