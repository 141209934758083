import { authApi, siteApi } from "@api";
import { BankSelect, RHFController } from "@components/inputs";
import { zodResolver } from "@hookform/resolvers/zod";
import useRHF from "@hooks/useRHF.ts";
import { AppDispatch } from "@stores";
import authSlice from "@stores/auth/slices.ts";
import { BankAccount } from "@types";
import {
  AddBankAccountRequest,
  AddBankAccountResponse,
  Button,
  PropsWithClassName,
  TextField,
  cn,
  useHandleApiResponse,
} from "kz-ui-sdk";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { z as zod } from "zod";

interface UpdateBankAccountFormProps extends PropsWithClassName {
  onComplete?: (response?: AddBankAccountResponse) => void;
  defaultBank: BankAccount | null | undefined;
}

const schema = zod.object({
  accountNo: zod
    .string({
      required_error: "Account number is required",
    })
    .min(1, "Account number is required")
    .regex(/^\d+$/, "account number must be digits only"),
  firstname: zod
    .string({
      required_error: "First name is required",
    })
    .min(1, "First name is required"),
  lastname: zod
    .string({
      required_error: "Last name is required",
    })
    .min(1, "Last name is required"),
  bankKey: zod
    .string({
      required_error: "Bank name is required",
    })
    .min(1, "Bank name is required"),
});

type Values = zod.infer<typeof schema>;

const defaultValue = { accountNo: "", firstname: "", lastname: "", bankKey: "" } satisfies Values;

const FormUpdateBankAccount = ({ onComplete, className, defaultBank }: UpdateBankAccountFormProps) => {
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });
  const { t } = useTranslation();
  const [addBankAcc, { isLoading }] = siteApi.useAddBankAccountMutation();
  const { data } = authApi.useGetProfileQuery();
  const dispatch = useDispatch<AppDispatch>();

  const bankInfo = useMemo(() => {
    return data?.bankInfos;
  }, [data?.bankInfos]);

  const onSubmit = useCallback(
    async (v: Values) => {
      const requestBankInfo: AddBankAccountRequest = {
        firstname: v.firstname.trim(),
        lastname: v.lastname.trim(),
        bankName: bankInfo?.[v.bankKey]?.name ?? "",
        accountNo: v.accountNo.trim(),
        bankKey: v.bankKey,
      };

      const result = await addBankAcc(requestBankInfo);
      handleApiResponse(result, {
        successMessage: t("Updated bank account successfully!"),
        onSuccess: async () => {
          // refresh self
          const result = await dispatch(
            authApi.endpoints.getProfile.initiate(undefined, {
              forceRefetch: true,
            }),
          );
          if (result.data) {
            dispatch(authSlice.actions.updateProfile(result.data));
          }
          onComplete?.(result.data);
        },
      });
    },
    [addBankAcc, bankInfo, dispatch, handleApiResponse, onComplete, t],
  );

  const { control, submit, setValue } = useRHF(defaultValue, zodResolver(schema), onSubmit);

  return (
    <div className={cn("flex flex-col gap-6", className)}>
      <RHFController
        control={control}
        name="bankKey"
      >
        <BankSelect disabled={isLoading} />
      </RHFController>

      <label className="flex gap-2">
        <RHFController
          control={control}
          name="firstname"
          onBlur={(v) => {
            setValue("firstname", v.trim());
          }}
        >
          <TextField
            classes={{
              "input&": "w-full truncate",
            }}
            placeholder={t("First name")}
            disabled={isLoading}
            size={"lg"}
          />
        </RHFController>
        <RHFController
          control={control}
          name="lastname"
          onBlur={(v) => {
            setValue("lastname", v.trim());
          }}
        >
          <TextField
            classes={{
              "input&": "w-full truncate",
            }}
            placeholder={t("Last name")}
            disabled={isLoading}
            size={"lg"}
          />
        </RHFController>
      </label>
      <label className="flex flex-col gap-2">
        <RHFController
          control={control}
          name="accountNo"
          onBlur={(v) => {
            setValue("accountNo", v.trim());
          }}
        >
          <TextField
            classes={{
              "input&": "w-full truncate",
            }}
            placeholder={t("Account number")}
            disabled={isLoading}
            size={"lg"}
          />
        </RHFController>
      </label>
      <Button
        onClick={submit}
        loading={isLoading}
        size={"lg"}
      >
        {defaultBank ? t("Update") : t("Finish & login")}
      </Button>
    </div>
  );
};

export default FormUpdateBankAccount;
