import { SVGProps } from "react";

interface IconPentagonUpProps extends SVGProps<SVGSVGElement> {}

const IconPentagonUp = (props: IconPentagonUpProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      {...props}
    >
      <path
        d="M14.3855 4.22543C16.8389 2.44292 20.1611 2.44292 22.6145 4.22543L30.8045 10.1758C33.2579 11.9583 34.2845 15.1179 33.3474 18.002L30.2191 27.6299C29.282 30.5141 26.5943 32.4668 23.5617 32.4668H13.4383C10.4057 32.4668 7.71804 30.5141 6.78092 27.6299L3.65262 18.002C2.7155 15.1179 3.74211 11.9583 6.19552 10.1758L14.3855 4.22543Z"
        stroke="url(#paint0_linear_131_2073)"
        strokeWidth="2"
      />
      <path
        d="M18.3333 11L17.378 10.0447L18.3333 9.08935L19.2887 10.0447L18.3333 11ZM19.6844 27C19.6844 27.7462 19.0795 28.351 18.3333 28.351C17.5872 28.351 16.9823 27.7462 16.9823 27L19.6844 27ZM12.0447 15.378L17.378 10.0447L19.2887 11.9553L13.9553 17.2887L12.0447 15.378ZM19.2887 10.0447L24.622 15.378L22.7113 17.2887L17.378 11.9553L19.2887 10.0447ZM19.6844 11L19.6844 27L16.9823 27L16.9823 11L19.6844 11Z"
        fill="url(#paint1_linear_131_2073)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_131_2073"
          x1="18"
          y1="-0.5"
          x2="18"
          y2="34"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="#F3FAFF"
            stopOpacity="0.25"
          />
          <stop
            offset="1"
            stopColor="#B8DBFB"
            stopOpacity="0.85"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_131_2073"
          x1="18"
          y1="27.5"
          x2="18"
          y2="9.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B8E3FB" />
          <stop
            offset="1"
            stopColor="#F3FCFF"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default IconPentagonUp;
