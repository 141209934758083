import React, { useCallback, useState } from "react";

type ScrollPositionOptions = {
  scrollOffset?: number;
  defaultAtTop?: boolean;
  defaultAtBottom?: boolean;
  defaultAtLeft?: boolean;
  defaultAtRight?: boolean;
  direction?: "vertical" | "horizontal";
};

/**
 * Hook to detect the scroll position of a container
 * @param containerRef
 * @param options
 * @returns isScrollbarAtBottom, isScrollbarAtTop, checkPosition()
 */
export const useScrollPositionDetector = <TRefObject extends HTMLElement>(
  containerRef: React.RefObject<TRefObject>,
  options?: ScrollPositionOptions,
) => {
  const {
    scrollOffset = 2,
    defaultAtTop = true,
    defaultAtBottom = true,
    defaultAtLeft = true,
    defaultAtRight = true,
    direction = "vertical",
  } = options ?? {};

  const [isScrollbarAtBottom, setIsScrollbarAtBottom] = useState(defaultAtBottom);
  const [isScrollbarAtTop, setIsScrollbarAtTop] = useState(defaultAtTop);
  const [isScrollbarAtLeft, setIsScrollbarAtLeft] = useState(defaultAtLeft);
  const [isScrollbarAtRight, setIsScrollbarAtRight] = useState(defaultAtRight);

  const checkPosition = useCallback(() => {
    if (containerRef.current) {
      // Vertical scroll
      if (direction === "vertical") {
        const element = containerRef.current;
        const isBottom = element.scrollHeight - element.clientHeight - element.scrollTop <= scrollOffset;
        const isTop = element.scrollTop <= scrollOffset;
        setIsScrollbarAtBottom(isBottom);
        setIsScrollbarAtTop(isTop);
      }

      // Horizontal scroll
      if (direction === "horizontal") {
        const element = containerRef.current;
        const isRight = element.scrollWidth - element.clientWidth - element.scrollLeft <= scrollOffset;
        const isLeft = element.scrollLeft <= scrollOffset;
        setIsScrollbarAtRight(isRight);
        setIsScrollbarAtLeft(isLeft);
      }
    } else {
      setIsScrollbarAtTop(true);
      setIsScrollbarAtLeft(true);
    }
  }, [containerRef, direction, scrollOffset]);

  return { isScrollbarAtBottom, isScrollbarAtTop, checkPosition, isScrollbarAtLeft, isScrollbarAtRight };
};
