import { SVGProps } from "react";

interface IconNavDepositProps extends SVGProps<SVGSVGElement> {
  active?: boolean;
}

const IconNavDeposit = (props: IconNavDepositProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="44"
      viewBox="0 0 49 44"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_d_8227_2197)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.343 16H19.743C17.983 16 16.543 17.2 16.543 18.6667C16.543 19.8 17.343 20.7333 18.543 21.1333V18.6667C18.543 18.4 18.703 18.1333 18.863 17.9333C19.023 17.7333 19.423 17.6667 19.743 17.6667H29.343C29.663 17.6667 29.983 17.8 30.223 17.9333C30.463 18.0667 30.543 18.4 30.543 18.6667V21.1333C31.743 20.7333 32.543 19.8 32.543 18.6667C32.543 17.2 31.103 16 29.343 16ZM29.343 26.6667V18.6667H19.743V26.6667C19.743 27.4 20.463 28 21.343 28H27.743C28.623 28 29.343 27.4 29.343 26.6667ZM23.903 22.2667L23.343 22.7333C23.103 22.9333 22.703 22.9333 22.463 22.7333C22.223 22.5333 22.223 22.2 22.463 22L23.503 21.1333C24.063 20.6667 24.943 20.6667 25.503 21.1333L26.543 22C26.783 22.2 26.783 22.5333 26.543 22.7333C26.303 22.9333 25.903 22.9333 25.663 22.7333L25.103 22.2667V25.0667C25.103 25.3333 24.863 25.6 24.463 25.6C24.063 25.6 23.823 25.4 23.823 25.0667V22.2667H23.903Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8227_2197"
          x="-0.457031"
          y="-3"
          width="50"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.796078 0 0 0 0 0.894118 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8227_2197"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8227_2197"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default IconNavDeposit;
