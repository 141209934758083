import { authApi, publicApi } from "@api";
import { FormTitle } from "@components";
import { deployEnvConfig } from "@configs";
import { STORAGE_KEY } from "@constants";
import { Paths } from "@constants/paths";
import auth from "@stores/auth";
import { resolveRegisterRequestError } from "@views/LoginPage/utils";
import {
  APIErrorCodes,
  Button,
  CodeField,
  standardizePhoneNumber,
  useDateAvailCheck,
  useHandleApiResponse,
} from "kz-ui-sdk";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface FormPINProps extends PropsWithChildren {
  phone: string;
}

const PIN_LENGTH = 4;
const LOGIN_FAILED_LIMIT = 2;

const FormPIN = ({ phone }: FormPINProps) => {
  const navigate = useNavigate();
  const [PINValue, setPINValue] = useState("");
  const dispatch = useDispatch();

  const [login, { isLoading: isSubmittingLogin, isSuccess: isLoginSuccess }] = authApi.useLoginMutation();
  const { handleApiResponse } = useHandleApiResponse({
    toast,
    navigate,
  });

  const [loginFailedCount, setLoginFailedCount] = useState(LOGIN_FAILED_LIMIT);
  const [createRegisterMutation, { isLoading: isCreateRegisterLoading }] = publicApi.useCreateRegisterMutation();
  const { t } = useTranslation();
  const [errorMsgPIN, setErrorMsgPIN] = useState<string>();

  const { check: checkRequestOTP } = useDateAvailCheck(
    standardizePhoneNumber(localStorage.getItem(STORAGE_KEY.LOGIN_PHONE) ?? "", deployEnvConfig.country.phoneCode),
  );

  const doForgotPIN = useCallback(async () => {
    toast.dismiss();
    let allowRedirect = true;
    if (checkRequestOTP()) {
      const response = await createRegisterMutation({
        phone: standardizePhoneNumber(phone, deployEnvConfig.country.phoneCode),
      });
      handleApiResponse(response, {
        toastError: false,
        onError: (error) => {
          const message = resolveRegisterRequestError(error, t);
          if (message) {
            toast.error(message);
          }
        },
      });
      if (response.error) {
        allowRedirect = false;
      }
    }
    if (allowRedirect) {
      navigate(Paths.PUBLIC.FORGOT_PIN, {
        state: {
          phone,
        },
      });
    }
  }, [checkRequestOTP, createRegisterMutation, handleApiResponse, navigate, phone, t]);

  const doLogin = useCallback(
    async (pin: string) => {
      setErrorMsgPIN("");
      const response = await login({
        client_id: standardizePhoneNumber(phone, deployEnvConfig.country.phoneCode),
        client_secret: pin,
        grant_type: "password",
      });

      handleApiResponse(response, {
        successMessage: t("Login successfully!"),
        toastError: false,
        onError: (error) => {
          setPINValue("");
          let errMsg = "";
          let deductLoginCounter = true;
          switch (error?.data?.error?.message) {
            case "unauthorized":
              errMsg = t("Incorrect PIN. Please try again.");
              break;
            case APIErrorCodes.Auth.AccountSuspended:
            case APIErrorCodes.Auth.AccountClosed:
            case APIErrorCodes.Auth.AccountFrozen:
              toast.error(t("Sign in failed, please contact customer service!"));
              deductLoginCounter = false;
              break;
            default:
              errMsg = t(error?.data?.error?.message ?? "");
              break;
          }
          setErrorMsgPIN(errMsg);
          if (loginFailedCount <= 1) {
            doForgotPIN();
          }
          if (deductLoginCounter) {
            setLoginFailedCount((prev) => prev - 1);
          }
        },
        onSuccess: () => {
          if (!response.data) return;
          dispatch(auth.slice.actions.updateAccessToken(response.data));
        },
      });
    },
    [login, phone, handleApiResponse, t, loginFailedCount, doForgotPIN, dispatch],
  );

  const handleOnPINComplete = useCallback(
    (code: string) => {
      if (code.length < PIN_LENGTH) {
        setErrorMsgPIN(t("PIN length is invalid"));
      } else {
        doLogin(code).then(() => {});
      }
    },
    [doLogin, t],
  );

  const isFormLoading = useMemo(() => {
    return isSubmittingLogin || isLoginSuccess || isCreateRegisterLoading;
  }, [isSubmittingLogin, isLoginSuccess, isCreateRegisterLoading]);

  return (
    <div className="flex flex-col items-center justify-between px-5">
      <FormTitle
        phone={phone}
        subTitle={t("Enter your PIN to Log in")}
      />
      <div className="mt-6">
        <CodeField
          key={errorMsgPIN}
          length={PIN_LENGTH}
          type={"password"}
          shouldAutoFocus
          disabled={isFormLoading}
          value={PINValue}
          onComplete={handleOnPINComplete}
          error={!!errorMsgPIN}
          helperText={errorMsgPIN}
          helperTextClasses={{
            className: "!text-center",
          }}
          className="flex flex-col items-center"
        />
      </div>

      <div className="mt-6 flex w-full flex-col items-center">
        <Button
          id="btn-next"
          variant="primary"
          size="lg"
          onClick={() => handleOnPINComplete(PINValue)}
          loading={isFormLoading}
        >
          {t("Login")}
        </Button>
        <Button
          size="sm"
          className="mt-6 !w-fit px-3"
          variant="secondary"
          onClick={doForgotPIN}
          disabled={isFormLoading}
        >
          {t("Forgot PIN")}
        </Button>
      </div>
    </div>
  );
};

export default FormPIN;
