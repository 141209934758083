import { RootState } from "@stores";
import { isTokenExpired, logger } from "@utils";
import { getWsUrl } from "@utils/websocket";
import { AuthToken, WSEventResponse } from "kz-ui-sdk";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";

export default function useAppWS() {
  const auth = useSelector<RootState, AuthToken | null | undefined>((state) => state?.auth?.oauth);

  const token: string | null = useMemo(() => {
    if (auth?.access_token) {
      // Check if token is expired
      if (isTokenExpired(auth.access_token)) {
        logger._console.log("WS token expired");
        return null;
      }
      return auth.access_token;
    }
    return null;
  }, [auth?.access_token]);

  return useWebSocket<WSEventResponse>(token ? getWsUrl(token) : null, {
    share: true,
    // shouldReconnect: (e) => {
    //   try {
    //     // @ts-ignore
    //     const wsUrl = e.currentTarget?.url;
    //     if (!wsUrl) return false;
    //
    //     const url = new URL(wsUrl);
    //     const token = url.searchParams.get("token");
    //
    //     // Check if token is available and not expired
    //     if (!!token && !isTokenExpired(token)) {
    //       return true;
    //     }
    //     logger._console.log("WS token expired");
    //     return false;
    //   } catch (e) {
    //     logger._console.error(e);
    //     return false;
    //   }
    // },
    // reconnectAttempts: 5,
    // reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });
}
