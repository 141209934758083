import useDepositNotification from "@hooks/useDepositNotification";
import { Outlet } from "react-router-dom";

/**
 * Websocket route layout
 * Which is used to connect to websocket
 */
const WSRouteLayout = () => {
  useDepositNotification();
  return <Outlet />;
};

export default WSRouteLayout;
