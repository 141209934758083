import { publicApi } from "@api";
import { deployEnvConfig } from "@configs";
import { fork, put } from "@redux-saga/core/effects";
import preferenceSlice from "@stores/preference/slices.ts";
import { execQuery, logger } from "@utils";

function* loadServerInfo() {
  return yield* execQuery(
    publicApi.endpoints.getInfo.initiate({
      query: {
        account: deployEnvConfig.country.accountId,
      },
    }),
  );
}

function* init() {
  const publicInfo = yield* loadServerInfo();
  logger._console.log("server public info", publicInfo);
  yield put(preferenceSlice.actions.setServerInfo(publicInfo));
}

export function* preferenceSaga() {
  yield fork(init);
}

export default preferenceSaga;
