import { authApi, siteApi } from "@api";
import {
  IconBank,
  IconCaretDoubleDown,
  IconCopyGradient,
  IconErrorHourGlass,
  IconMSIArrowBack,
  IconSpinnerBars,
} from "@assets";
import {
  DepositMethod,
  NativeDepositStatus,
  NativeUploadSlipStatus,
  NativeUploadSlipStep,
} from "@components/utils/DepositDrawer/types";
import {
  nativeDepositBack,
  nativeDepositCancel,
  nativeDepositClose,
  nativeDepositTimeout,
} from "@components/utils/DepositDrawer/utils";
import { useAuthSession } from "@hooks";
import useAppWS from "@hooks/useAppWS.ts";
import { formatTimer } from "@utils";
import {
  BankTransferDepositInfoResult,
  Button,
  ButtonIcon,
  DrawerTitle,
  FundingStatus,
  FundingTxModel,
  WSEventType,
  cn,
  fAmount,
  useHandleApiResponse,
} from "kz-ui-sdk";
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { MdBackup, MdClose, MdInfo, MdOutlineCancel, MdOutlineCheckCircle, MdWarning } from "react-icons/md";

interface NativeBankTransferProps {
  fundingTx: FundingTxModel;
  onNativeStatusChange?: (status: NativeDepositStatus) => void;
  requireSlip?: boolean;
  method?: DepositMethod;
}

const DEPOSIT_TIMEOUT = 10 * 60 - 1; //9:59 minutes
const SLIP_MAX_SIZE = 5; // 5MB

type BankTransferDepositInfo = BankTransferDepositInfoResult & {
  amount: number;
};

/**
 * Native bank transfer screen
 * @description This component is used to display the native bank transfer mode in the deposit drawer
 * There are 2 types of native bank transfer:
 * - Without slip upload (requireSlip = false): Users will input the amount in previous step and confirm the transfer
 * - With slip upload (requireSlip = true): Users will skip the amount input and go straight to native screen with 2 steps
 */
const NativeBankTransfer = ({ fundingTx, method, onNativeStatusChange, requireSlip }: NativeBankTransferProps) => {
  const [paymentStatus, setPaymentStatus] = useState<NativeDepositStatus>(NativeDepositStatus.INIT);
  const [uploadSlipStatus, setUploadSlipStatus] = useState<NativeUploadSlipStatus>(NativeUploadSlipStatus.INIT);
  const [uploadSlipStep, setUploadSlipStep] = useState<NativeUploadSlipStep>(NativeUploadSlipStep.INIT);
  const [previewSlipImg, setPreviewSlipImg] = useState<string>();

  const { data: profileRes } = authApi.useGetProfileQuery();
  const [uploadProof] = siteApi.useUploadProofMutation();
  const { bankAccount } = useAuthSession();

  const [remainingSeconds, setRemainingSeconds] = useState(DEPOSIT_TIMEOUT);
  const refTimer = useRef<NodeJS.Timeout>();

  const { lastJsonMessage } = useAppWS();

  const { t } = useTranslation();
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });

  const payData: BankTransferDepositInfo = useMemo(() => {
    return fundingTx.payData as BankTransferDepositInfo;
  }, [fundingTx.payData]);

  const targetBankInfo = useMemo(() => {
    let icon, name;
    if (payData.bankCode && profileRes) {
      icon = profileRes.bankInfos?.[payData.bankCode]?.icon;
      name = profileRes.bankInfos?.[payData.bankCode]?.name;
    }

    return {
      icon,
      bankAccountName: payData.bankAccountName,
      bankAccountNo: payData.bankAccountNo,
      bankName: name ?? payData.bankName,
    };
  }, [payData.bankAccountName, payData.bankAccountNo, payData.bankCode, payData.bankName, profileRes]);

  // Update success status
  useEffect(() => {
    if (lastJsonMessage) {
      try {
        if (lastJsonMessage.type === "event") {
          switch (lastJsonMessage.payload.event) {
            case WSEventType.DEPOSIT_SUCCESS:
              setUploadSlipStatus(NativeUploadSlipStatus.SUCCESS);
              break;
            default:
              break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [lastJsonMessage, t]);

  const currency = useMemo(() => {
    return fundingTx.netCurrency ?? fundingTx.reqCurrency;
  }, [fundingTx.netCurrency, fundingTx.reqCurrency]);

  const handleTimedOut = useCallback(() => {
    clearInterval(refTimer.current);
    setPaymentStatus(NativeDepositStatus.TIMEOUT);
    nativeDepositTimeout();
  }, []);

  // Start timer
  useEffect(() => {
    refTimer.current = setInterval(() => {
      setRemainingSeconds((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(refTimer.current);
  }, [handleTimedOut, t]);

  // Trigger timeout
  useEffect(() => {
    if (remainingSeconds <= 0) {
      handleTimedOut();
    }
  }, [handleTimedOut, remainingSeconds]);

  // Handle status change
  useEffect(() => {
    onNativeStatusChange?.(paymentStatus);
  }, [onNativeStatusChange, paymentStatus]);

  const handleCopyAccount = async () => {
    try {
      await navigator.clipboard.writeText(payData.bankAccountNo);
      toast.success(t("bank account copied"));
    } catch (e) {
      toast.error(t("failed to copy account"));
    }
  };

  const handleConfirmTransfer = () => {
    // If it requires slip, go to upload slip step
    if (requireSlip) {
      setUploadSlipStep(NativeUploadSlipStep.UPLOADING);
    }
    setPaymentStatus(NativeDepositStatus.PENDING);
  };

  const handleOnBack = useCallback(() => {
    // if in step 2, back to step 1
    if (uploadSlipStep === NativeUploadSlipStep.UPLOADING) {
      setUploadSlipStep(NativeUploadSlipStep.INIT);
      setPaymentStatus(NativeDepositStatus.INIT);
      return;
    }
    // if in step 1, back to method selection
    nativeDepositBack();
  }, [uploadSlipStep]);

  const handleOnCancel = useCallback(() => {
    if (requireSlip) {
      if (uploadSlipStatus === NativeUploadSlipStatus.INIT) {
        nativeDepositCancel();
      } else {
        nativeDepositClose();
      }
    } else {
      nativeDepositCancel();
    }
  }, [requireSlip, uploadSlipStatus]);

  const drawerTitle = useMemo(() => {
    if (!requireSlip) {
      return t("Deposit");
    } else {
      return (
        <div className="flex items-center justify-center">
          <span
            className={cn(
              "flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-[#562EF0] text-sm font-bold",
            )}
          >
            1
          </span>
          <div className="h-[2px] w-4 flex-shrink-0 bg-[#FFFFFF29]" />
          <span
            className={cn(
              "flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 text-sm font-bold transition-colors",
              {
                "border-transparent bg-[#562EF0]": uploadSlipStep === NativeUploadSlipStep.UPLOADING,
                "border-[#FFFFFF29] bg-transparent": uploadSlipStep === NativeUploadSlipStep.INIT,
              },
            )}
          >
            2
          </span>
        </div>
      );
    }
  }, [requireSlip, t, uploadSlipStep]);

  const requiredInputAmount = useMemo(() => {
    return !requireSlip;
  }, [requireSlip]);

  const handleUploadSlip = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) {
        return;
      }

      // Set status to uploading
      setUploadSlipStatus(NativeUploadSlipStatus.UPLOADING);

      // Validate file size
      if (file.size > SLIP_MAX_SIZE * 1024 * 1024) {
        toast.error(t("image size is too large"));
        setUploadSlipStatus(NativeUploadSlipStatus.ERROR);
        return;
      }

      // Set preview image URL
      const previewUrl = URL.createObjectURL(file);
      setPreviewSlipImg(previewUrl);

      // Upload slip
      const uploadSlipRes = await uploadProof({
        fundingTxId: fundingTx.id,
        file,
      });

      // Handle response
      handleApiResponse(uploadSlipRes, {
        toastError: false,
        onSuccess: () => {
          // check if user need to retry upload slip
          const isRetry = uploadSlipRes.data?.retry;
          if (isRetry) {
            setUploadSlipStatus(NativeUploadSlipStatus.INIT);
            setPreviewSlipImg("");
            toast.error(t("invalid slip please try with another slip"));
            return;
          }

          // check res status
          switch (uploadSlipRes.data?.fundingTx?.status) {
            case FundingStatus.Processing:
              // continue checking
              break;
            case FundingStatus.Error:
              toast.error(t("payment failed please contact customer service"));
              setUploadSlipStatus(NativeUploadSlipStatus.ERROR);
              break;
          }
        },
        onError: () => {
          toast.error(t("payment failed please contact customer service"));
          setUploadSlipStatus(NativeUploadSlipStatus.ERROR);
        },
      });
    },
    [fundingTx.id, handleApiResponse, t, uploadProof],
  );

  const uploadBtnProps = useMemo(() => {
    return {
      variant: UPLOAD_BTN_VARIANT_MAP?.[uploadSlipStatus] as any,
      icon: UPLOAD_BTN_ICON_MAP?.[uploadSlipStatus],
      text: UPLOAD_BTN_TEXT_MAP?.[uploadSlipStatus],
      iconPosition: uploadSlipStatus === NativeUploadSlipStatus.INIT ? "left" : ("right" as any),
      shiny: uploadSlipStatus === NativeUploadSlipStatus.INIT,
    };
  }, [uploadSlipStatus]);

  const isDisplayBackBtn = useMemo(() => {
    // With slip upload
    if (requireSlip) {
      return uploadSlipStatus === NativeUploadSlipStatus.INIT;
    }

    // Without slip upload
    if (!requireSlip) {
      return paymentStatus === NativeDepositStatus.INIT;
    }
    return false;
  }, [requireSlip, uploadSlipStatus, paymentStatus]);

  const isDisplayCloseBtn = useMemo(() => paymentStatus === NativeDepositStatus.PENDING, [paymentStatus]);

  return (
    <>
      <DrawerTitle
        title={drawerTitle}
        size="lg"
        backButton={
          isDisplayBackBtn && (
            <ButtonIcon
              className="animate-fade-in"
              onClick={handleOnBack}
              icon={<IconMSIArrowBack />}
              variant="ghost"
            />
          )
        }
        closeButton={
          isDisplayCloseBtn && (
            <ButtonIcon
              className="animate-fade-in"
              onClick={handleOnCancel}
              icon={<MdClose size={24} />}
              variant="ghost"
            />
          )
        }
        classes={{
          "root&": "mx-5 mt-4 mb-0 w-full",
          "title&": "!text-[22px]",
        }}
      />
      {/*TAB CONTAINER*/}
      <div className={cn("w-screen max-w-md overflow-x-hidden")}>
        <div
          className={cn("flex transform transition-transform duration-500 ease-in-out will-change-transform", {
            "translate-x-0": uploadSlipStep === NativeUploadSlipStep.INIT,
            "-translate-x-full": uploadSlipStep === NativeUploadSlipStep.UPLOADING,
          })}
        >
          {/*STEP 1*/}
          <div
            className={cn(
              "flex w-screen max-w-md flex-shrink-0 flex-col items-center justify-center px-5 transition-all duration-500",
              {
                "scale-90 opacity-0": uploadSlipStep === NativeUploadSlipStep.UPLOADING,
                "opacity-100": uploadSlipStep === NativeUploadSlipStep.INIT,
              },
            )}
          >
            {/*BANK ACCOUNT*/}
            <div className="mt-6 flex animate-fade-in items-center justify-center gap-2 px-4">
              <p className="content-base-secondary flex-shrink-0 text-lg font-semibold capitalize leading-[24px]">
                {t("My account")}
              </p>
              {bankAccount?.bankIcon && (
                <img
                  className="h-6 w-6 flex-shrink-0"
                  src={bankAccount?.bankIcon}
                  alt="user bank icon"
                />
              )}
              <p className="line-clamp-1 break-words text-lg font-semibold">{bankAccount?.bankAccNo}</p>
            </div>
            {/*NOTE*/}
            <div className="mx-auto mt-2 flex animate-fade-in items-center gap-x-1">
              <MdInfo color="#E8BC89" />
              <span className="content-base text-sm font-bold">{t("use this account to deposit")}</span>
            </div>
            {/*TRANSFER INFO*/}
            <div className="mt-4 flex w-full animate-fade-in flex-col rounded-lg border border-[#FFFFFF3F] bg-[#FFFFFF07] shadow-[0px_8px_16px_0px_#00000019]">
              {/*TIMER & AMOUNT*/}
              <div className="flex flex-col items-center px-4 pb-5 pt-5">
                <div className="flex items-center gap-x-1">
                  <IconErrorHourGlass />
                  <span className="content-base font-feat-tnum text-xl font-semibold leading-7">
                    {formatTimer(remainingSeconds, {
                      minutes: false,
                      seconds: true,
                    })}
                  </span>
                </div>
                <div className="mt-2 flex h-[50px] w-full items-center justify-center gap-x-2 rounded-md border border-[#FFFFFF19] bg-[linear-gradient(180deg,#00000019_0%,#00000066_100%)] px-0 py-2 shadow-[0px_2px_3px_0px_#0000003d]">
                  {requiredInputAmount && (
                    <div className="relative">
                      <span className="content-base text-[28px] font-semibold">
                        {fAmount(payData.amount ?? fundingTx.reqAmount)}
                      </span>
                      <span className="absolute -right-1 top-1/2 -translate-y-1/2 translate-x-full text-sm font-bold text-[#59549F]">
                        {t(currency)}
                      </span>
                    </div>
                  )}
                  {!requiredInputAmount && (
                    <div className="flex items-center">
                      <MdWarning color="#D67D39" />
                      <span className="mx-2 inline-block text-[#DC7F0C]">{t("minimum amount")}</span>
                      <strong className="text-[#EFA011]">{fAmount(method?.content?.min ?? 50)}</strong>
                      <strong className="ml-1 inline-block text-[#EFA011]">{currency}</strong>
                    </div>
                  )}
                </div>
              </div>
              {/*DIVIDER*/}
              <div className="flex items-center">
                <div className="h-[1px] flex-grow bg-white/10"></div>
                <div className="relative h-7 w-7 flex-shrink-0 overflow-y-hidden rounded-full bg-black/20 p-1">
                  <IconCaretDoubleDown className="transfer-info__caret-double-down--B" />
                  <IconCaretDoubleDown className="transfer-info__caret-double-down--A" />
                </div>
                <div className="h-[1px] flex-grow bg-white/10"></div>
              </div>
              {/*TARGET BANK*/}
              <div className="mb-5 flex flex-col items-center px-4 pt-4">
                <span className="content-base text-lg font-bold">{t("transfer to")}:</span>
                <div className="mt-2 flex w-full items-center justify-between space-x-2">
                  <div>
                    {targetBankInfo.icon && (
                      <img
                        className="h-[50px] w-[50px]"
                        src={targetBankInfo.icon}
                        alt="target bank icon"
                      />
                    )}
                    {!targetBankInfo.icon && <IconBank className="ml-2 mr-2 flex-shrink-0 scale-[1.5]" />}
                  </div>
                  <div className="flex flex-grow flex-col items-end space-y-1 break-all">
                    <span className="content-base text-sm leading-[17px]">{targetBankInfo.bankName}</span>
                    <span className="text-2xl font-semibold leading-[30px] text-[#E8BC89]">
                      {targetBankInfo.bankAccountNo}
                    </span>
                    <span className="content-base text-sm leading-[17px]">{targetBankInfo.bankAccountName}</span>
                  </div>
                </div>
              </div>

              <div className="mb-6 mt-auto w-full animate-fade-in px-4">
                <Button
                  size="sm"
                  variant="yellow"
                  onClick={handleCopyAccount}
                  icon={<IconCopyGradient />}
                  iconPosition={"left"}
                  classes={{
                    "label&": "gap-x-0",
                    "root&": "!h-[35px]",
                  }}
                >
                  {t("copy account")}
                </Button>
              </div>
            </div>
            {/*CONFIRM TRANSFER*/}
            <div className="mt-6 w-full">
              <Button
                size="lg"
                disabled={paymentStatus === NativeDepositStatus.PENDING}
                icon={paymentStatus === NativeDepositStatus.PENDING && <IconSpinnerBars className="content-base" />}
                iconPosition="right"
                onClick={handleConfirmTransfer}
                classes={{
                  "root&": "disabled:!bg-[linear-gradient(180deg,#371B97_0%,#411EB9_100%)] !opacity-100",
                }}
                shiny={paymentStatus === NativeDepositStatus.INIT}
              >
                {paymentStatus === NativeDepositStatus.INIT && t("after transferring click here")}
                {paymentStatus === NativeDepositStatus.PENDING && t("checking")}
              </Button>
            </div>
          </div>

          {/*STEP 2*/}
          <div
            className={cn(
              "w-screen max-w-md flex-shrink-0 flex-col items-center justify-center px-5 transition-all duration-500",
              {
                "scale-90 opacity-0": uploadSlipStep === NativeUploadSlipStep.INIT,
                "opacity-100": uploadSlipStep === NativeUploadSlipStep.UPLOADING,
              },
            )}
          >
            <h1 className="content-base mt-8 text-center text-2xl font-bold leading-8">{t("upload slip")}</h1>
            {/*UPLOADER*/}
            <div className="my-6 flex h-[267px] flex-col items-center justify-center rounded-md border border-[rgba(255,255,255,0.16)] bg-[linear-gradient(180deg,_rgba(0,_0,_0,_0.10)_0%,_rgba(0,_0,_0,_0.40)_100%)]">
              {/*PREVIEW*/}
              <div
                className={cn("max-h-[0] opacity-0 transition-all", {
                  "max-h-[130px] opacity-100": previewSlipImg,
                })}
              >
                <img
                  className="h-[130px] w-auto rounded-md object-contain"
                  src={previewSlipImg}
                  alt="preview slip"
                />
              </div>
              {/*IMAGE FEEDBACK*/}
              {previewSlipImg && (
                <div className="my-2 h-4 text-xs leading-4 text-[#EF4444]">
                  {uploadSlipStatus === NativeUploadSlipStatus.ERROR && t("please contact customer service")}
                </div>
              )}
              {/*UPLOAD BUTTON*/}
              <div className="relative w-[200px]">
                <Button
                  size="lg"
                  variant={uploadBtnProps.variant}
                  icon={uploadBtnProps.icon}
                  iconPosition={uploadBtnProps.iconPosition}
                  classes={{
                    "root_secondary&": "bg-[linear-gradient(180deg,#371B97_0%,#411EB9_100%)]",
                  }}
                  shiny={uploadBtnProps.shiny}
                >
                  {t(uploadBtnProps.text)}
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 left-0 top-0 h-full w-full opacity-0"
                  onChange={handleUploadSlip}
                  disabled={uploadSlipStatus !== NativeUploadSlipStatus.INIT}
                />
              </div>

              {uploadSlipStatus === NativeUploadSlipStatus.INIT && (
                <span className="content-base mt-4 text-xs">
                  {t("the image size maximum size MB", {
                    size: SLIP_MAX_SIZE,
                  })}
                </span>
              )}
            </div>
            {/*NOTE*/}
            <div className="px-5 text-center leading-4">
              <span className="content-base text-xs">
                {t("important")}
                {": "}
              </span>
              <span className="content-base-secondary text-xs">
                {t("do not use screenshots from other screens. ensure the qr code is clearly visible on the slip")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const UPLOAD_BTN_VARIANT_MAP: Record<NativeUploadSlipStatus, string> = {
  [NativeUploadSlipStatus.INIT]: "primary",
  [NativeUploadSlipStatus.UPLOADING]: "secondary",
  [NativeUploadSlipStatus.ERROR]: "danger",
  [NativeUploadSlipStatus.SUCCESS]: "green",
};

const UPLOAD_BTN_ICON_MAP: Record<NativeUploadSlipStatus, ReactNode> = {
  [NativeUploadSlipStatus.INIT]: <MdBackup size={24} />,
  [NativeUploadSlipStatus.UPLOADING]: <IconSpinnerBars className="content-base" />,
  [NativeUploadSlipStatus.ERROR]: <MdOutlineCancel size={24} />,
  [NativeUploadSlipStatus.SUCCESS]: <MdOutlineCheckCircle size={24} />,
};

const UPLOAD_BTN_TEXT_MAP: Record<NativeUploadSlipStatus, string> = {
  [NativeUploadSlipStatus.INIT]: "upload slip",
  [NativeUploadSlipStatus.UPLOADING]: "checking",
  [NativeUploadSlipStatus.ERROR]: "payment failed",
  [NativeUploadSlipStatus.SUCCESS]: "success",
};

export default NativeBankTransfer;
