import { PropsWithClassName, cn } from "kz-ui-sdk";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export type TabNavigationOption = {
  label: string;
  value: string;
};

interface TabNavigationProps extends PropsWithClassName {
  onChange?: (tab: TabNavigationOption) => void;
  options: TabNavigationOption[];
  currentTab?: TabNavigationOption;
  defaultTab?: TabNavigationOption;
  classes?: {
    option?: string;
  };
}

const TabNavigation = ({ options, classes, currentTab, defaultTab, className, onChange }: TabNavigationProps) => {
  const [tab, setTab] = useState<TabNavigationOption | undefined>(defaultTab ?? currentTab ?? options?.[0]);
  const { t } = useTranslation();

  useEffect(() => {
    setTab(currentTab);
  }, [currentTab]);

  const handleOnChangeTab = (tab: TabNavigationOption) => {
    setTab(tab);
    onChange?.(tab);
  };

  return (
    <div className={cn("flex h-10 items-center justify-between border-b border-[rgba(255,255,255,0.16)]", className)}>
      {options.map((option) => (
        <button
          key={option.value}
          className={cn("flex h-full w-[90px] justify-center pb-[1px] pt-1.5", classes?.option)}
          onClick={() => handleOnChangeTab(option)}
        >
          <span
            className={cn(
              "flex h-full w-fit items-center justify-center whitespace-nowrap border-b-2 border-b-transparent text-xs leading-4 transition-[font-weight,border-color] will-change-contents",
              {
                "!border-b-[#9389FC] font-bold": tab?.value === option.value,
              },
            )}
          >
            {t(option.label)}
          </span>
        </button>
      ))}
    </div>
  );
};

export default TabNavigation;
