import { GameStats, GetGameProviderStatsResponse } from "kz-ui-sdk";

export const standardizeGameStats = (
  providersStatsResponse: GetGameProviderStatsResponse,
): Record<string, GameStats> => {
  //if providers is undefined => return the old structure
  if (!providersStatsResponse?.providers) {
    return providersStatsResponse;
  }
  //if providers => transform data with new structure
  return providersStatsResponse?.providers?.reduce<Record<string, GameStats>>(
    (acc, provider) => {
      acc[provider.id] = {
        total: provider.total,
        ...provider.categories.reduce<Record<string, number>>((categoryAcc, category) => {
          categoryAcc[category.id] = category.total;
          return categoryAcc;
        }, {}),
      };
      return acc;
    },
    {} as Record<string, GameStats>,
  );
};
