import { siteApi } from "@api";
import { IconSpinnerBars } from "@assets";
import { RHFController, openIframeDialog } from "@components";
import { closeDeposit } from "@components/utils/DepositDrawer";
import { DepositMethodOption } from "@components/utils/DepositDrawer/components";
import { DepositDrawerSubScreen, DepositMethod, NativeDepositStatus } from "@components/utils/DepositDrawer/types";
import { gotoSubScreen } from "@components/utils/DepositDrawer/utils";
import { appConfig } from "@configs";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthSession } from "@hooks";
import useRHF from "@hooks/useRHF.ts";
import {
  Button,
  FundingTxPayDataType,
  NumberField,
  TopupResponse,
  bnOrZero,
  cn,
  delay,
  fNumber,
  useHandleApiResponse,
} from "kz-ui-sdk";
import { throttle } from "lodash-es";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { z as zod } from "zod";
import { NativeDeposit } from "..";

interface StepAmountSelectionProps {
  method: DepositMethod;
  nativeFlowProps?: {
    onStart?: (data: TopupResponse) => void;
    onStatusChange?: (status: NativeDepositStatus) => void;
  };
  redirectFlowProps?: {};
}

const DEV_SIMULATE_PAYMENT_URL = "/simulate/";

type FormValues = {
  amount: number;
};

enum DepositMode {
  // Redirect flow: Open iframe dialog
  REDIRECT,
  // Native flow: Open native deposit screen
  NATIVE,
}

export interface StepAmountSelectionRef {
  backToAmountSelection: () => void;
}

const StepAmountSelection = forwardRef<StepAmountSelectionRef, StepAmountSelectionProps>(
  ({ method, nativeFlowProps }, ref) => {
    const { onStart, onStatusChange } = nativeFlowProps ?? {};
    const { t } = useTranslation();
    const { bankAccount } = useAuthSession();

    const [topUp, { isLoading }] = siteApi.useTopupMutation();
    const { handleApiResponse } = useHandleApiResponse({
      toast,
    });
    const [depositMode, setDepositMode] = useState(DepositMode.REDIRECT);
    const [topUpResponse, setTopUpResponse] = useState<TopupResponse>();

    useImperativeHandle(
      ref,
      () => ({
        backToAmountSelection: () => {
          setDepositMode(DepositMode.REDIRECT);
          delay(500).then(() => {
            setTopUpResponse(undefined);
          });
        },
      }),
      [],
    );

    /**
     * Native deposit flow:
     * MP will handle the deposit flow
     */
    const doNativeDeposit = useCallback(
      (data: TopupResponse) => {
        onStart?.(data);
        setTopUpResponse(data);
        setDepositMode(DepositMode.NATIVE);
      },
      [onStart],
    );

    /**
     * Redirect deposit flow:
     * Open iframe dialog to display 3rd party payment gateway
     */
    const doRedirectDeposit = useCallback(
      (data: TopupResponse) => {
        let iframeUrl = data?.url;

        // Simulate payment in dev mode
        if (data?.url?.startsWith(DEV_SIMULATE_PAYMENT_URL)) {
          iframeUrl = new URL(data.url, appConfig.server.baseURL).toString();
        }

        if (iframeUrl) {
          openIframeDialog(
            {
              url: iframeUrl,
              title: t(method.content.name),
              id: `deposit-${data.fundingTx?.orderRef}`,
            },
            {
              closeConfirmMessage: "Are you sure you want to cancel?",
              withConfirmCloseDialog: true,
            },
          );
          closeDeposit();
        } else {
          console.error("Missing deposit url");
          toast.error(t("an error occurred, please try again"));
        }
      },
      [method.content.name, t],
    );

    const doTopUp = async (amount: number) => {
      if (!bankAccount) return toast.error(t("Lacking of bank account"));
      const topUpRes = await topUp({
        amount: amount,
        method: method.key,
      });
      handleApiResponse(topUpRes, {
        onSuccess: (data) => {
          if (data.fundingTx?.payData?.type) {
            // Check kind of deposit flow
            switch (data.fundingTx.payData.type) {
              case FundingTxPayDataType.NATIVE:
                return doNativeDeposit(data);
              case FundingTxPayDataType.REDIRECT:
                return doRedirectDeposit(data);
            }
          } else {
            // Fall back to default
            doRedirectDeposit(data);
          }
        },
        onError: () => {
          // If amount is not required and has error, back to method selection
          if (method.skipAmountInput) {
            gotoSubScreen(DepositDrawerSubScreen.METHOD_SELECTION);
          }
        },
      });
    };

    // Create a throttled version of doTopUp
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttledDoTopUp = useCallback(
      throttle(
        (amount: number) => {
          doTopUp(amount);
        },
        1000,
        { leading: true, trailing: false },
      ),
      [],
    );

    // if amount is not required, submit the form with default value
    useEffect(() => {
      if (method.skipAmountInput) {
        throttledDoTopUp(0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [method.skipAmountInput]);

    const onSubmit = useCallback(
      async (formValues: FormValues) => {
        throttledDoTopUp(formValues.amount);
      },
      [throttledDoTopUp],
    );

    const formSchema = useMemo(() => {
      const minValue = bnOrZero(method.content.min ?? 100);
      const maxValue = method.content.max ? bnOrZero(method.content.max) : undefined;

      const amountValidator = zod
        // Returned value can be a string. E.g: "100.00"
        .string({
          required_error: "Amount is required",
        })
        // Min validator
        .refine((val) => bnOrZero(val).gte(minValue), {
          message: t(`Minimum minValue currency`, {
            minValue: fNumber(minValue),
            currency: t(method.content.currency),
          }),
        })
        // Max validator
        .refine(
          (val) => {
            if (maxValue) {
              return bnOrZero(val).lte(maxValue);
            }
            return true;
          },
          {
            message: t(`Maximum maxValue currency`, {
              maxValue: fNumber(maxValue),
              currency: t(method.content.currency),
            }),
          },
        );

      return zod.object({
        amount: amountValidator,
      });
    }, [method.content.currency, method.content.max, method.content.min, t]);

    const { control, submit, watch, formState } = useRHF<FormValues>(
      {
        amount: 0,
      },
      zodResolver(formSchema),
      onSubmit,
    );

    const helperTextValidation = watch("amount") < bnOrZero(method.content.min ?? 100).toNumber();

    return (
      <>
        {/*DISPLAY SPINNER IF LOADING & AND NOT REQUIRED AMOUNT*/}
        {isLoading && method.skipAmountInput && (
          <div className="flex h-full w-full items-center justify-center">
            <IconSpinnerBars className="content-base" />
          </div>
        )}
        {/*AMOUNT INPUT*/}
        {!method.skipAmountInput && (
          <div
            className={cn("overflow-hidden transition-all duration-500", {
              "opacity-0": depositMode === DepositMode.NATIVE,
              "opacity-100": depositMode === DepositMode.REDIRECT,
            })}
          >
            <div className="mt-5 flex flex-col items-center gap-4 px-5">
              <DepositMethodOption
                method={method}
                key={method.key}
                // ratio="210/76"
                className="h-[60px] w-[165px]"
                labelClassName="text-base leading-[1.2rem] max-h-[46px]"
                btnClassName="shadow-[0px_4px_4px_0px_#00000040] border rounded-lg bg-white"
                maskedImgClassName="!rounded-lg"
              />

              <label className="flex flex-col gap-1">
                <span
                  className={cn("content-base text-sm", {
                    "content-negative-tertiary": !!formState?.errors?.amount,
                  })}
                >
                  {t("Deposit Amount")}
                </span>
                <RHFController
                  control={control}
                  name="amount"
                  helperText={
                    helperTextValidation
                      ? t(`Minimum minValue currency`, {
                          minValue: fNumber(bnOrZero(method.content.min ?? 100).toNumber()),
                          currency: t(method.content.currency),
                        })
                      : undefined
                  }
                >
                  <NumberField
                    variant="currency"
                    allowDecimals
                    allowNegativeValue={false}
                    currency={t(method.content.currency)}
                    displayHelperText
                  />
                </RHFController>
              </label>

              <Button
                size="lg"
                onClick={submit}
                loading={isLoading}
              >
                {t("Top Up")}
              </Button>
            </div>
          </div>
        )}

        {/*NATIVE DEPOSIT*/}
        <div
          className={cn(
            "absolute right-0 top-0 flex h-full w-full flex-grow flex-col items-center px-5 transition-all duration-500",
            {
              "pointer-events-none translate-x-4 translate-y-12 opacity-0": depositMode === DepositMode.REDIRECT,
              "translate-x-0 translate-y-0 opacity-100": depositMode === DepositMode.NATIVE,
            },
          )}
        >
          {!!bankAccount && !!method && !!topUpResponse && (
            <NativeDeposit
              topUpResponse={topUpResponse}
              onStatusChange={onStatusChange}
              method={method}
            />
          )}
        </div>
      </>
    );
  },
);

StepAmountSelection.displayName = "StepAmountSelection";

export default StepAmountSelection;
