import { IconBank } from "@assets";
import { BackButton } from "@components";
import { useAuthSession } from "@hooks";
import { List, PageTitle } from "kz-ui-sdk";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface BankAccountsPageProps {}

const BankAccountsPage = ({}: BankAccountsPageProps) => {
  const { t } = useTranslation();
  const { bankAccount } = useAuthSession();

  const displayBankAccount = useMemo(() => {
    return {
      ...bankAccount,
      icon: bankAccount?.bankIcon ? (
        <img
          src={bankAccount?.bankIcon}
          alt="bank icon"
          className="h-6 w-6"
        />
      ) : (
        <div className="h-6 w-6">
          <IconBank className="-translate-x-1 -translate-y-1 scale-[0.72]" />
        </div>
      ),
    };
  }, [bankAccount]);

  return (
    <div className="flex flex-col">
      <PageTitle
        label={t("Bank Account")}
        backButton={<BackButton />}
      />
      <List className="mt-4">
        <div className="p-4">
          <h4 className="content-base-secondary mb-1 text-sm leading-5">{t("Bank Account")}</h4>
          <div className="flex items-center gap-x-3">
            {displayBankAccount?.icon}
            <p className="content-base text-base font-bold leading-6">{displayBankAccount?.bankName}</p>
          </div>
        </div>
        <div className="p-4">
          <h4 className="content-base-secondary mb-1 text-sm leading-5">{t("Bank Account Name")}</h4>
          <p className="content-base text-base font-bold leading-6">{displayBankAccount?.bankAccName}</p>
        </div>
        <div className="p-4">
          <h4 className="content-base-secondary mb-1 text-sm leading-5">{t("Account Number")}</h4>
          <p className="content-base text-base font-bold leading-6">{displayBankAccount?.bankAccNo}</p>
        </div>
      </List>
    </div>
  );
};

export default BankAccountsPage;
