import { authApi, publicApi, siteApi } from "@api";
import { Paths } from "@constants/paths";
import { RootState } from "@stores";
import auth from "@stores/auth";
import { AuthSliceState } from "@stores/auth/slices.ts";
import { PreferenceSliceState } from "@stores/preference/slices.ts";
import { AuthStatus, BankAccount } from "@types";
import { logger } from "@utils";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/**
 * Get the current auth session.
 * return { self, oauth, status }
 * - self: Current user profile
 * - oauth: Current auth token
 * - status: Current auth status
 */
const useAuthSession = () => {
  const { self, oauth, bankInfos } = useSelector<RootState, AuthSliceState>((state) => state.auth);
  const { serverInfo } = useSelector<RootState, PreferenceSliceState>((state) => state.preferences);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const status = useMemo(() => {
    // Token not found, authenticating state
    if (oauth === undefined) {
      logger._console.log("[Auth] Token not found, authenticating...");
      return AuthStatus.Authenticating;
    }
    // Found token, but no profile, unauthenticated state
    // Can be used to redirect to login page
    if (!self || !oauth) {
      logger._console.log("[Auth] Token found, but no profile, Unauthenticated.", { self }, { oauth });
      return AuthStatus.Unauthenticated;
    }
    // Found token and profile, authenticated state
    logger._console.log("[Auth] Token and profile found, Authenticated.", { self }, { oauth });
    // Check if user needs to reset password
    if (self?.member?.pwExpireAt) {
      logger._console.log("[Auth] Found pwExpiredAt.", { self }, { oauth });
      const expiredAt = moment.utc(self?.member?.pwExpireAt).utcOffset(serverInfo?.utcOffset ?? 0);
      logger._console.log("[Auth] Found pwExpiredAt.", expiredAt.format(), moment().format());
      if (expiredAt.isBefore(moment())) {
        return AuthStatus.PasswordExpired;
      }
    }
    return AuthStatus.Authenticated;
  }, [oauth, self, serverInfo?.utcOffset]);

  const logout = useCallback(() => {
    // clear redux store
    dispatch(auth.slice.actions.logout());
    // reset api cache
    dispatch(siteApi.util.resetApiState());
    dispatch(authApi.util.resetApiState());
    dispatch(publicApi.util.resetApiState());
    // redirect to login page
    navigate(Paths.PUBLIC.ONBOARD_LOGIN);
  }, [navigate, dispatch]);

  const bankAccount: BankAccount | null = useMemo(() => {
    if (self?.member?.bankAccNo) {
      return {
        bankAccNo: self.member.bankAccNo,
        bankAccName: self.member.fullname,
        bankKey: self.member.bankKey,
        bankName: !!self.member.bankKey ? bankInfos?.[self.member.bankKey]?.name : null,
        bankIcon: !!self.member.bankKey ? bankInfos?.[self.member.bankKey]?.icon : null,
      } as BankAccount;
    }
    return null;
  }, [self?.member?.bankAccNo, self?.member?.fullname, self?.member?.bankKey, bankInfos]);

  return { self, oauth, status, bankAccount, logout, bankInfos };
};

export default useAuthSession;
