import reactHotToast, { ToastOptions } from "react-hot-toast";
import { FcInfo } from "react-icons/fc";

const toast = {
  ...reactHotToast,
  info: (message: string, options?: ToastOptions) =>
    reactHotToast(message, {
      icon: <FcInfo className="h-5 w-5 rounded-full" />,
      ...options,
    }),
};

export default toast;
