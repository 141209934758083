import { siteApi } from "@api";
import { IconSpinner } from "@assets/icons";
import { FundingStatus } from "kz-ui-sdk";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface DepositCallbackPageProps extends PropsWithChildren {}

const DepositCallbackPage = ({}: DepositCallbackPageProps) => {
  const { orderRef } = useParams() as {
    provider: string;
    orderRef: string;
  };
  const [orderStatus, setOrderStatus] = useState(FundingStatus.Processing);
  const { data: fundingRes } = siteApi.useGetFundingHistoryQuery(
    {
      query: {
        limit: 1,
        offset: 0,
        orderRef,
      },
    },
    {
      pollingInterval: 2000,
      skip: orderStatus === FundingStatus.Completed,
    },
  );

  useEffect(() => {
    if (fundingRes && fundingRes.entries[0]) {
      // check if the order is completed
      if (fundingRes.entries[0].status === FundingStatus.Completed) {
        setOrderStatus(FundingStatus.Completed);
      }
    }
  }, [fundingRes]);

  const { t } = useTranslation();

  const dataDisplay = useMemo(() => {
    if (orderStatus === FundingStatus.Completed) {
      return {
        imageUrl: "url(/images/deposit-success.png)",
        title: t("deposit success"),
        message: t("your deposit has been successfully processed"),
      };
    }
    return {
      imageUrl: "url(/images/deposit-processing.png)",
      title: t("deposit processing, please wait."),
      message: t("this may take a few moments."),
    };
  }, [orderStatus, t]);

  return (
    <div className="relative flex h-dvh w-full flex-col overflow-y-hidden bg-[radial-gradient(46.81%_58.96%_at_50%_0%,#3B2A7E_0%,#151E51_100%)]">
      <div className="flex-shrink-0 flex-grow animate-fade-in bg-black/10 shadow-[0px_-8px_16px_0px_rgba(0,0,0,0.25)_inset]">
        <div
          className="mt-7 h-full w-full bg-center bg-no-repeat"
          style={{
            backgroundImage: dataDisplay.imageUrl,
          }}
        ></div>
      </div>
      <div className="flex basis-1/4 animate-slide-fade-up flex-col items-center gap-2 border-t-2 border-white/10 p-5 pb-16">
        <div className="content-base max-w-[264px] text-center text-base tracking-[-0.96px]">{dataDisplay.title}</div>
        <div className="content-base-secondary max-w-[264px] text-center text-sm tracking-[-0.54px]">
          {dataDisplay.message}
        </div>
        <IconSpinner className="mt-5 h-6 w-6" />
      </div>
    </div>
  );
};

export default DepositCallbackPage;
