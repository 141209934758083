import { PropsWithChildren, ReactNode } from "react";

interface FormTitleProps extends PropsWithChildren {
  phone: string;
  subTitle: string | ReactNode;
}

const FormTitle = ({ phone, subTitle }: FormTitleProps) => {
  return (
    <div className="flex flex-col items-center">
      <h1 className="content-base mt-6 h-[32px] text-2xl font-semibold">{phone}</h1>
      <span className="content-base-secondary mt-2 text-base leading-5">{subTitle}</span>
    </div>
  );
};

export default FormTitle;
