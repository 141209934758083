import { BrandLogo, ChatButton, FormTitle, FormUpdateBankAccount } from "@components";
import { deployEnvConfig } from "@configs";
import { Paths } from "@constants";
import { useAuthSession } from "@hooks";
import { AppDispatch } from "@stores";
import { AddBankAccountResponse, delay, localizePhoneNumber } from "kz-ui-sdk";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface ForceUpdateBankPageProps {}

const OnboardUpdateBankPage = (props: ForceUpdateBankPageProps) => {
  const navigate = useNavigate();
  const { self, bankAccount } = useAuthSession();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!!bankAccount) {
      navigate(Paths.PRIVATE.HOME, {
        replace: true,
      });
    }
  }, [bankAccount, navigate]);

  const handleOnComplete = useCallback(
    async (bankAccountRes?: AddBankAccountResponse) => {
      if (!bankAccountRes) return;
      delay().then(() => navigate(Paths.PRIVATE.HOME));
    },
    [navigate],
  );

  return (
    <div className="flex flex-grow flex-col items-center px-5">
      <BrandLogo className="mb-0 mt-3.5" />
      <FormTitle
        phone={localizePhoneNumber(self?.member?.phone ?? "", deployEnvConfig.country.phoneCode)}
        subTitle={t("Enter bank account details")}
      />
      <FormUpdateBankAccount
        defaultBank={bankAccount}
        onComplete={handleOnComplete}
        className="mt-6 w-full"
      />
      <div className="mb-3 mt-auto flex w-full justify-end">
        <ChatButton />
      </div>
    </div>
  );
};

OnboardUpdateBankPage.ignoreLayout = true;
export default OnboardUpdateBankPage;
