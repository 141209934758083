import { deployEnvConfig } from "@configs";
import { Language } from "@types";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from "./locales/en.json";
import thailandTranslation from "./locales/th.json";
import { PersistLanguage } from "./plugins";
import { STORAGE_KEY } from "@constants";

const resources = {
  en: {
    translation: englishTranslation,
  },
  th: {
    translation: thailandTranslation,
  },
};

i18next.use(PersistLanguage).use(initReactI18next);

export const initI18n = (lng: string) => {
  i18next.init({
    resources,
    lng,
    debug: false,
  });
};
const lng = localStorage.getItem(STORAGE_KEY.LANGUAGE) ?? deployEnvConfig.country.language ?? Language.TH;

initI18n(lng);

export default i18next;
