import { MARKETING_DATA_PARAMS, STORAGE_KEY } from "@constants";
import { MarketingDataParam } from "@types";
import { storage } from "kz-ui-sdk";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

interface MarketingDataCollectorProps {}
// To clean up entire old marketing data, can update the version number
const MKTG_VERSION = "1.0.0";

const MarketingDataCollector = ({}: MarketingDataCollectorProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Validate search params
    if (!searchParams || searchParams.size === 0) {
      return;
    }

    // Build marketing data object
    const mktgData: Partial<Record<MarketingDataParam, string>> = {};
    const foundMktgParams = new Set<string>();

    // Search for marketing data params
    searchParams.forEach((value, key) => {
      const keyLower = key.toLowerCase();
      if (MARKETING_DATA_PARAMS.includes(keyLower as MarketingDataParam)) {
        mktgData[keyLower as MarketingDataParam] = value;
        foundMktgParams.add(key);
      }
    });

    // If no marketing data found, return
    if (Object.keys(mktgData).length === 0) {
      return;
    }

    // Validate marketing data version
    storage.local.invalidate(STORAGE_KEY.MARKETING_DATA, MKTG_VERSION);

    // Merge with existing marketing data
    const existingMktgData = storage.local.getItem(STORAGE_KEY.MARKETING_DATA) || {};
    const newMktgData = { ...existingMktgData, ...mktgData };
    storage.local.setItem(STORAGE_KEY.MARKETING_DATA, newMktgData, MKTG_VERSION);

    // Clean up search params
    setSearchParams((prev) => {
      const newSearchParams = new URLSearchParams(prev);
      foundMktgParams.forEach((key) => newSearchParams.delete(key));
      return newSearchParams;
    });
  }, [searchParams, setSearchParams]);

  return <></>;
};

export default MarketingDataCollector;
