import { put } from "@redux-saga/core/effects";
import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import {
  MutationActionCreatorResult,
  MutationDefinition,
  QueryActionCreatorResult,
  QueryDefinition,
} from "@reduxjs/toolkit/query";

export function* execQuery<D extends QueryDefinition<any, any, any, any, any>>(
  action: ThunkAction<QueryActionCreatorResult<D>, any, any, AnyAction>,
) {
  // @ts-ignore
  const promise = yield put(action);
  // @ts-ignore
  const result = yield promise;
  promise.unsubscribe();

  return result.data;
}

export function* execMutation<D extends MutationDefinition<any, any, any, any, any>>(
  action: ThunkAction<MutationActionCreatorResult<D>, any, any, AnyAction>,
) {
  // @ts-ignore
  const promise = yield put(action);
  // @ts-ignore
  const result = yield promise;
  promise.unsubscribe();

  return result.data;
}
