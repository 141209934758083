import { IconNavDeposit, IconNavDepositGlow, IconNavDepositRays } from "@assets/icons";
import { cn } from "kz-ui-sdk";
import "./DepositPulseIcon.css";

interface CoinHeartbeatProps {
  className?: string;
  imgSrc?: string;
  imgClassName?: string;
}

export default function DepositPulseIcon({ className }: CoinHeartbeatProps) {
  return (
    <div className={cn("pointer-events-none relative", className)}>
      <div className="h-10">
        <IconNavDepositRays className="deposit-icon__rays" />
        <IconNavDeposit
          className="absolute"
          width={60}
          height={60}
        />
        <IconNavDepositGlow
          className="deposit-icon--glow"
          width={60}
          height={60}
        />
      </div>
    </div>
  );
}
