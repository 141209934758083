import { initGTM, initSentry } from "@configs";
import "@styles/index.css";
import { logger } from "@utils";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { swRegistration } from "./swRegistration.ts";

initSentry().then(() => {
  logger._console.log("Sentry initialized");
});

initGTM();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Service worker registration
try {
  swRegistration.register();
} catch (e) {
  console.log("Service worker registration failed", e);
}
