import { siteApi } from "@api";
import { MaintenanceOverlay } from "@components";
import { QUERY_ALL } from "@constants";
import { standardizeGameStats } from "@views/GamesPage/utils";
import { GameProviderModel, PropsWithClassName, TabNavigation, TabNavigationAction, cn } from "kz-ui-sdk";
import { PropsWithChildren, useMemo } from "react";

interface ProviderListProps extends PropsWithChildren, PropsWithClassName {
  onSelect?: (provider?: GameProviderModel) => void;
  selected?: GameProviderModel;
  categoryId?: string;
}

const GameProviderList = ({ className, onSelect, selected, categoryId }: ProviderListProps) => {
  const { data: providersResponse } = siteApi.useGetGameProviderListQuery({
    query: QUERY_ALL,
  });
  const { data: providersStatsResponse } = siteApi.useGetGameProviderStatsQuery();

  const providers = useMemo(() => {
    if (providersStatsResponse) {
      const standardizedStats = standardizeGameStats(providersStatsResponse);
      return (providersResponse?.entries || [])
        .map((provider) => ({
          ...provider,
          total: categoryId ? standardizedStats?.[provider.id]?.[categoryId] ?? 0 : 0,
        }))
        .filter((provider) => provider.total !== 0);
    }
  }, [categoryId, providersResponse?.entries, providersStatsResponse]);

  const providerOptions: TabNavigationAction[] = useMemo(() => {
    return (
      providers?.map(
        (provider) =>
          ({
            id: provider.id,
            label: !!provider.primaryImage?.url ? undefined : (
              <MaintenanceOverlay
                underMaintenance={provider.isMaintenance}
                className="rounded-r-lg"
              >
                {provider.name}
              </MaintenanceOverlay>
            ),
            icon: !!provider.primaryImage?.url && (
              <MaintenanceOverlay
                underMaintenance={provider.isMaintenance}
                className="rounded-r-lg"
              >
                <img
                  src={provider.primaryImage?.url}
                  alt={provider.name}
                  className="h-full max-h-[40px] w-auto max-w-[60px] object-contain object-center"
                />
              </MaintenanceOverlay>
            ),
            styles: {
              className: "relative",
            },
          }) satisfies TabNavigationAction,
      ) ?? []
    );
  }, [providers]);

  const selectedOption = useMemo(() => {
    return providerOptions.find((option) => option.id === selected?.id);
  }, [selected, providerOptions]);

  return (
    <div className={cn("xs:scrollbar-primary", className)}>
      <TabNavigation
        onChange={(tab) => {
          if (!tab) {
            onSelect?.(undefined);
            return;
          }
          const provider = providers?.find((provider) => provider.id === tab.id);
          onSelect?.(provider);
        }}
        currentTab={selectedOption}
        tabActions={providerOptions}
        direction={"vertical"}
        variant="secondary"
        classes={{
          "tab&": "w-[85px] py-0.5 px-5 rounded-l-none",
        }}
      />
    </div>
  );
};

export default GameProviderList;
