import { BackButton, BrandLogo } from "@components";
import { PropsWithClassName, cn } from "kz-ui-sdk";
import { PropsWithChildren } from "react";
import { NavigateOptions } from "react-router-dom";

interface OnboardPageLayoutProps extends PropsWithChildren, PropsWithClassName {
  backTo?: string;
  navigateOptions?: NavigateOptions;
}

const OnboardPageLayout = ({ children, backTo, navigateOptions, className }: OnboardPageLayoutProps) => {
  return (
    <div className={cn("relative flex flex-col items-center", className)}>
      {backTo && (
        <BackButton
          className={cn("absolute left-5 top-[18px]")}
          to={backTo}
          navigateOptions={navigateOptions}
        />
      )}
      <BrandLogo className="mb-1 mt-3.5" />
      {children}
    </div>
  );
};

export default OnboardPageLayout;
