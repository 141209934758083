import { delay } from "kz-ui-sdk";
import { MouseEvent } from "react";
import { NavLink, NavLinkProps, useNavigate } from "react-router-dom";

interface DeferNavLinkProps extends NavLinkProps {
  defer?: boolean;
  deferTimeout?: number;
  openNewTab?: boolean;
}

const DeferNavLink = ({ defer = true, deferTimeout = 200, openNewTab, ...props }: DeferNavLinkProps) => {
  const navigate = useNavigate();

  const goto = (url: string) => {
    if (openNewTab) {
      window.open(url, "_blank", "noopener,noreferrer");
      return;
    }
    navigate(url);
  };

  const handleOnClick = (e: MouseEvent) => {
    // prevent default behavior
    e.preventDefault();
    // defer navigation
    if (defer) {
      delay(deferTimeout).then(() => {
        goto(props.to as string);
      });
    } else {
      goto(props.to as string);
    }
  };

  return (
    <NavLink
      {...props}
      onClick={handleOnClick}
    />
  );
};

export default DeferNavLink;
