import { closeWithdraw } from "@components/utils/WithdrawDrawer";
import { Button } from "kz-ui-sdk";
import { useTranslation } from "react-i18next";

export default function SuccessView() {
  const { t } = useTranslation();
  return (
    <div className="flex h-full flex-col items-center text-light1">
      {/*  */}
      <div className="h-[235px] w-full grow border-b-2 border-white/10 bg-black/10 shadow-[0px_-8px_16px_0px_rgba(0,0,0,0.25)_inset]">
        <div
          className="h-full w-full bg-center bg-no-repeat"
          style={{
            backgroundImage: "url(/images/withdraw-success.png)",
          }}
        ></div>
      </div>

      <div className="px-5 pb-8">
        <div className="content-base mb-2 mt-6 text-center text-base tracking-[-0.72px]">
          {t("Your withdrawal request has been received!")}
        </div>
        <div className="content-base-secondary mb-6 text-center text-sm tracking-[-0.54px] text-[#ADAEE1]">
          {t("Processing will be completed shortly.")}
        </div>
        <div className="w-full">
          <Button
            variant="secondary"
            size="lg"
            onClick={closeWithdraw}
          >
            {t("close")}
          </Button>
        </div>
      </div>
    </div>
  );
}
