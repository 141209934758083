import { TabNavigationOption } from "@components/navigation";
import { cn, PropsWithClassName } from "kz-ui-sdk";
import { useCallback, useEffect, useRef, useState } from "react";

type TabPanelOption = TabNavigationOption & {
  panel: React.ReactNode;
};

interface TabPanelContainerProps extends PropsWithClassName {
  options: TabPanelOption[];
  currentTab: TabNavigationOption;
  panelClassName?: string;
}

const TabPanelContainer = ({ options, currentTab, className, panelClassName }: TabPanelContainerProps) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const [containerClassName, setContainerClassName] = useState("");

  const slideToTab = useCallback(
    (tab: TabNavigationOption) => {
      if (!refContainer.current) return;
      const index = options.findIndex((option) => option.value === tab.value);
      refContainer.current.style.transform = `translateX(-${index * (100 / options.length)}%)`;
    },
    [refContainer, options],
  );

  // Skip slide effect at the first render
  useEffect(() => {
    setTimeout(() => {
      setContainerClassName("transition-transform duration-500");
    }, 500);
  }, []);

  useEffect(() => {
    slideToTab(currentTab);
  }, [currentTab, slideToTab]);

  return (
    <div className="overflow-hidden">
      <div
        className={cn("flex will-change-transform", className, containerClassName)}
        style={{
          width: `${options.length * 100}%`,
        }}
        ref={refContainer}
      >
        {options.map((option) => (
          <div
            key={option.value}
            className={cn("w-full flex-1 flex-shrink-0 opacity-50 transition-opacity duration-500", panelClassName, {
              "!opacity-100": currentTab.value === option.value,
              "!h-[calc(100vh-200px)] overflow-y-hidden": currentTab.value !== option.value,
            })}
          >
            {option.panel}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabPanelContainer;
