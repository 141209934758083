import { siteApi } from "@api";
import { closeIframeDialog, openIframeDialog } from "@components";
import { updateIframeUrl } from "@components/utils/IframeDialog";
import { QUERY_ALL } from "@constants";
import { GameModel, GameProviderModel, useHandleApiResponse } from "kz-ui-sdk";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface UseGameGatewayOptions {
  onSuccess?: () => void;
  onRecentChange?: () => void;
}

const useGameGateway = (options?: UseGameGatewayOptions) => {
  const [openingGame, setOpeningGame] = useState<GameModel>();
  const { handleApiResponse } = useHandleApiResponse({ toast });
  const [getGameGateway, { isFetching: isFetchingGameGateway }] = siteApi.useLazyGetGameGatewayQuery();
  const { data: gameProviderList } = siteApi.useGetGameProviderListQuery({
    query: QUERY_ALL,
  });
  const dispatch = useDispatch();

  const refOnSuccess = useRef(options?.onSuccess);
  const { t } = useTranslation();

  useEffect(() => {
    refOnSuccess.current = options?.onSuccess;
  });

  const providerMap = useMemo(() => {
    const resultMap: Record<string, GameProviderModel> = {};
    gameProviderList?.entries?.forEach((provider) => {
      resultMap[provider.id] = provider;
    });
    return resultMap;
  }, [gameProviderList]);

  const handleOpenGame = useCallback(
    async (game: GameModel) => {
      setOpeningGame(game);

      // Open the game in an iframe dialog
      openIframeDialog(
        {
          // open empty iframe first
          url: null,
          title: game.name,
        },
        {
          withLogo: true,
          withAppInstallerOffset: false,
          allowLandscapeMode: true,
          closeOnBack: true,
        },
      );
      // push a new state to the history stack, so that we can handle the back button
      window.history.pushState({ isGameOpen: true }, "");

      // Get the game gateway
      const gameGatewayResponse = await getGameGateway({
        query: {
          code: game.providerRef,
          productId: providerMap[game.gameProviderId]?.sourceRef,
        },
      });

      // Handle the game gateway response, update the iframe URL
      handleApiResponse(gameGatewayResponse, {
        onSuccess: (response) => {
          if (response.url) {
            // update the iframe URL
            updateIframeUrl(response.url);
            refOnSuccess.current?.();
          } else {
            closeIframeDialog();
            toast.error(t("Game is not available at the moment. Please try again later."));
          }
          // refetch recent games
          dispatch(siteApi.util.invalidateTags(["game-recent"]));
        },
        defaultErrorMessage: t("Game is not available at the moment. Please try again later."),
        onError: () => {
          closeIframeDialog();
        },
      });
    },
    [getGameGateway, providerMap, handleApiResponse, t, dispatch],
  );

  return {
    open: handleOpenGame,
    isFetching: isFetchingGameGateway,
    providerMap,
    openingGame,
  };
};

export default useGameGateway;
