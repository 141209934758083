import { FormTitle } from "@components";
import { OnboardMode } from "@constants/misc";
import { Button, cn, CodeField } from "kz-ui-sdk";
import { PropsWithChildren, ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface FormPINProps extends PropsWithChildren {
  phone?: string;
  loading?: boolean;
  mode?: OnboardMode;
  onSubmit: (values: { pin: string }) => void;
  submitLabel?: string | ReactNode;
  className?: string;
  classes?: {
    subTitle?: string;
  };
  messages?: {
    enterPIN?: string;
    reEnterPIN?: string;
  };
}

const PIN_LENGTH = 4;

const FormVerifyPIN = ({
  submitLabel,
  classes,
  phone,
  loading,
  mode,
  className,
  onSubmit,
  messages = {
    enterPIN: "Create new PIN",
    reEnterPIN: "Re-enter new PIN",
  },
}: FormPINProps) => {
  const [PINValue, setPINValue] = useState("");
  const [rePINValue, setRePINValue] = useState("");
  const { t } = useTranslation();

  const [isReenterPIN, setIsReenterPIN] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleOnPINComplete = useCallback((code: string) => {
    setPINValue(code);
    setIsReenterPIN(true);
    setErrorMessage("");
  }, []);

  const handleOnRePINComplete = useCallback(
    async (code: string) => {
      if (PINValue !== code) {
        setRePINValue("");
        setErrorMessage(t("PIN does not match. Please re-enter"));
        // reset re PIN
      } else {
        setErrorMessage("");
        await onSubmit({ pin: code });
      }
    },
    [PINValue, onSubmit, t],
  );

  return (
    <div className={cn("flex flex-col items-center justify-between px-5", className)}>
      <FormTitle
        phone={phone ?? ""}
        subTitle={
          <div className={cn(classes?.subTitle)}>
            {isReenterPIN ? t(messages.reEnterPIN ?? "Re-enter new PIN") : t(messages.enterPIN ?? "Create new PIN")}
          </div>
        }
      />
      {isReenterPIN ? (
        <>
          <div className="mt-6 flex flex-col items-center gap-2">
            <CodeField
              length={PIN_LENGTH}
              type={"password"}
              shouldAutoFocus
              value={rePINValue}
              onChange={setRePINValue}
              onComplete={handleOnRePINComplete}
              error={!!errorMessage}
              helperText={errorMessage}
              key={"rePIN" + errorMessage}
              helperTextClasses={{
                className: "!text-center",
              }}
              disabled={loading}
            />
          </div>
          <div className="mt-6 flex w-full flex-col items-center">
            <Button
              id="btn-next"
              variant="primary"
              size="lg"
              onClick={() => handleOnRePINComplete(rePINValue)}
              loading={loading}
            >
              {submitLabel ? submitLabel : t(mode === OnboardMode.REGISTER ? "Next" : "Finish & login")}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-6 flex w-full flex-col items-center">
            <CodeField
              length={PIN_LENGTH}
              type={"password"}
              shouldAutoFocus
              loading={loading}
              value={PINValue}
              onChange={setPINValue}
              onComplete={handleOnPINComplete}
              key="PIN"
            />
          </div>

          <div className="mt-6 flex w-full flex-col items-center">
            <Button
              id="btn-next"
              variant="primary"
              size="lg"
              onClick={() => handleOnPINComplete(PINValue)}
              disabled={PINValue.length < PIN_LENGTH}
            >
              {t("Next")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FormVerifyPIN;
