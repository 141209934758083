import { FlatTheme, type ThemePresetsConfig } from "kz-ui-sdk";

export const ThemePreset: ThemePresetsConfig = {
  ...FlatTheme,
  Drawer: {
    classes: {
      ...FlatTheme?.Drawer?.classes,
      container: FlatTheme?.Drawer?.classes?.container + " max-w-md left-1/2 transform -translate-x-1/2",
    },
  },
};
