import { siteApi } from "@api";
import { useGetRewardsInfo } from "@hooks/index.ts";
import { checkClaimable } from "@utils";
import { WalletTxMethod } from "kz-ui-sdk";
import { useMemo } from "react";

/**
 * Hook to get the promotion status of Daily Bonus, Cashback, and Referral
 */
const usePromotionStatus = () => {
  const { data: rewardData, isLoading: isFetchingRewardStatus } = useGetRewardsInfo();
  const { data: referralData, isLoading: isFetchingReferralStatus } = siteApi.useGetReferralInfoQuery({});
  const { data: referralClaimData, isLoading: isFetchingReferralClaimData } = siteApi.useGetReferralAvailableClaimQuery(
    {},
    {
      skip: !referralData?.referralConfig?.visibility,
    },
  );

  // Check daily login & reward status
  const { isCashbackAvailable, isCashbackClaimable, isDailyBonusAvailable, isDailyBonusClaimable } = useMemo(() => {
    const checkClaimableResult = checkClaimable(rewardData);
    const isCashbackClaimable =
      checkClaimableResult[WalletTxMethod.DailyCashBack] ||
      checkClaimableResult[WalletTxMethod.InstantCashBack] ||
      checkClaimableResult[WalletTxMethod.WeeklyCashBack] ||
      checkClaimableResult[WalletTxMethod.MonthlyCashBack];

    const isCashbackAvailable =
      !!rewardData?.[WalletTxMethod.DailyCashBack] ||
      !!rewardData?.[WalletTxMethod.InstantCashBack] ||
      !!rewardData?.[WalletTxMethod.WeeklyCashBack] ||
      !!rewardData?.[WalletTxMethod.MonthlyCashBack];

    return {
      isCashbackClaimable,
      isCashbackAvailable,
      isDailyBonusAvailable: !!rewardData?.[WalletTxMethod.DailyBonus],
      isDailyBonusClaimable: checkClaimableResult[WalletTxMethod.DailyBonus],
    };
  }, [rewardData]);

  // Check referral status
  const { isReferralAvailable, isReferralClaimable } = useMemo(() => {
    return {
      isReferralAvailable: !!referralData?.referralConfig?.visibility,
      isReferralClaimable:
        !!referralData?.referralConfig &&
        referralClaimData?.availableAmount?.gt(0) &&
        referralClaimData?.availableAmount?.gte(referralData?.referralConfig?.minComAmt ?? 0),
    };
  }, [referralData, referralClaimData]);

  // combine status
  const { isAnyAvailable, isAnyClaimable } = useMemo(() => {
    return {
      isAnyAvailable: isCashbackAvailable || isDailyBonusAvailable || isReferralAvailable,
      isAnyClaimable: isCashbackClaimable || isDailyBonusClaimable || isReferralClaimable,
    };
  }, [
    isCashbackAvailable,
    isCashbackClaimable,
    isDailyBonusAvailable,
    isDailyBonusClaimable,
    isReferralAvailable,
    isReferralClaimable,
  ]);

  const isFetching = isFetchingRewardStatus || isFetchingReferralStatus || isFetchingReferralClaimData;

  return {
    isCashbackAvailable,
    isCashbackClaimable,
    isDailyBonusAvailable,
    isDailyBonusClaimable,
    isReferralAvailable,
    isReferralClaimable,
    isAnyAvailable,
    isAnyClaimable,
    isFetching,
  };
};

export default usePromotionStatus;
