import { getBrandResource } from "@utils";
import { cn, PropsWithClassName } from "kz-ui-sdk";
import { NavigateOptions, useNavigate } from "react-router-dom";

type BrandLogoType = "sm" | "md" | "auto";
interface BrandLogoProps extends PropsWithClassName {
  size?: BrandLogoType;
  navigateTo?: string;
  navigateOptions?: NavigateOptions;
}

const BrandLogo = ({ size = "md", className, navigateTo, navigateOptions }: BrandLogoProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (navigateTo) {
      navigate(navigateTo, navigateOptions);
    }
  };

  return (
    <img
      src={getBrandResource("logo.png")}
      className={cn("no-tap-highlight object-contain", SIZE_MAP_CLASSES[size], className)}
      alt={"kz-logo"}
      onClick={handleClick}
      role={navigateTo ? "button" : undefined}
    />
  );
};

const SIZE_MAP_CLASSES: Record<BrandLogoType, string> = {
  sm: "h-[46px] auto",
  md: "h-[40px] w-[110px]",
  auto: "w-auto h-full",
};

export default BrandLogo;
