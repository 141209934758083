import { STORAGE_KEY } from "@constants";

export const storeReferralCode = (refCode: string | undefined | null) => {
  if (refCode) {
    sessionStorage.setItem(STORAGE_KEY.REF_CODE, refCode);
  }
};

export const getReferralCode = () => {
  return sessionStorage.getItem(STORAGE_KEY.REF_CODE) || undefined;
};

export const clearReferralCode = () => {
  sessionStorage.removeItem(STORAGE_KEY.REF_CODE);
};
