import { SVGProps } from "react";

interface IconCloseProps extends SVGProps<SVGSVGElement> {}

const IconClose = (props: IconCloseProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M9.17 15.58c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l5.66-5.66c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L9.7 15.36c-.14.15-.34.22-.53.22Z"
        fill="currentColor"
      ></path>
      <path
        d="M14.83 15.58c-.19 0-.38-.07-.53-.22L8.64 9.7a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l5.66 5.66c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default IconClose;
