import { EVENT_NATIVE_DEPOSIT_CLOSE } from "@components/utils/DepositDrawer/constants";
import { DepositDrawerSubScreen } from "@components/utils/DepositDrawer/types";
import {
  EVENT_CLOSE_DEPOSIT,
  EVENT_GO_TO_SUB_SCREEN,
  EVENT_NATIVE_DEPOSIT_BACK,
  EVENT_NATIVE_DEPOSIT_CANCEL,
  EVENT_NATIVE_DEPOSIT_TIMEOUT,
  EVENT_OPEN_DEPOSIT,
} from "..";

export function openDeposit() {
  window.dispatchEvent(new CustomEvent(EVENT_OPEN_DEPOSIT));
}

export function closeDeposit() {
  window.dispatchEvent(new CustomEvent(EVENT_CLOSE_DEPOSIT));
}

export function gotoSubScreen(subScreen: DepositDrawerSubScreen) {
  window.dispatchEvent(new CustomEvent(EVENT_GO_TO_SUB_SCREEN, { detail: subScreen }));
}

export function nativeDepositCancel() {
  window.dispatchEvent(new CustomEvent(EVENT_NATIVE_DEPOSIT_CANCEL));
}

export function nativeDepositClose() {
  window.dispatchEvent(new CustomEvent(EVENT_NATIVE_DEPOSIT_CLOSE));
}

export function nativeDepositTimeout() {
  window.dispatchEvent(new CustomEvent(EVENT_NATIVE_DEPOSIT_TIMEOUT));
}

export function nativeDepositBack() {
  window.dispatchEvent(new CustomEvent(EVENT_NATIVE_DEPOSIT_BACK));
}
