export enum AuthStatus {
  Authenticating = "authenticating",
  Authenticated = "authenticated",
  Unauthenticated = "unauthenticated",
  PasswordExpired = "passwordExpired",
}

export interface BankAccount {
  bankAccNo: string;
  bankAccName: string;
  bankKey: string;
  bankName: string | null;
  bankIcon: string | null;
}
