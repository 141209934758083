import { FormOTPAndPIN, OnboardPageLayout } from "@components";
import { deployEnvConfig } from "@configs";
import { OnboardMode, Paths } from "@constants";
import { useAuthSession } from "@hooks";
import { AuthStatus } from "@types";
import { localizePhoneNumber } from "kz-ui-sdk";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

interface ForgotPinPageProps {}

const OnboardResetPINPage = ({}: ForgotPinPageProps) => {
  const { self, status } = useAuthSession();
  const navigate = useNavigate();

  const phone = useMemo(() => {
    return self?.member?.phone ?? "";
  }, [self]);

  useEffect(() => {
    if (status !== AuthStatus.PasswordExpired) {
      navigate(Paths.PRIVATE.HOME, {
        replace: true,
      });
    }
  }, [navigate, status]);

  return (
    <OnboardPageLayout>
      <FormOTPAndPIN
        phone={localizePhoneNumber(phone, deployEnvConfig.country.phoneCode)}
        mode={OnboardMode.LOGIN}
        deferUpdateToken={true}
      />
    </OnboardPageLayout>
  );
};

export default OnboardResetPINPage;
