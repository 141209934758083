import * as React from "react";
import { cloneElement, isValidElement } from "react";
import type { Control, Path } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface RHFControllerProps<T extends object> extends React.PropsWithChildren {
  name: keyof T;
  control: Control<T>;
  onChange?: (...event: any[]) => void;
  helperText?: string;
  onBlur?: (value: any) => void;
}

export default function RHFController<T extends object>({
  name,
  control,
  children,
  onChange,
  helperText,
  onBlur,
}: RHFControllerProps<T>) {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name as unknown as Path<T>}
      render={({ field, fieldState: { error } }) => {
        const { ref: _ref, ...rest } = field; // ignore ref to avoid the error in browser console (still work well)
        return isValidElement(children) ? (
          cloneElement(children, {
            ...rest,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            disabled: (rest?.disabled ?? false) || ((children.props?.disabled ?? false) as boolean),
            error: Boolean(error),
            helperText: t(helperText ? helperText : (error?.message as string)),
            onChange: (v: any) => {
              rest.onChange(v);
              onChange?.(v);
            },
            onBlur: () => {
              rest.onBlur();
              onBlur?.(rest.value);
            },
          } as typeof rest)
        ) : (
          <>{children}</>
        );
      }}
    />
  );
}
