import { authApi } from "@api";
import { IconBank } from "@assets";
import { Select, SelectProps, cn } from "kz-ui-sdk";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface BankSelectProps extends Omit<SelectProps<{ value: string; label: string }>, "label" | "options"> {}

const BankSelect: React.FC<BankSelectProps> = ({ ...props }: BankSelectProps) => {
  const { data } = authApi.useGetProfileQuery();
  const { t } = useTranslation();

  const bankList = useMemo(() => {
    const bankMap = data?.bankInfos;

    return Object.entries(bankMap ?? {})
      .filter(([bankKey, bankConfig]) => !!bankConfig.visibility)
      .sort(([, lhsBank], [, rhsBank]) => (lhsBank.sort ?? 0) - (rhsBank.sort ?? 0))
      .map(([bankKey, bankConfig]) => ({
        value: bankKey,
        label: bankConfig.name,
        icon: !!bankConfig.icon ? (
          <img
            src={bankConfig.icon}
            alt={bankConfig.name}
            className={cn(`mt-0.5 h-[24px] w-[24px] flex-shrink-0 self-start object-contain`)}
          />
        ) : null,
      }));
  }, [data?.bankInfos]);

  return (
    <Select
      {...props}
      icon={
        <div>
          <IconBank className="-ml-1 scale-75" />
        </div>
      }
      placeholder={t("Select bank")}
      options={bankList}
      optionIconPlaceholder={<div className="mt-0.5 h-[24px] w-[24px] flex-shrink-0" />}
      size={"lg"}
      classes={{
        "root&": "gap-0",
        "label&": "!text-light1 text-base",
        "placeHolder&": "text-left w-56",
        "helperText&": "!mt-1 !text-xs my-0 px-0",
      }}
    />
  );
};

export default BankSelect;
