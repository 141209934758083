import { siteApi } from "@api";
import { IconMSIKeyboardArrowDown } from "@assets";
import IconPentagonUp from "@assets/icons/IconPentagonUp.tsx";
import { BackButton } from "@components";
import { TFunction } from "i18next";
import {
  Button,
  CashBackType,
  Drawer,
  DrawerTitle,
  EmptyState,
  FundingTransaction,
  FundingTxModel,
  FundingType,
  GetFundingRequest,
  List,
  PageTitle,
  SelectOption,
  SelectOptions,
  Skeleton,
  useHandleApiResponse,
  useInfiniteQuery,
  WalletTxMethod,
} from "kz-ui-sdk";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const AVAILABLE_FILTER_TYPES = [
  FundingType.Deposit,
  FundingType.Withdraw,
  FundingType.Adjustment,
  FundingType.Promotion,
  FundingType.Commission,
];

const FILTER_ALL_ID = AVAILABLE_FILTER_TYPES.join(",");

const itemsPerPage = 10;
const initialQuery: GetFundingRequest["query"] = {
  count: 0,
  limit: itemsPerPage,
  offset: 0,
  sort: "requestedAt:desc",
  type: FILTER_ALL_ID,
};

const TransactionPage = () => {
  const [queryParams, setQueryParams] = useState<GetFundingRequest["query"]>(initialQuery);
  const [getFundingHistory] = siteApi.useLazyGetFundingHistoryQuery();
  const { t } = useTranslation();
  const ALL_TX_TYPE_OPTION: SelectOption = useMemo(
    () => ({
      id: FILTER_ALL_ID,
      name: t("All Transactions"),
    }),
    [t],
  );

  const [isShowTxTypeSelector, setIsShowTxTypeSelector] = useState(false);
  const [selectedTxType, setSelectedTxType] = useState<SelectOption>(ALL_TX_TYPE_OPTION);

  const toggleTxTypeSelector = () => {
    setIsShowTxTypeSelector((prev) => !prev);
  };

  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });

  // Fetch transaction history
  const {
    data: fundingData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingInitialPage,
  } = useInfiniteQuery<GetFundingRequest, FundingTxModel>(
    getFundingHistory,
    {
      query: queryParams,
    },
    {
      onError: handleApiResponse,
    },
  );

  // Update query params when filter changes
  useEffect(() => {
    setQueryParams((params) => ({
      ...params,
      type: selectedTxType?.id,
    }));
  }, [ALL_TX_TYPE_OPTION.id, selectedTxType]);

  const txTypeOptions = useMemo(() => {
    // Convert FundingType enum to option list
    const fundingTypes: SelectOption[] = [ALL_TX_TYPE_OPTION];

    fundingTypes.push(
      ...AVAILABLE_FILTER_TYPES.map((type) => ({
        id: type,
        name: t(type),
      })),
    );

    return fundingTypes;
  }, [ALL_TX_TYPE_OPTION, t]);

  const handleOnSelectTxType = (option: SelectOption) => {
    setSelectedTxType(option);
    setIsShowTxTypeSelector(false);
  };

  const isLoading = useMemo(() => {
    return isFetchingNextPage || isFetchingInitialPage;
  }, [isFetchingInitialPage, isFetchingNextPage]);

  return (
    <>
      <div className="flex flex-col">
        <PageTitle
          label={t("Transactions History")}
          backButton={<BackButton />}
          className="mb-4"
        />
        <div className="flex items-center gap-1">
          <Button
            onClick={toggleTxTypeSelector}
            variant="contained"
            icon={<IconMSIKeyboardArrowDown />}
            iconPosition="right"
            size="lg"
            classes={{
              "label&": "text-base font-normal content-base w-full justify-between px-3",
            }}
          >
            {selectedTxType?.name}
          </Button>
        </div>
        {!!fundingData.length && (
          <>
            <List className="mt-4 animate-fade-in">
              {fundingData?.map((fundingTx: FundingTxModel) => {
                return (
                  <FundingTransaction
                    key={fundingTx.id}
                    transaction={{
                      amount: fundingTx.netAmount ?? fundingTx.reqAmount,
                      currency: t(fundingTx.netCurrency ?? fundingTx.reqCurrency),
                      timestamp: getTxTimestamp(fundingTx),
                      title: fundingTx.type,
                      status: fundingTx.status,
                      type: fundingTx.type,
                      remark: getTxRemark(fundingTx, t),
                    }}
                    statusLabel={t(fundingTx.status)}
                    title={t(fundingTx.type)}
                    classes={{
                      "label&": "capitalize",
                    }}
                  />
                );
              })}
            </List>

            {hasNextPage && (
              <Button
                loading={isFetchingNextPage}
                onClick={fetchNextPage}
                size="sm"
                variant="secondary"
                className="mx-auto mt-6 !w-fit px-4"
                classes={{
                  "label&": "text-xs",
                }}
              >
                {t("Show More")}
              </Button>
            )}
          </>
        )}
        {fundingData.length === 0 && !isLoading && (
          <EmptyState
            className="mt-4 animate-fade-in"
            icon={<IconPentagonUp />}
            title={t("No transaction found.")}
          />
        )}
        {isLoading && (
          <div className="mt-4 flex flex-col gap-y-4">
            <Skeleton
              className="!h-20 !rounded-md"
              count={8}
            />
          </div>
        )}
      </div>
      <Drawer
        open={isShowTxTypeSelector}
        onClose={toggleTxTypeSelector}
        placement="bottom"
      >
        <DrawerTitle title={t("Transaction Types")} />
        <SelectOptions
          className="mx-8 mb-6"
          options={txTypeOptions}
          selectedOption={selectedTxType}
          handleOnSelect={handleOnSelectTxType}
        />
      </Drawer>
    </>
  );
};

const getTxRemark = (fundingTx: FundingTxModel, t: TFunction) => {
  if (fundingTx.type === FundingType.Promotion) {
    switch (fundingTx.method) {
      case CashBackType.Instant:
      case WalletTxMethod.InstantCashBack:
        return t("Instant cashback");
      case CashBackType.Daily:
      case WalletTxMethod.DailyCashBack:
        return t("Daily cashback");
      case CashBackType.Weekly:
      case WalletTxMethod.WeeklyCashBack:
        return t("Weekly cashback");
      case CashBackType.Monthly:
      case WalletTxMethod.MonthlyCashBack:
        return t("Monthly cashback");
      case WalletTxMethod.DailyBonus:
        return t("Daily bonus");
    }
  }
  return fundingTx.remark ? t(fundingTx.remark) : undefined;
};

const getTxTimestamp = (fundingTx: FundingTxModel) => {
  if (fundingTx.completedAt) return fundingTx.completedAt;
  if (fundingTx.canceledAt) return fundingTx.canceledAt;
  if (fundingTx.auditApproveAt) return fundingTx.auditApproveAt;
  if (fundingTx.providerNotifiedAt) return fundingTx.providerNotifiedAt;
  if (fundingTx.requestedAt) return fundingTx.requestedAt;

  return fundingTx.createdAt ?? moment();
};

export default TransactionPage;
