import { IconMSIArrowForward } from "@assets";
import { CoinHeartbeat } from "@components";
import { cn, WarningWrapper } from "kz-ui-sdk";
import { PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import MenuButton from "../MenuButton";

interface RewardButtonProps extends PropsWithChildren {
  // Display claim now button
  claimable?: boolean;
  // Display locked button
  locked?: boolean;
  lockedIcon?: ReactNode;
  rewardIcon?: ReactNode;
  onClick?: () => void;
}

const RewardButton = ({ claimable = false, locked, lockedIcon, rewardIcon, children, onClick }: RewardButtonProps) => {
  const { t } = useTranslation();

  return (
    <WarningWrapper
      isShown={claimable}
      icon={
        <CoinHeartbeat
          className="absolute -right-1 -top-1"
          imgClassName="h-4 w-4"
        />
      }
    >
      <MenuButton
        icon={rewardIcon}
        className={cn({ "pointer-events-none opacity-50": locked })}
        onClick={onClick}
        rightSection={
          <>
            {claimable && (
              <div className="content-base flex items-center gap-x-0.5 text-[10px]">
                <span>{t("Claim Now")}</span>
                <IconMSIArrowForward />
              </div>
            )}
            {/*Display arrow*/}
            {!claimable && !locked && <IconMSIArrowForward />}
            {/*Display locked icon*/}
            {locked && lockedIcon}
          </>
        }
      >
        {children}
      </MenuButton>
    </WarningWrapper>
  );
};

export default RewardButton;
