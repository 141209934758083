import { cn, PropsWithClassName } from "kz-ui-sdk";

export interface StatsTableCol {
  title: string;
  value: React.ReactNode;
  subValue?: string;
  classes?: {
    root?: string;
    title?: string;
    value?: string;
    subValue?: string;
  };
}

interface StatsTableProps extends PropsWithClassName {
  title?: string;
  cols: StatsTableCol[];
}

const StatsTable = ({ title, cols, className }: StatsTableProps) => {
  return (
    <div className={cn("relative", className)}>
      <fieldset className="rounded-md border border-[rgba(255,255,255,0.16)]">
        {!!title && (
          <legend className="content-base ml-2 flex h-4 items-center justify-center rounded-sm px-0.5 text-xs leading-4">
            {title}
          </legend>
        )}
        <div
          className={cn("flex items-center justify-center gap-x-1 px-1 py-[7px]", {
            "pb-1.5 pt-1": !!title,
          })}
        >
          {cols.map((col, index) => (
            <>
              <div
                className={cn("flex flex-1 flex-col items-center", col.classes?.root)}
                key={col.title}
              >
                <span className={cn("content-base-secondary text-center text-xs leading-4", col.classes?.title)}>
                  {col.title}
                </span>
                <span className={cn("content-base text-center text-base font-bold leading-6", col.classes?.value)}>
                  {col.value}
                </span>
                {col.subValue && (
                  <span
                    className={cn(
                      "content-base-secondary text-center text-[10px] leading-[10px]",
                      col.classes?.subValue,
                    )}
                  >
                    {col.subValue}
                  </span>
                )}
              </div>
              {index < cols.length - 1 && <div className="h-10 w-[1px] bg-[rgba(255,255,255,0.16)]" />}
            </>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default StatsTable;
