import { cn, fNumber, PropsWithClassName } from "kz-ui-sdk";
import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { useCountUp } from "react-countup";

interface PointLabelProps extends PropsWithClassName {
  startVal: number;
  endVal: number;
  onCountEnd?: () => void;
  onCountStart?: () => void;
  type?: "up" | "down";
  decimal?: number;
  duration?: number;
}

export interface PointLabelRef {
  count: () => void;
  reset: () => void;
  update: (newEndVal: number) => void;
}

const PointLabel = forwardRef<PointLabelRef, PointLabelProps>(
  (
    {
      startVal,
      endVal,
      onCountStart,
      onCountEnd,
      type = "down",
      className,
      decimal = 0,
      duration = 2,
    }: PointLabelProps,
    ref,
  ) => {
    const countUpRef = useRef(null);

    const handleOnCountEnd = useCallback(() => {
      onCountEnd?.();
    }, [onCountEnd]);

    const handleOnCountStart = useCallback(() => {
      onCountStart?.();
    }, [onCountStart]);

    const { start, reset, update } = useCountUp({
      decimals: decimal,
      end: endVal,
      start: startVal,
      duration: duration,
      ref: countUpRef,
      startOnMount: false,
      startVal: startVal,
      onStart: handleOnCountStart,
      onEnd: handleOnCountEnd,
      formattingFn: (value) => fNumber(value, decimal),
    });

    const handleOnCount = useCallback(() => {
      start();
    }, [start]);

    useImperativeHandle(ref, () => ({
      count: handleOnCount,
      reset,
      update,
    }));

    return (
      <span
        className={cn(className, "will-change-transform")}
        ref={countUpRef}
      >
        {fNumber(startVal, decimal)}
      </span>
    );
  },
);

PointLabel.displayName = "PointLabel";

export default PointLabel;
