import { siteApi } from "@api";
import IconPentagonUp from "@assets/icons/IconPentagonUp.tsx";
import { ScrollBox } from "@components";
import { usePreference } from "@hooks";
import { EmptyState, Skeleton } from "kz-ui-sdk";
import { useMemo } from "react";
import { AspectRatio } from "react-aspect-ratio";
import { useTranslation } from "react-i18next";
import { DepositMethodOption } from "../../components";
import { MINT_METHOD } from "../../constants";
import { DepositMethod } from "../../types";
import { standardizeDepositMethod } from "../../utils";

interface StepMethodSelectionProps {
  onChange?: (method: DepositMethod) => void;
}

export default function StepMethodSelection({ onChange }: StepMethodSelectionProps) {
  const { t } = useTranslation();
  const { serverInfo } = usePreference();
  const { data, isFetching: isFetchingMethods } = siteApi.useGetDepositMethodsQuery();

  const depositMethods: DepositMethod[] = useMemo(() => {
    if (isFetchingMethods) return [];
    let methods: DepositMethod[] = Object.entries(data?.entries ?? {})
      .map(([_, value]) => standardizeDepositMethod(value))
      .filter((method) => method !== null) as DepositMethod[];

    // Filter out disabled methods
    methods = methods.filter((method) => method.content.enabled);

    // Sort deposit methods by ASC
    methods = methods.sort((lhsMethod, rhsMethod) => {
      return (lhsMethod.content.sort ?? 0) - (rhsMethod.content.sort ?? 0);
    });

    // Add Mint method if enabled
    if (serverInfo?.simulate?.deposit) {
      methods.push(MINT_METHOD);
    }

    return methods;
  }, [isFetchingMethods, data?.entries, serverInfo?.simulate?.deposit]);

  const isEmpty = useMemo(() => {
    return depositMethods.length === 0 && !isFetchingMethods;
  }, [depositMethods.length, isFetchingMethods]);

  return (
    <div className="flex h-full flex-col pb-4">
      <span className="content-base-secondary pb-4 pt-0.5 text-center text-sm leading-normal">
        {t("Select payment method")}
      </span>
      {isFetchingMethods && (
        <div className="mx-auto flex h-0 flex-col gap-6">
          {Array.from({ length: 4 }).map((_, index) => (
            <AspectRatio
              key={index}
              ratio="200/76"
              className="!h-[76px] !w-[200px]"
            >
              <Skeleton className="!h-full !w-full" />
            </AspectRatio>
          ))}
        </div>
      )}

      {!isEmpty && (
        <ScrollBox
          classes={{
            root: "flex flex-1 flex-col overflow-y-hidden",
            content: "flex flex-grow flex-col gap-y-5 overflow-y-auto items-center h-[300px]",
            shadow: "shadow-[0_0_16px_20px_#17114a] animate-fade-in",
          }}
        >
          {depositMethods.map((method) => (
            <DepositMethodOption
              method={method}
              onClick={onChange}
              key={method.key}
              // ratio="210/76"
              className="!h-[76px] !w-[200px]"
              btnClassName="!rounded-2xl border-[3px] !h-[76px] !w-[200px] border-white bg-white"
              maskedImgClassName="!rounded-[14px] !w-[194px] !h-[70px]"
            />
          ))}
        </ScrollBox>
      )}
      {isEmpty && (
        <EmptyState
          icon={<IconPentagonUp />}
          subTitle={t("There is no available deposit method at the moment.")}
          title={t("Please try again later")}
        />
      )}
    </div>
  );
}
