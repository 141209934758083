import { IconMSIArrowBack } from "@assets";
import { ButtonIcon, ButtonIconVariant, delay, PropsWithClassName } from "kz-ui-sdk";
import { NavigateOptions, useNavigate } from "react-router-dom";
interface BackButtonProps extends PropsWithClassName {
  to?: string;
  variant?: ButtonIconVariant;
  replace?: boolean;
  navigateOptions?: NavigateOptions;
  onClick?: () => void;
}

const BackButton = ({ to, className, onClick, variant = "ghost", navigateOptions }: BackButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    delay(200).then(() => {
      if (onClick) {
        onClick();
        return;
      }
      if (to) {
        navigate(to, navigateOptions);
      } else {
        navigate(-1);
      }
    });
  };

  return (
    <ButtonIcon
      onClick={handleClick}
      className={className}
      variant={variant}
      icon={<IconMSIArrowBack />}
    />
  );
};

export default BackButton;
