import { IconMSIClose } from "@assets";
import { APP_INSTALLER_HEIGHT } from "@constants";
import preferenceSlice from "@stores/preference/slices.ts";
import { getBrandResource } from "@utils";
import { Button, PWAInstaller } from "kz-ui-sdk";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface AppInstallerProps {}

const AppInstaller = ({}: AppInstallerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnAppInstallable = (isInstallable: boolean) => {
    dispatch(preferenceSlice.actions.setDisplayAppInstaller(isInstallable));
  };

  return (
    <PWAInstaller
      onInstallable={handleOnAppInstallable}
      instructionOptions={{
        openExtBrowser: {
          videoSrc: "/videos/help-open-ext-browser.mp4",
          title: t("download app"),
        },
        openShareAddHomeScreen: {
          videoSrc: "/videos/help-share-add-homescreen.mp4",
          title: t("download app"),
        },
      }}
    >
      {(onInstall, onDismiss) => (
        <div
          className="fixed left-1/2 top-0 z-[9998] flex w-full max-w-md flex-shrink-0 -translate-x-1/2 items-center bg-black/60 px-1"
          style={{
            height: APP_INSTALLER_HEIGHT,
          }}
        >
          <button
            onClick={onDismiss}
            className="flex h-[30px] w-[30px] flex-shrink-0 items-center justify-center"
          >
            <IconMSIClose className="h-6 w-6 text-[#958ED8]" />
          </button>
          <img
            src={getBrandResource("app-installer-icon.png")}
            className="ml-2 h-9 w-auto self-end object-cover"
            alt="install-app"
          />
          <div className="flex-grow-1 flex flex-col pl-2">
            <div className="content-base text-sm font-semibold leading-5">{t("download app")}</div>
            <div className="content-base-secondary text-[10px] leading-3">{t("get free credit everyday")}</div>
          </div>
          <Button
            variant="danger"
            size="sm"
            className="ml-auto mr-3 flex-shrink-0 animate-zoom-pulse rounded-lg"
            classes={{
              "root&": "!h-[26px] !w-fit px-3",
            }}
            onClick={onInstall}
          >
            {t("add")}
          </Button>
        </div>
      )}
    </PWAInstaller>
  );
};

export default AppInstaller;
