import { BrandBanner, DailyRewardDialog, DeferNavLink } from "@components";
import { Brand, Paths } from "@constants";
import { usePromotionStatus } from "@hooks";
import { FadeUpSmall, RevealOnScroll, Skeleton, cn } from "kz-ui-sdk";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DepositButton, RewardButton, WithdrawButton } from "./components";

const HomePage = () => {
  const { t } = useTranslation();
  const {
    isFetching: isFetchingPromotionStatus,
    isDailyBonusAvailable,
    isCashbackAvailable,
    isCashbackClaimable,
    isReferralAvailable,
    isReferralClaimable,
  } = usePromotionStatus();

  const externalLinks: ExternalLink[] = useMemo(() => {
    const currentBrandKey = import.meta.env.VITE_BRAND_KEY;
    const links = [];
    // External link for XO268
    if (currentBrandKey === Brand.XO268.key) {
      links.push({
        title: t("VIP"),
        icon: "/images/vip/icon-home-vip.png",
        url: Paths.EXTERNAL.ILV,
      });
    }
    // Slots Hack AI
    const slotHackUrl = Paths.EXTERNAL.SLOTS_HACK_AI[currentBrandKey];
    if (slotHackUrl) {
      links.push({
        title: t("slots ai prediction"),
        icon: "/images/slots-hack-ai/icon-home-slots-hack-ai.png",
        url: slotHackUrl,
      });
    }
    return links;
  }, [t]);

  const buttonSkeletonLength = useMemo(() => {
    const DEFAULT_BUTTON_COUNT = 3;
    return DEFAULT_BUTTON_COUNT + externalLinks.length;
  }, [externalLinks]);

  return (
    <div className="relative flex h-full flex-col gap-4">
      <BrandBanner />

      <RevealOnScroll
        animation={FadeUpSmall}
        triggerOnce
        cascade
        damping={RevealOnScroll.DAMPLING.FASTEST}
        fraction={0.05}
      >
        <div className="flex flex-row justify-center gap-2">
          <DepositButton />
          <WithdrawButton />
        </div>

        {!isFetchingPromotionStatus && (
          <>
            {/*DAILY LOGIN*/}
            {isDailyBonusAvailable && (
              <div className="mb-2">
                <DailyRewardDialog />
              </div>
            )}

            {/*CASHBACK*/}
            {isCashbackAvailable && (
              <DeferNavLink
                className={cn("mb-2 block", {
                  "pointer-events-none": !isCashbackAvailable,
                })}
                to={Paths.PRIVATE.CASHBACK}
              >
                <RewardButton
                  claimable={isCashbackClaimable}
                  rewardIcon={
                    <img
                      src="/images/icon-home-cashback.png"
                      alt="cashback"
                      className="h-full w-auto object-contain"
                    />
                  }
                >
                  {t("Cashback")}
                </RewardButton>
              </DeferNavLink>
            )}

            {/*REFERRAL*/}
            {isReferralAvailable && (
              <DeferNavLink
                className={cn("mb-2 block")}
                to={Paths.PRIVATE.REFERRAL}
              >
                <RewardButton
                  claimable={isReferralClaimable}
                  rewardIcon={
                    <img
                      src="/images/referral/icon-home-referral.png"
                      alt="referral"
                      className="h-full w-auto object-contain"
                    />
                  }
                >
                  {t("refer friend")}
                </RewardButton>
              </DeferNavLink>
            )}

            {externalLinks.map((link) => (
              <DeferNavLink
                key={link.url}
                className={cn("mb-2 block")}
                to={link.url}
                openNewTab
              >
                <RewardButton
                  rewardIcon={
                    <img
                      src={link.icon}
                      alt={link.title}
                      className="h-full w-auto object-contain"
                    />
                  }
                >
                  {link.title}
                </RewardButton>
              </DeferNavLink>
            ))}
          </>
        )}

        {isFetchingPromotionStatus && (
          <Skeleton
            count={buttonSkeletonLength}
            className="mb-2 !h-[56px] !rounded-lg"
          />
        )}
      </RevealOnScroll>
    </div>
  );
};

type ExternalLink = {
  title: string;
  icon: string;
  url: string;
};

export default HomePage;
