import { siteApi } from "@api";
import { BankSelect, RHFController } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import useRHF from "@hooks/useRHF";
import { Button, Dialog, TextField, useHandleApiResponse } from "kz-ui-sdk";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { z as zod } from "zod";

const schema = zod.object({
  accountNo: zod
    .string({
      required_error: "Account number is required",
    })
    .min(1, "Account number is required"),
  legalName: zod
    .string({
      required_error: "Legal name is required",
    })
    .min(1, "Legal name is required"),
  bankName: zod
    .string({
      required_error: "Bank name is required",
    })
    .min(1, "Bank name is required"),
});

type Values = zod.infer<typeof schema>;

const defaultValue = { accountNo: "", legalName: "", bankName: "" } satisfies Values;

export default function AddBankDialog() {
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });
  const { t } = useTranslation();

  const [addBankAcc, { isLoading }] = siteApi.useAddBankAccountMutation();

  const [open, setOpen] = useState(false);

  const onSubmit = useCallback(async (v: Values) => {
    //    const result = await addBankAcc(v);
    //    handleApiResponse(result, {
    //      successMessage: t("Add Bank Successfully"),
    //      onSuccess: () => {
    //        setOpen(false);
    //      },
    //    });
  }, []);

  const { control, submit } = useRHF(defaultValue, zodResolver(schema), onSubmit);

  return (
    <>
      <div>
        <p className="text-base font-medium text-error">{`You don't have a default bank account. `}</p>
        <Button
          className="mt-4"
          size="lg"
          onClick={() => setOpen(true)}
        >
          Setup here
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        title="Add Bank Account"
      >
        <div className="flex flex-col gap-[29px]">
          <RHFController
            control={control}
            name="bankName"
          >
            <BankSelect disabled={isLoading} />
          </RHFController>

          <label className="flex flex-col gap-2">
            <span className="text-xl font-bold text-light1">Legal Name</span>
            <RHFController
              control={control}
              name="legalName"
            >
              <TextField
                classes={{
                  "input&": "border-[#A76CFF]",
                }}
                disabled={isLoading}
              />
            </RHFController>
          </label>
          <label className="flex flex-col gap-2">
            <span className="text-xl font-bold text-light1">Account Number</span>
            <RHFController
              control={control}
              name="accountNo"
            >
              <TextField
                classes={{
                  "input&": "border-[#A76CFF]",
                }}
                disabled={isLoading}
              />
            </RHFController>
          </label>
          <Button
            size="lg"
            onClick={submit}
            loading={isLoading}
          >
            Add
          </Button>
        </div>
      </Dialog>
    </>
  );
}
