import { queryWithRefresh } from "@api/base.ts";
import { APIClient } from "kz-ui-sdk";

const apiClient = APIClient.getInstance({
  baseQuery: {
    auth: queryWithRefresh,
    public: queryWithRefresh,
    site: queryWithRefresh,
  },
});

export const { auth: authApi, public: publicApi, site: siteApi } = apiClient.createApis();

export const apiReducers = apiClient.reducers();
export const apiMiddlewares = apiClient.middlewares();
