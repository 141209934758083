import { cn } from "kz-ui-sdk";
import { useMemo } from "react";
import "./CoinHeartbeat.css";

interface CoinHeartbeatProps {
  className?: string;
  imgSrc?: string;
  imgClassName?: string;
}

export default function CoinHeartbeat({
  className,
  imgSrc = "/images/icon-coin.png",
  imgClassName,
}: CoinHeartbeatProps) {
  // sync with machine clock, delay animation until next animation frame
  const delayAnimation = useMemo(() => {
    const animationDuration = 1.5;
    const now = new Date();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();
    return String(animationDuration - (seconds + milliseconds / 1000)) + "s";
  }, []);

  return (
    <div className={cn("coin-heartbeat", className)}>
      <div
        className="coin-heartbeat__ring"
        style={{
          animationDelay: delayAnimation,
        }}
      />
      <img
        className={cn("coin-heartbeat__image", imgClassName)}
        src={imgSrc}
        alt="coin"
        style={{
          animationDelay: delayAnimation,
        }}
      />
    </div>
  );
}
