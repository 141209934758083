import { DepositMethod } from "@components/utils/DepositDrawer/types";
import { DepositMethodContent, DepositMethodType, PaymentProviderKey } from "kz-ui-sdk";

const PROVIDER_IMAGE_MAP: Partial<Record<PaymentProviderKey, string | null>> = {
  [PaymentProviderKey.Mint]: "/images/mint-logo.png",
};

const BANK_TRANSFER_IMAGE = "/images/bank-transfer-logo.png";

/**
 * Standardize deposit method content to a standard format
 * Add provider and type to the content
 * Return null if id is not provided
 * @param methodContent
 */
export const standardizeDepositMethod = (methodContent: DepositMethodContent): DepositMethod | null => {
  if (!methodContent.id) return null;
  const [provider, paymentType] = methodContent.id.split("/");
  let bannerUrl = methodContent.bannerUrl;
  if (!bannerUrl) {
    // Use default hardcoded banner image if not provided
    bannerUrl =
      paymentType === DepositMethodType.BankTransfer
        ? BANK_TRANSFER_IMAGE
        : PROVIDER_IMAGE_MAP[provider as PaymentProviderKey];
  }

  return {
    key: methodContent.id,
    provider: provider as PaymentProviderKey,
    type: (paymentType ?? DepositMethodType.QrCode) as DepositMethodType,
    content: {
      ...methodContent,
      bannerUrl,
    },
    skipAmountInput: methodContent.skipAmountInput ?? false,
  } satisfies DepositMethod;
};
