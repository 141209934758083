import { Button } from "kz-ui-sdk";
import { MdInfoOutline } from "react-icons/md";

interface EmptyStateProps {
  title?: string;
  subTitle?: string;
  btnText?: string;
  onGetShareLink?: () => void;
}

const EmptyState = ({ title, subTitle, btnText, onGetShareLink }: EmptyStateProps) => {
  return (
    <div className="flex flex-col items-center gap-y-2 px-4 py-1">
      <span>
        <MdInfoOutline
          size={24}
          className="content-base-secondary"
        />
      </span>
      <div className="text-center">
        <span className="content-base mb-0.5 block text-sm font-bold leading-5">{title}</span>
        <span className="content-base-secondary block text-xs leading-4">{subTitle}</span>
      </div>
      <div>
        <Button
          variant="secondary"
          onClick={onGetShareLink}
          size="sm"
          classes={{
            "label&": "text-xs px-3 !w-fit !content-base",
          }}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
};

export default EmptyState;
