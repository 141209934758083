import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { InfoResponse } from "kz-ui-sdk";

export interface PreferenceSliceState {
  serverInfo: InfoResponse | null;
  displayAppInstaller: boolean;
}

const initialState: PreferenceSliceState = {
  serverInfo: null,
  displayAppInstaller: false,
};

const preferenceSlice = createSlice({
  name: "@preference",
  initialState,
  reducers: {
    setServerInfo(state, action: PayloadAction<InfoResponse>) {
      state.serverInfo = action.payload;
    },
    setDisplayAppInstaller(state, action: PayloadAction<boolean>) {
      state.displayAppInstaller = action.payload;
    },
  },
});

export default preferenceSlice;
