import { FormOTPAndPIN, OnboardPageLayout } from "@components";
import { OnboardMode, Paths, STORAGE_KEY } from "@constants";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

interface ForgotPinPageProps {}

const ForgotPINPage = ({}: ForgotPinPageProps) => {
  const { state } = useLocation();
  const onboardingNumber = localStorage.getItem(STORAGE_KEY.LOGIN_PHONE);

  const phone = useMemo(() => {
    return state?.phone || onboardingNumber || "";
  }, [onboardingNumber, state?.phone]);

  return (
    <OnboardPageLayout
      backTo={Paths.PUBLIC.LOGIN}
      navigateOptions={{
        replace: true,
        state: {
          phone,
        },
      }}
    >
      <FormOTPAndPIN
        phone={phone}
        mode={OnboardMode.LOGIN}
      />
    </OnboardPageLayout>
  );
};

export default ForgotPINPage;
