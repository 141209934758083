import { cn, PropsWithClassName } from "kz-ui-sdk";
import { AspectRatio } from "react-aspect-ratio";

interface GameEntrySkeletonProps extends PropsWithClassName {
  count?: number;
  variant: "thumbnail" | "row";
}

const GameEntrySkeleton = ({ className, count = 1, variant }: GameEntrySkeletonProps) => {
  if (variant === "row") {
    return Array.from({ length: count }).map((_, index) => (
      <div
        className="flex items-center gap-x-4"
        key={["skeleton-row", index].join("-")}
      >
        <div className={cn("h-[62px] w-[62px] shrink-0 animate-pulse rounded-[10px] bg-[#1f2051]", className)}></div>
        <div className="flex grow flex-col">
          <div className={cn("h-[24px] w-full animate-pulse rounded-[10px] bg-[#1f2051]", className)}></div>
          <div className={cn("mt-1 h-[16px] w-full animate-pulse rounded-[10px] bg-[#1f2051]", className)}></div>
        </div>
        <div className="h-8 w-8 shrink-0 animate-pulse rounded-full bg-[#1f2051]"></div>
      </div>
    ));
  }

  return Array.from({ length: count }).map((_, index) => (
    <AspectRatio
      ratio={1}
      key={["skeleton-thumbnail", index].join("-")}
    >
      <div className={cn("aspect-square animate-pulse rounded-[10px] bg-[#1f2051]", className)} />
    </AspectRatio>
  ));
};

export default GameEntrySkeleton;
