import { appConfig } from "@configs";
import { DeployCountry, DeployEnv, DeployEnvConfiguration } from "@types";

class DeployEnvChecker {
  private static instance: DeployEnvChecker;
  private env: DeployEnvConfiguration;

  private constructor() {
    const currentCountry = import.meta.env.VITE_CURRENT_COUNTRY as DeployCountry;

    if (!currentCountry) {
      throw Error("Current country is not defined, please check VITE_CURRENT_COUNTRY in .env file");
    }

    // Find the deploying env from the appConfig
    const countryConfig = appConfig.countries[currentCountry];

    if (!countryConfig) {
      throw Error("Country config is not defined, please check application.ts file");
    }

    this.env = {
      country: countryConfig,
      deploymentType: DeployEnv.Production,
    };
  }

  public static getInstance(): DeployEnvChecker {
    if (!DeployEnvChecker.instance) {
      DeployEnvChecker.instance = new DeployEnvChecker();
    }
    return DeployEnvChecker.instance;
  }

  public getDeployEnv() {
    return this.env;
  }
}

const deployEnvChecker = DeployEnvChecker.getInstance();

export const getDeployEnv = deployEnvChecker.getDeployEnv.bind(deployEnvChecker);

export const isProd = () => {
  return import.meta.env.PROD && import.meta.env.VITE_DEPLOY_ENV === "production";
};
