type FlyingCoinsAnimationConfig = {
  durations?: {
    bounceDown?: number;
    flyToTarget?: number;
    delay?: number;
    skipBounceDown?: boolean;
    withTrail?: boolean;
  };
  positions?: {
    offset?: {
      bounceDown?: {
        x?: number;
        y?: number;
      };
    };
    fixed?: {
      bounceDown?: {
        x: number;
        y: number;
      };
    };
  };
};

export const generateFlyingCoins = (
  sourceRef: HTMLDivElement,
  targetPosition: DOMRect,
  numberOfCoins: number,
  coinSizes: {
    source: { width: number; height: number };
    target: { width: number; height: number };
  },
  animationConfig?: FlyingCoinsAnimationConfig,
) => {
  const animation = {
    durations: {
      bounceDown: 1,
      flyToTarget: 1,
      delay: 0.3,
      skipBounceDown: false,
      withTrail: true,
      ...animationConfig?.durations,
    },
    positions: {
      offset: {
        bounceDown: {
          x: 0,
          y: 0,
          ...animationConfig?.positions?.offset?.bounceDown,
        },
      },
      fixed: {
        bounceDown: animationConfig?.positions?.fixed?.bounceDown ?? undefined,
      },
    },
  };

  const orgSourcePosition = sourceRef.getBoundingClientRect();

  for (let i = 0; i < numberOfCoins; i++) {
    setTimeout(
      () => {
        const sourcePosition = animation.durations.withTrail ? sourceRef.getBoundingClientRect() : orgSourcePosition;
        const coin = document.createElement("img");
        coin.src = "/images/icon-coin.png";
        coin.style.width = `${coinSizes.source.width}px`;
        coin.style.height = `${coinSizes.source.height}px`;
        coin.style.position = "fixed";
        coin.style.zIndex = String(9999 - i);
        coin.style.transition = `left,top,width,height ${animation.durations.bounceDown}s`;
        coin.style.left = `${sourcePosition.x}px`;
        coin.style.top = `${sourcePosition.y}px`;
        coin.style.willChange = "transform";

        // Set custom properties for CSS animations
        coin.style.setProperty("--source-x", `${sourcePosition.x}px`);
        coin.style.setProperty("--source-y", `${sourcePosition.y}px`);

        // add the coin to the body
        document.body.appendChild(coin);

        // accumulate the coin, keep them waiting for all coins
        const offset = 700 - i * animation.durations.delay * 1000;
        // once all coins are ready, delay the flying effect to let user see the coins
        const delay = ((i * animation.durations.delay) / 3) * 1000;

        const timeoutFlyToTarget =
          animation.durations.bounceDown * 1000 + delay + (animation.durations.skipBounceDown ? 0 : offset) || 50;
        // fly to the target position
        setTimeout(() => {
          coin.style.setProperty("--target-x", `${targetPosition.x}px`);
          coin.style.setProperty("--target-y", `${targetPosition.y}px`);
          // Apply CSS animation
          coin.style.animation = `flyToTarget ${animation.durations.flyToTarget}s forwards`;
        }, timeoutFlyToTarget ?? 50);

        // remove the coin from the body
        setTimeout(
          () => {
            document.body.removeChild(coin);
          },
          animation.durations.flyToTarget * 1000 + offset + delay,
        );
      },
      i * (animation.durations.delay * 1000),
    );
  }
};
