import { BackButton } from "@components";
import { LANGUAGES } from "@constants";
import { PageTitle, SelectOption, SelectOptions } from "kz-ui-sdk";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguagePage = () => {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    LANGUAGES.find((lang) => lang.code === i18n.language) ?? LANGUAGES[0],
  );

  useEffect(() => {
    setSelectedLanguage(LANGUAGES.find((lang) => lang.code === i18n.language) ?? LANGUAGES[0]);
  }, [i18n.language]);

  const handleOnSelect = useCallback(
    (option: SelectOption) => {
      i18n.changeLanguage(option.id).then(() => {});
    },
    [i18n],
  );
  return (
    <div className="flex flex-col">
      <PageTitle
        label={t("Language")}
        backButton={<BackButton />}
        className="mb-4"
      />
      <SelectOptions
        options={LANGUAGES.map((lang) => ({
          id: lang.code,
          imgUrl: lang.flagURL,
          name: lang.name,
        }))}
        handleOnSelect={handleOnSelect}
        imageVariant="circular"
        selectedOption={{
          id: selectedLanguage.code,
          imgUrl: selectedLanguage.flagURL,
          name: selectedLanguage.name,
        }}
        classes={{
          "image&": "!w-6 !h-6",
        }}
      />
    </div>
  );
};

export default LanguagePage;
