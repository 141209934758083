import { DepositMethodType, PaymentProviderKey } from "kz-ui-sdk";
import { DepositMethod } from "../types";

export const MINT_METHOD: DepositMethod = {
  key: "mint-deposit",
  provider: PaymentProviderKey.Mint,
  type: DepositMethodType.QrCode,
  content: {
    name: "Mint",
    currency: "THB",
    enabled: true,
    max: null,
    min: null,
    bannerUrl: "/images/mint-logo.png",
  },
  skipAmountInput: false,
};
