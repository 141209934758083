import { Drawer, delay, useCustomEventsListener } from "kz-ui-sdk";
import { useEffect, useState } from "react";
import { SuccessView, WithdrawForm } from "./components";
import { EVENT_CLOSE_WITHDRAW, EVENT_OPEN_WITHDRAW } from "./constants";

export default function WithdrawDrawer() {
  const [isOpen, setIsOpen] = useState(false);

  useCustomEventsListener({
    [EVENT_CLOSE_WITHDRAW]: () => setIsOpen(false),
    [EVENT_OPEN_WITHDRAW]: () => setIsOpen(true),
  });

  const [isSuccess, setIsSuccess] = useState(false);

  // Reset success state when drawer is closed
  useEffect(() => {
    if (!isOpen) {
      delay(500).then(() => setIsSuccess(false));
    }
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      floatingHandle={isSuccess}
      safeZone={!isSuccess}
    >
      {!isSuccess ? <WithdrawForm onSuccess={() => setIsSuccess(true)} /> : <SuccessView />}
    </Drawer>
  );
}
