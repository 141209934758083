import TagManager from "@sooro-io/react-gtm-module";

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
};

export const initGTM = () => {
  if (!tagManagerArgs.gtmId) {
    return;
  }

  TagManager.initialize(tagManagerArgs);
};
