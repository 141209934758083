import { siteApi } from "@api";
import { ClaimButton, FlyingCoins, FlyingCoinsRef, PointLabel, PointLabelRef, toast } from "@components";
import { BALANCE_BADGE_TOP_ID } from "@constants";
import { ReferralEvent } from "@views/ReferralPage/types";
import BigNumber from "bignumber.js";
import { BN_ZERO, Skeleton, bnOrZero, cn, fAmount, useHandleApiResponse } from "kz-ui-sdk";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface ClaimSectionProps {}

const POT_OF_COINS_IMG = [
  {
    condition: 100,
    imgSrc: "/images/referral/pot-of-coins-3.png",
  },
  {
    condition: 50,
    imgSrc: "/images/referral/pot-of-coins-2.png",
  },
  {
    condition: 0.01,
    imgSrc: "/images/referral/pot-of-coins-1.png",
  },
];

type AnimKeyframe = "claimable" | "claiming" | "loading";

const ClaimSection = ({}: ClaimSectionProps) => {
  const { t } = useTranslation();
  const [claimAmount, setClaimAmount] = useState<BigNumber>();
  const [minClaimAmount, setMinClaimAmount] = useState<BigNumber>(BN_ZERO);
  const [animKeyframe, setAnimKeyframe] = useState<AnimKeyframe>("loading");
  const pointLabelRef = useRef<PointLabelRef>(null);
  const { data: infoResponse, isLoading: isLoadingInfo } = siteApi.useGetReferralInfoQuery({});
  const [getAvailableClaim, { isLoading: isLoadingClaimAmount }] = siteApi.useLazyGetReferralAvailableClaimQuery({});
  const [claimReferral, { isLoading: isClaiming }] = siteApi.useClaimReferralMutation();
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });
  const refFlyingCoins = useRef<FlyingCoinsRef>(null);

  // init balance
  useEffect(() => {
    getAvailableClaim({}).then((res) => {
      if (res.data) {
        setClaimAmount(res.data.availableAmount);
      }
    });
  }, [getAvailableClaim]);

  // init min claim amount
  useEffect(() => {
    if (infoResponse?.referralConfig?.minComAmt) {
      if (!minClaimAmount.eq(infoResponse?.referralConfig?.minComAmt)) {
        setMinClaimAmount(bnOrZero(infoResponse?.referralConfig?.minComAmt));
      }
    }
  }, [infoResponse?.referralConfig?.minComAmt, minClaimAmount]);

  const potOfCoinsImgSrc = useMemo(() => {
    const balanceBn = bnOrZero(claimAmount);
    for (const pot of POT_OF_COINS_IMG) {
      if (balanceBn.gte(pot.condition)) {
        return pot.imgSrc;
      }
    }
    return null;
  }, [claimAmount]);

  const claimable = useMemo(() => {
    return claimAmount?.gt(0) && claimAmount?.gte(minClaimAmount);
  }, [claimAmount, minClaimAmount]);

  useEffect(() => {
    if (claimable) {
      setAnimKeyframe("claimable");
    }
  }, [claimable]);

  const handleOnClaim = async () => {
    // check if balance is 0
    if (claimAmount?.eq(0)) {
      toast.info(t("nothing to claim yet. refer more friends"));
      return;
    }

    // check min claim amount
    if (claimAmount?.lt(minClaimAmount)) {
      toast.info(t("minimum amount to claim. refer more friends", { amount: fAmount(minClaimAmount) }));
      return;
    }

    // Claim referral
    const claimRes = await claimReferral();
    handleApiResponse(claimRes, {
      onSuccess: async () => {
        pointLabelRef.current?.count();
        refFlyingCoins.current?.start();
        setAnimKeyframe("claiming");
        const newBalanceRes = await getAvailableClaim({}).refetch();
        window.dispatchEvent(new Event(ReferralEvent.CLAIMED));
        setTimeout(() => {
          setClaimAmount(newBalanceRes.data?.availableAmount ?? BN_ZERO);
        }, 2000);
      },
    });
  };

  return (
    <div className="relative flex h-[192px] justify-between pt-3">
      {/*CLAIM AMOUNT*/}
      {!isLoadingClaimAmount && !!claimAmount && !isLoadingInfo && (
        <div className="absolute left-1 top-[42px] z-10 w-full animate-fade-in will-change-transform">
          <PointLabel
            endVal={0}
            startVal={claimAmount.toNumber()}
            type="down"
            className={cn("block text-[30px] font-bold leading-9", {
              "text-[#E7D5FF]": !claimable,
              "bg-[linear-gradient(0deg,_#F5C12A_13.89%,_#FAE68D_84.72%)] bg-clip-text text-transparent drop-shadow-[0px_2px_0px_rgba(0,_0,_0,_0.40)]":
                claimable,
            })}
            decimal={2}
            ref={pointLabelRef}
            duration={1.5}
          />
        </div>
      )}

      {/*CLAIM BUTTON*/}
      {/*SKELETON*/}
      {isLoadingClaimAmount && (
        <div className="flex max-w-[160px] flex-1 flex-col pt-4">
          <div className="py-1">
            <Skeleton className="mb-0.5 !h-[10px] !w-20" />
            <Skeleton className="!h-9 !w-40" />
          </div>
          <div className="mt-1 max-w-[160px]">
            <Skeleton className="!h-10" />
          </div>
        </div>
      )}
      {!isLoadingClaimAmount && (
        <div className="flex flex-1 animate-fade-in flex-col pt-4">
          <div className="p-1">
            <span className="content-base-secondary block text-[10px] uppercase leading-[10px]">
              {t("your income")}
            </span>
            <div className="h-9"></div>
          </div>
          <div className="mt-1 max-w-[160px]">
            <ClaimButton
              onClick={handleOnClaim}
              text={t("claim cashback")}
              coinClassName="!duration-[0.5s] ![transition-delay:0s]"
              withCoin={claimable && animKeyframe !== "claiming"}
              withShine={claimable && animKeyframe !== "claiming"}
              withFlyingCoins={false}
              claimable
              loading={isClaiming}
            />
          </div>
        </div>
      )}
      {/*CAT ICON*/}
      <div className="relative h-[180px] flex-shrink-0 basis-[140px]">
        <img
          src="/images/referral/icon-cat.png"
          className="h-[180px] w-[140px]"
          alt=""
        />
        {!!potOfCoinsImgSrc && (
          <div
            className="absolute right-[20px] top-[64px] h-[20px] w-[100px]"
            style={{
              clipPath: "ellipse(50% 50% at 50% 50%)",
            }}
          >
            <img
              src={potOfCoinsImgSrc}
              className={cn("h-full w-full transition-transform !duration-[1.5s]", {
                "translate-y-8": animKeyframe === "claiming",
              })}
              alt=""
            />
          </div>
        )}
        <FlyingCoins
          coinImgSrc={"/images/icon-coin.png"}
          sourceCoinSize={18}
          count={12}
          targetId={BALANCE_BADGE_TOP_ID}
          className={cn("absolute left-[30px] top-[86px] h-[35px] w-[80px]")}
          ref={refFlyingCoins}
          randomHorizontalRange={60}
          randomVerticalRange={70}
          delayIn={0.05}
          overlay={true}
          duration={1.5}
          removeIn={4.4}
        />
      </div>
    </div>
  );
};

export default ClaimSection;
