export const Brand = {
  ["XO268"]: {
    key: "xo268",
    name: "XO268",
  },
  ["789BK"]: {
    key: "789bk",
    name: "789BK",
  },
};
