import { ACCESS_TOKEN_ENDPOINT, STORAGE_KEY } from "@constants";
import { BaseQueryApi } from "@reduxjs/toolkit/query";
import { RootState } from "@stores";
import { MarketingData, MarketingDataParam } from "@types";
import { isTokenExpired, logger } from "@utils";
import { storage } from "kz-ui-sdk";

type HeaderPreparer = (
  header: Headers,
  api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">,
  state?: RootState,
) => void;

/**
 * Attach marketing data to header
 * @description Attach marketing data from localStorage (mktg-data) to header
 */
export const prepareMarketingData: HeaderPreparer = (header): void => {
  const mktgData = storage.local.getItem(STORAGE_KEY.MARKETING_DATA) as MarketingData | null;
  if (mktgData) {
    for (const key in mktgData) {
      if (mktgData[key as MarketingDataParam]) {
        header.set(key, mktgData[key as MarketingDataParam]);
      }
    }
  }
};

/**
 * Attach access token to header
 * @description Attach access token to header if it's not expired and not in the access token endpoint
 */
export const prepareAccessToken: HeaderPreparer = (header, api, state) => {
  if (
    state?.auth.oauth?.access_token &&
    !isTokenExpired(state.auth.oauth?.access_token) &&
    api.endpoint !== ACCESS_TOKEN_ENDPOINT
  ) {
    header.set("Authorization", `Bearer ${state.auth.oauth.access_token}`);
  } else {
    logger._console.log("Header is not set", api, state);
  }
};
