import { STORAGE_KEY } from "@constants";
import { ThirdPartyModule } from "i18next";

const PersistLanguage = {
  type: "3rdParty",
  init: (i18nextInstance) => {
    i18nextInstance.on("languageChanged", (lng) => {
      localStorage.setItem(STORAGE_KEY.LANGUAGE, lng); // Or use any other storage method
    });
  },
} satisfies ThirdPartyModule;

export default PersistLanguage;
